import { supportedRetailers } from '../constants';

const getRetailerNameFromUrl = (url) => {
  const name = supportedRetailers
    .map((supportedRetailer) => supportedRetailer.name)
    .find((retailerName) =>
      // eslint-disable-next-line no-use-before-define
      createRetailerPattern(retailerName).test(url)
    );

  if (!name) {
    return '';
  }

  return name;
};

const createRetailerPattern = (name) => {
  const urlPattern = new RegExp(
    `https?://(www\\.)?([a-z]{1,256}\\.)?(${name}\\.)+([a-zA-Z]{1,256}.)?[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)`
  );

  return urlPattern;
};

export default getRetailerNameFromUrl;
