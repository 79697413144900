import { isEmpty, isNull, isUndefined } from 'lodash';
import { dicebarDefaultStyle, dicebarListStyle } from './dicebar-avatar-seeds';
import { firestore } from 'utils/firebase';

export const AVATAR_URL_FIREBASE_KEY = 'avatarUrl';

/**
 * @param {{
 *  seedName: string,
 *  styleName: string,
 * }} param0
 * @returns {string}
 */
export const generateAvatar = ({
  seedName,
  styleName = dicebarDefaultStyle,
}) => {
  return `${dicebarListStyle[styleName].svgURL}?seed=${seedName}`;
};

/**
 * @param {any} me
 * @returns {boolean}
 */
export const checkHasAvatarURL = (me) => {
  if (isEmpty(me)) {
    return false;
  }

  if (
    isUndefined(me[AVATAR_URL_FIREBASE_KEY]) ||
    isNull(me[AVATAR_URL_FIREBASE_KEY]) ||
    me[AVATAR_URL_FIREBASE_KEY] === ''
  ) {
    return false;
  }

  return true;
};

/**
 * Update avatar on firebase
 * @param {{
 *  avatarURL: string,
 *  uid: string
 * }} param0
 */
export const updateAvatarURL = async ({ avatarURL, uid }) => {
  try {
    const userRef = firestore.collection('users').doc(uid);
    await userRef.update({
      [AVATAR_URL_FIREBASE_KEY]: avatarURL,
    });
  } catch (error) {
    console.log(error);
  }
};
