import { defineStyle } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

/** Accessible color overrides for less accessible colors. */
const accessibleColorMap = {
  yellow: {
    bg: 'yellow.400',
    color: 'black',
    hoverBg: 'yellow.500',
    activeBg: 'yellow.600',
  },
  cyan: {
    bg: 'cyan.400',
    color: 'black',
    hoverBg: 'cyan.500',
    activeBg: 'cyan.600',
  },
};

const variantRounded = defineStyle((props) => {
  const { colorScheme: c } = props;

  if (c === 'gray') {
    const bg = mode(`gray.100`, `whiteAlpha.200`)(props);

    return {
      bg,
      _hover: {
        bg: mode(`gray.200`, `whiteAlpha.300`)(props),
        _disabled: {
          bg,
        },
      },
      _active: { bg: mode(`gray.300`, `whiteAlpha.400`)(props) },
    };
  }

  const {
    bg = `${c}.500`,
    color = 'white',
    hoverBg = `${c}.600`,
    activeBg = `${c}.700`,
  } = accessibleColorMap[c] ?? {};

  const background = mode(bg, `${c}.200`)(props);

  return {
    bg: background,
    borderRadius: '3xl',
    color: mode(color, `gray.800`)(props),
    _hover: {
      bg: mode(hoverBg, `${c}.300`)(props),
      _disabled: {
        bg: background,
      },
    },
    _active: { bg: mode(activeBg, `${c}.400`)(props) },
  };
});

const mobileRounded = defineStyle((props) => {
  const { colorScheme: c } = props;

  if (c === 'gray') {
    const bg = mode(`gray.100`, `whiteAlpha.200`)(props);

    return {
      bg,
      _hover: {
        bg: mode(`gray.200`, `whiteAlpha.300`)(props),
        _disabled: {
          bg,
        },
      },
      _active: { bg: mode(`gray.300`, `whiteAlpha.400`)(props) },
    };
  }

  if (c === 'ghost') {
    return {
      borderRadius: '100px',
      fontSize: '16px',
      lineHeight: '24px',
      height: '',
      padding: '16px',
      minW: '120px',
      color: '#999CA0',
    };
  }

  const {
    bg = `${c}.500`,
    color = 'white',
    hoverBg = `${c}.600`,
    activeBg = `${c}.700`,
  } = accessibleColorMap[c] ?? {};

  const background = mode(bg, `${c}.200`)(props);

  if (c === 'light') {
    return {
      bg: '#04CEAA',
      borderRadius: '100px',
      fontSize: '16px',
      lineHeight: '24px',
      height: '',
      padding: '16px',
      minW: '120px',
      color: mode(color, `gray.800`)(props),
      _hover: {
        bg: '#02be9c',
        _disabled: {
          bg: background,
        },
      },
      _active: { bg: '#00ab8c' },
    };
  }

  return {
    bg: background,
    borderRadius: '100px',
    fontSize: '16px',
    lineHeight: '24px',
    height: '',
    padding: '16px',
    minW: '120px',
    color: mode(color, `gray.800`)(props),
    _hover: {
      bg: mode(hoverBg, `${c}.300`)(props),
      _disabled: {
        bg: background,
      },
    },
    _active: { bg: mode(activeBg, `${c}.400`)(props) },
  };
});

const mobileRoundedOutlined = defineStyle((props) => {
  return {
    bg: 'rgba(0,0,0,0)',
    border: '1px solid',
    borderColor: 'teal.500',
    color: 'teal.500',
    borderRadius: '100px',
    fontSize: '16px',
    lineHeight: '24px',
    height: '',
    paddingRight: '16px',
    paddingLeft: '16px',
    paddingTop: '15px',
    paddingBottom: '15px',
    minW: '120px',
  };
});

const mobileRoundedGhost = defineStyle((props) => {
  return {
    borderRadius: '100px',
    fontSize: '16px',
    lineHeight: '24px',
    height: '',
    padding: '16px',
    minW: '120px',
    color: '#999CA0',
  };
});

export const buttonTheme = {
  baseStyle: {
    fontWeight: 'semibold',
    borderRadius: 'lg',
    textTransform: 'capitalize',
  },
  variants: {
    outline: (_props) => ({
      border: '2px solid',
    }),
    rounded: variantRounded,
    mobileRounded: mobileRounded,
    mobileRoundedOutlined: mobileRoundedOutlined,
    mobileRoundedGhost: mobileRoundedGhost,
  },
};
