import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PersonalityResultDownload } from 'pages/food-personality-result/food-personality-result';
import { getPersonality } from 'pages/food-personality-result/personality-rules';
import Error404 from 'components/fallback/404/error-404';
import {
  generateJWT,
  getPersonalityByUsername,
} from 'utils/questionnaire-module/questionnaire-module';
import { useToast } from '@chakra-ui/react';
import { t } from 'i18next';

const MyFoodPersonality = () => {
  const toast = useToast();
  const { username } = useParams();
  const [JWT, setJWT] = useState(null);
  const [isLoadJWT, setIsLoadJWT] = useState(true);
  const [errMsg, setErrMsg] = useState(null);
  const [personalityTraits, setPersonalityTraits] = useState(null);
  const [isLoadPersonality, setIsLoadPersonality] = useState(true);

  const fetchJWT = useCallback(async () => {
    setIsLoadJWT(true);
    try {
      const jwt = await generateJWT();
      setJWT(jwt.token);
    } catch (error) {
      setJWT(null);
      setErrMsg(error.response.data.message);
    }
    setIsLoadJWT(false);
  }, []);

  const fetchPersonality = useCallback(async () => {
    setIsLoadPersonality(true);
    try {
      const personality = await getPersonalityByUsername({
        token: JWT,
        username: username,
      });
      setPersonalityTraits(personality.traits);
    } catch (error) {
      setPersonalityTraits(null);
      setErrMsg(error.response.data.message);
    }
    setIsLoadPersonality(false);
  }, [JWT, username]);

  useEffect(() => {
    if (errMsg) {
      toast({
        title: t('warning'),
        description: errMsg,
        status: 'warning',
        duration: 3000,
        position: 'top',
        isClosable: false,
      });
    }

    if (!JWT) {
      fetchJWT();
    }
    if (!isLoadJWT && !personalityTraits && !errMsg) {
      fetchPersonality();
    }
  }, [
    JWT,
    fetchJWT,
    fetchPersonality,
    isLoadJWT,
    isLoadPersonality,
    personalityTraits,
    username,
    errMsg,
    toast,
  ]);

  return errMsg ? (
    <Error404 />
  ) : (
    <PersonalityResultDownload
      dark={true}
      personalityTraits={personalityTraits}
      personalityType={getPersonality({ ...personalityTraits })}
      isLoading={isLoadPersonality}
    />
  );
};

export default MyFoodPersonality;
