import { isEmpty, isNil } from 'lodash';
import getRetailerNameFromUrl from '../get-retailer-name-from-url';
import { getProductIdFromUrl } from './get-product-id-from-url';

const isBlank = (v) => isEmpty(v) || isNil(v);

/**
 * Product uid is combination of retailer + productId. This allows duplicate productId
 * from different retailers to coexist. If the given url is not recognized or the id cannot
 * be extracted, the provided url will be thrown as a fallback.
 *
 * @param {{productUrl: string, productId?: string, retailer?: string}} product
 * @returns
 */
export const getProductUid = (product) => {
  if (['productId', 'retailer'].every((key) => !isBlank(product[key]))) {
    return `${product.retailer}/${product.productId}`;
  }

  const retailer = getRetailerNameFromUrl(product.productUrl);
  const productId = !isBlank(retailer)
    ? getProductIdFromUrl({ url: product.productUrl, retailer })
    : '';

  if (!isBlank(retailer) && !isBlank(productId)) {
    return `${retailer}/${productId}`;
  }

  return product.productUrl;
};
