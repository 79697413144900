import { appendPrefixToPaths } from './routes';

const perVersionRootPaths = {
  versionZeroRoot: '/v0.*',
  versionOneRoot: '/v1.*',
};

const authPaths = {
  auth: '/auth/*',
  login: '/auth/login',
  signup: '/auth/signup',
};

const extensionPaths = {
  extension: '/extension/*',
  extensionPopup: '/extension/popup',
  popupOnboardingReminder: '/extension/popup/onboarding-reminder',
  popupZeroState: '/extension/popup/zero',
  onboardingOne: '/extension/onboarding/one',
  onboardingTwo: '/extension/onboarding/two',
  onboardingThree: '/extension/onboarding/three',
  onboardingFour: '/extension/onboarding/four',
  onboardingFive: '/extension/onboarding/five',
  onboardingInjectCoeliacUk: '/extension/onboarding/inject-coeliacuk',
  uninstall: '/extension/uninstall',
};

const inshopPaths = {
  inshop: '/inshop/*',
  welcome: '/inshop/welcome',
  starter: '/inshop/starter',
  notification: '/inshop/notification',
  profile: '/inshop/profile',
  resetPassword: '/inshop/reset-password',
  confirmEmail: '/inshop/confirm-email',
  dashboard: '/inshop/dashboard',
  preferences: '/inshop/preferences',
  addPreferences: '/inshop/add-preferences',
  editPreferences: '/inshop/edit-preferences',
  productRelated: '/inshop/product-related',
  manageRecommendation: '/inshop/manage-recommendation',
  editRecommendation: '/inshop/edit-recommendation',
  editRecommendationCongrats: '/inshop/edit-recommendation/congratulation',
  thanks: '/inshop/uninstall/thanks',
  recommendationAlternatives: '/inshop/recommendation-alternatives',
  goalsTransition: '/inshop/goals-transition',
  personalityTransition: '/inshop/personality-transition',
  checkProduct: '/inshop/check-a-product',
  shoppingAssistantZeroState: '/inshop/connect-retailer-account',
  shoppingAssistantConnect: '/inshop/connect-retailer-account?route=connect',
  shoppingAssistantConnected:
    '/inshop/connect-retailer-account?route=connected&retailer=',
  shoppingAssistantBeforeShop:
    '/inshop/connect-retailer-account?route=before-shop&retailer=',
  history: '/inshop/history',
  foodPersonalityResult: '/inshop/food-personality-result',
  foodPersonalityResultDownload: '/inshop/food-personality-result/download',
  analyzeOrderHistoryListOrders: '/inshop/analyze-order-history/orders',
  analyzeOrderHistoryView: '/inshop/analyze-order-history/view',
  subscriptionList: '/inshop/subscriptions',
  passwordSetting: '/inshop/password-setting',
};

const outshopPaths = {
  outshop: '/:username',
};

const publicPaths = {
  myFoodPersonality: '/:username',
  toStore: '/to-store',
};

export const path = {
  root: '/',
  ...appendPrefixToPaths(authPaths),
  ...appendPrefixToPaths(extensionPaths),
  ...appendPrefixToPaths(inshopPaths),
  ...appendPrefixToPaths(publicPaths),
  ...perVersionRootPaths,
  ...outshopPaths,
};

export const collections = {
  supportedPrefs: 'new_supported_preferences',
  creatorRecommendations: 'creator_recommendations',
  users: 'users',
};

export const externalUrls = {
  creatorCreatives:
    'https://drive.google.com/drive/folders/1NwFWnH06NgxiCgYyRd0x92XpHgfAoitc?usp=sharing',
  creatorForms: 'https://forms.gle/egspkUw1QQGWm4Z97',
  myFoodPersonality:
    process.env.REACT_APP_PLATFORM === 'staging'
      ? 'https://foodlama.vercel.app/'
      : 'https://myfoodpersonality.com/',
  appStoreURL:
    'https://apps.apple.com/gb/app/foodlama-smart-food-shopping/id6470634926',
  googlePlayURL:
    'https://play.google.com/store/apps/details?id=com.heyfoodlama.app',
  extensionURL:
    'https://chromewebstore.google.com/detail/foodlama-your-personal-sh/nchihklmcddhbbgbkkopfgblejahnnpo',
  termsOfUse: 'http://www.apple.com/legal/itunes/appstore/dev/stdeula',
  privacyPolicy: 'https://heyfoodlama.com/privacy-policy/',
};

export const landingPageTheme = {
  /**
   * maybe equal to black in theme
   */
  newBlack: '#1D1838',
  /**
   * no existing color in theme
   */
  newBlack2: '#101010',
  /**
   * maybe equal to lightTeal in theme
   */
  lightTeal: '#04CEAA',
  /**
   * no existing color in theme
   */
  newBlack3: '#242424',
  /**
   * maybe equal to gray in theme
   */
  newGray: '#F5F4F8',
  /**
   * no existing color in theme
   */
  newBlue: 'rgba(37, 43, 92, 0.8)',
  /**
   * maybe equal to gray in theme
   */
  newGray2: '#898794',
  /**
   * maybe equal to gray in theme
   */
  newGray3: '#777777',
  /**
   * maybe equal to gray in theme
   */
  newGray4: '#c7c7c7',
};
