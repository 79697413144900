import { useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';

import { actions as productActions } from 'slices/products.slice';

import { useDispatch, useSelector } from 'react-redux';
import { getProductUid } from '../utils/product';

/**
 * Retrieve analyzed version of a product.
 *
 * @param {string[]} productUrls
 * @returns {Record<string, Product>}
 */
export function useAnalyzedProducts(productUrls) {
  const { products: analyzedProducts, reqStatus } = useSelector(
    (state) => state.products
  );

  const existingUids = Object.keys(analyzedProducts);
  // const _productUrls = JSON.stringify(productUrls);
  // const unanalyzedUrlsString = JSON.stringify(
  //   productUrls.filter(
  //     (url) => !existingUids.includes(getProductUid({ productUrl: url }))
  //   )
  // );

  const requestedUrlsString = JSON.stringify(productUrls);

  // Return ids that haven't been analyzed. If this function is invoked
  // every time the effect runs, it will cause infinite loop.

  const existingUidsString = JSON.stringify(existingUids);

  const unanalyzedUrlsString = useMemo(() => {
    return JSON.stringify(
      JSON.parse(requestedUrlsString).filter((url) =>
        JSON.stringify(
          !JSON.parse(existingUidsString).includes(
            getProductUid({ productUrl: url })
          )
        )
      )
    );
  }, [existingUidsString, requestedUrlsString]);

  const dispatch = useDispatch();

  useEffect(() => {
    async function analyze(urls) {
      if (isEmpty(urls)) {
        return null;
      }

      try {
        await dispatch(productActions.fetchProductsByUrl(urls));
      } catch (error) {}
    }

    analyze(JSON.parse(unanalyzedUrlsString));
  }, [unanalyzedUrlsString, dispatch]);

  const requestedUids = productUrls.map((url) =>
    getProductUid({ productUrl: url })
  );

  return {
    // Filter requested products only
    data: Object.entries(analyzedProducts)
      .filter(([uid]) => requestedUids.includes(uid))
      .reduce(
        (acc, [uid, product]) => ({
          ...acc,
          [uid]: product,
        }),
        {}
      ),
    status: reqStatus,
  };
}

// function symmetricDiff(arr1, arr2) {
//   return arr1
//     .filter((x) => !arr2.includes(x))
//     .concat(arr2.filter((x) => !arr1.includes(x)));
// }

// function asymmetricDiff(arr1, arr2) {
//   return arr1.filter((x) => !arr2.includes(x));
// }

export default useAnalyzedProducts;
