import axios from 'axios';

import { auth } from './firebase';
import getToken from './get-token';

import config from './config';

const postProduct = async ({ product, retailer, options }) => {
  const payload = {
    retailer: {
      name: retailer.name,
    },
    product: {
      ...product,
      updatedBy: auth.currentUser.uid,
    },
    options: typeof options === 'object' ? options : {},
  };

  const token = await getToken();
  const headers = { Authorization: `Bearer ${token}` };

  return axios
    .post(
      config.endpoint.product.concat(`?productId=${product.productId}`),
      payload,
      {
        headers,
      }
    )
    .catch((err) => err);
};

export default postProduct;
