import axios from 'axios';
import getToken from '../get-token';
import { supportedRetailers } from '../../constants';
import config from '../config';

/**
 * To fetch product categories data based on the
 * current url and the analyzed product.
 *
 * @param string retailerCurrentUrl
 * @param object analyzedProduct
 * @returns array
 */
const getProductCategories = async (retailerCurrentUrl, analyzedProduct) => {
  const retailers = supportedRetailers.filter(
    (v) => v.isSupported && retailerCurrentUrl?.includes(v.name)
  );

  // Won't send network request if required properties missing
  if (retailers.length === 0 || Object.keys(analyzedProduct).length === 0)
    return [];

  try {
    const token = await getToken();
    const urlApi = `${
      process.env.NODE_ENV === 'development'
        ? '/api/v0.2/products'
        : config.endpoint.product
    }/${analyzedProduct.productId}?retailer=${retailers[0].name}`;

    const response = await axios.get(urlApi, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.data.length > 0) {
      return response.data[0].categories;
    }
    return [];
  } catch (error) {
    // TODO: DELETE THIS CONSOLE ERROR IN THE FUTURE
    console.error('An error occurred:', error);
    // Return fetch data as empty array
    return [];
  }
};

export default getProductCategories;
