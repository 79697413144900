import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/performance';
import 'firebase/compat/analytics';
import config from './config';

firebase.initializeApp(config.firebase);

export const analytics = firebase.analytics();
export const auth = firebase.auth();
export const storage = firebase.storage().ref();
export const performance = firebase.performance();
export const firestore = firebase.firestore();
export const fireAuth = firebase.auth.Auth;
export const emailAuth = firebase.auth.EmailAuthProvider;
export const googleAuth = new firebase.auth.GoogleAuthProvider();
export const facebookAuth = new firebase.auth.FacebookAuthProvider();

// Uncomment to connect to localhost
// firestore.useEmulator('localhost', 8080);

export default firebase;
