import ecoGourmetImage from '../../assets/images/food-personality/eco-gourmet.svg';
import aestheticAthleteImage from '../../assets/images/food-personality/aesthetic-athlete.svg';
import fitnessFoodieImage from '../../assets/images/food-personality/fitness-foodie.svg';
import healthExplorerImage from '../../assets/images/food-personality/health-explorer.svg';
import expressiveEaterImage from '../../assets/images/food-personality/expressive-eater.svg';
import naturalNomadImage from '../../assets/images/food-personality/natural-nomad.svg';
import socialSamplerImage from '../../assets/images/food-personality/social-sampler.svg';
import balancedTraditionalistImage from '../../assets/images/food-personality/balanced-traditionalist.svg';

/**
 * Represents a user profile with specific traits related to culinary preferences.
 * @typedef {Object} CulinaryProfile
 * @property {string} openness - The openness trait of the user.
 * @property {string} control - The control trait of the user.
 * @property {string} socialFunction - The social function trait of the user.
 * @property {string} personality - The personality trait of the user.
 * @property {string} [description] - A description of the user's culinary profile.
 * @property {string} [image] - The image associated with the user's personality.
 */

/**
 * personality rules, based on this rules we can render personality card
 * for now ignore motivator
 * @type {CulinaryProfile[]}
 */
export const personalityRules = [
  {
    // motivator: 'Universally Driven',
    openness: 'Taste Explorer',
    control: 'Health Focused',
    socialFunction: 'Socialization',
    personality: 'Eco Gourmet',
    description:
      'You are a trendsetter. Always seeking new flavors and cuisines, you prioritize both taste and health. You use food to bring people together and create lasting relationships.',
    image: ecoGourmetImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Taste Explorer',
    control: 'Health Focused',
    socialFunction: 'Norms',
    personality: 'Eco Gourmet',
    description:
      'You are a culinary adventurer, always seeking new flavors and cuisines. You prioritize your health and respect cultural and societal dietary traditions.',
    image: ecoGourmetImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Taste Explorer',
    control: 'Health Focused',
    socialFunction: 'Image',
    personality: 'Aesthetic Athlete',
    description:
      'You are someone who loves to try new and different flavors, always searching for new taste experiences. You prioritize your health and value how your food looks on your plate.',
    image: aestheticAthleteImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Culinary Nomad',
    control: 'Health Focused',
    socialFunction: 'Socialization',
    personality: 'Fitness Foodie',
    description: `You are the ultimate food explorer, always on the lookout for new and exciting flavors. Whether it's trying street food in Thailand or indulging in fine dining in Paris, you are constantly seeking new culinary adventures. You prioritize your health and well-being, making conscious choices when it comes to food. And above all, you believe that good food is meant to be shared, bringing people together and creating lasting memories.`,
    image: fitnessFoodieImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Culinary Nomad',
    control: 'Health Focused',
    socialFunction: 'Norms',
    personality: 'Eco Gourmet',
    description: `You are a person who loves trying different types of food from all over the world, and you always prioritize your health. You respect and follow the dietary customs of different cultures and societies.`,
    image: ecoGourmetImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Culinary Nomad',
    control: 'Health Focused',
    socialFunction: 'Image',
    personality: 'Aesthetic Athlete',
    description: `You're the type who's always on the go, seeking new flavors and experiences. You prioritize your health and how your food looks on your plate.`,
    image: aestheticAthleteImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Selective Taster',
    control: 'Health Focused',
    socialFunction: 'Socialization',
    personality: 'Eco Gourmet',
    description:
      'You are a health-conscious, discerning palate who appreciates the power of food to bring people together.',
    image: ecoGourmetImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Selective Taster',
    control: 'Health Focused',
    socialFunction: 'Norms',
    personality: 'Health Explorer',
    description: `You're an adventurous eater, always seeking new flavors and experiences. You take your time to pick what you eat, ensuring it's healthy and in line with your values.`,
    image: healthExplorerImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Selective Taster',
    control: 'Health Focused',
    socialFunction: 'Image',
    personality: 'Expressive Eater',
    description: `You are a health enthusiast who loves experimenting with flavors. You carefully choose what you eat and believe that food is a way to express yourself and nourish your body.`,
    image: expressiveEaterImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Taste Explorer',
    control: 'Fitness Focused',
    socialFunction: 'Socialization',
    personality: 'Fitness Foodie',
    description: `You are a culinary adventurer, always seeking new flavors and dishes. You prioritize your health and enjoy staying active. Eating is a social experience for you, connecting with friends and loved ones over delicious meals.`,
    image: fitnessFoodieImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Taste Explorer',
    control: 'Fitness Focused',
    socialFunction: 'Norms',
    personality: 'Fitness Foodie',
    description: `You are a culinary adventurer, always seeking out new flavors and cuisines. You prioritize health and fitness, while also respecting and following dietary guidelines.`,
    image: fitnessFoodieImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Taste Explorer',
    control: 'Fitness Focused',
    socialFunction: 'Image',
    personality: 'Fitness Foodie',
    description:
      'You are a passionate adventurer of flavors and a fitness enthusiast, using food to showcase your unique identity and share your personal journey with the world.',
    image: fitnessFoodieImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Culinary Nomad',
    control: 'Fitness Focused',
    socialFunction: 'Socialization',
    personality: 'Fitness Foodie',
    description: `You're a chameleon in the kitchen, always seeking new flavors and cuisines. You strive to stay healthy and fit, but also know that food is meant to be enjoyed with friends and loved ones.`,
    image: fitnessFoodieImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Culinary Nomad',
    control: 'Fitness Focused',
    socialFunction: 'Norms',
    personality: 'Fitness Foodie',
    description: `You're an adventurous eater, always seeking out new cuisines and flavors. Health and fitness are important to you, but you also enjoy indulging in delicious meals.`,
    image: fitnessFoodieImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Culinary Nomad',
    control: 'Fitness Focused',
    socialFunction: 'Image',
    personality: 'Fitness Foodie',
    description: `You are a passionate food enthusiast, constantly seeking new flavors and experiences. You prioritize your health and fitness, using food as a way to nourish your body and fuel your active lifestyle. You also see food as an art form, using it to express your creativity and personality.`,
    image: fitnessFoodieImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Selective Taster',
    control: 'Fitness Focused',
    socialFunction: 'Socialization',
    personality: 'Fitness Foodie',
    description: `You're a chameleon in the kitchen, always seeking new flavors and cuisines. You strive to stay healthy and fit, but also know that food is meant to be enjoyed with friends and loved ones.`,
    image: fitnessFoodieImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Selective Taster',
    control: 'Fitness Focused',
    socialFunction: 'Norms',
    personality: 'Fitness Foodie',
    description: `You're an adventurous eater, always seeking out new cuisines and flavors. Health and fitness are important to you, but you also enjoy indulging in delicious meals.`,
    image: fitnessFoodieImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Selective Taster',
    control: 'Fitness Focused',
    socialFunction: 'Image',
    personality: 'Fitness Foodie',
    description: `You are a passionate food enthusiast, constantly seeking new flavors and experiences. You prioritize your health and fitness, using food as a way to nourish your body and fuel your active lifestyle. You also see food as an art form, using it to express your creativity and personality.`,
    image: fitnessFoodieImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Taste Explorer',
    control: 'Nature Focused',
    socialFunction: 'Socialization',
    personality: 'Natural Nomad',
    description: `You are an adventurous soul, always on the lookout for new flavors and experiences. You appreciate the simple beauty of nature and believe that food brings people together.`,
    image: naturalNomadImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Taste Explorer',
    control: 'Nature Focused',
    socialFunction: 'Norms',
    personality: 'Eco Gourmet',
    description: `You're like a passionate food explorer, always seeking new flavors and cuisines from around the world. You appreciate the beauty of nature and prefer to eat fresh, organic ingredients. You also pay attention to dietary norms for a healthy lifestyle.`,
    image: ecoGourmetImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Taste Explorer',
    control: 'Nature Focused',
    socialFunction: 'Image',
    personality: 'Eco Gourmet',
    description: `You're someone who's always on the move, seeking out new and exciting flavors from all corners of the world. You appreciate the beauty of nature and enjoy using food as a way to show off your true self.`,
    image: ecoGourmetImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Culinary Nomad',
    control: 'Nature Focused',
    socialFunction: 'Socialization',
    personality: 'Social Sampler',
    description: `You are a versatile person who is influenced by both personal and outside factors when deciding what to eat. You enjoy trying new foods, prefer natural options, and use food as a way to connect with people.`,
    image: socialSamplerImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Culinary Nomad',
    control: 'Nature Focused',
    socialFunction: 'Norms',
    personality: 'Aesthetic Athlete',
    description: `You are an adventurous eater, always seeking new flavors and experiences. You appreciate the beauty of nature and value traditions in cooking.`,
    image: aestheticAthleteImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Culinary Nomad',
    control: 'Nature Focused',
    socialFunction: 'Image',
    personality: 'Expressive Eater',
    description: `You're an adventurous eater who values wholesome, nourishing options. You let your taste buds guide you, always willing to try something new. Food is your way of expressing yourself.`,
    image: expressiveEaterImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Selective Taster',
    control: 'Nature Focused',
    socialFunction: 'Socialization',
    personality: 'Balanced Traditionalist',
    description: `You are a true food enthusiast who appreciates both tradition and sustainability. You enjoy familiar flavours and ingredients, and find joy in using food to foster connections and create memorable moments with loved ones.`,
    image: balancedTraditionalistImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Selective Taster',
    control: 'Nature Focused',
    socialFunction: 'Norms',
    personality: 'Eco Gourmet',
    description: `You, my friend, are the epitome of a food aficionado. You possess a blend of culinary desires, both within and outside yourself. Comforting flavours are your jam, and you value the goodness of nature. Moreover, you beautifully embrace cultural and societal dietary traditions. Bravo!`,
    image: ecoGourmetImage,
  },
  {
    // motivator: 'Universally Driven',
    openness: 'Selective Taster',
    control: 'Nature Focused',
    socialFunction: 'Image',
    personality: 'Balanced Traditionalist',
    description: `You are a versatile foodie, with a mix of interests. You enjoy tried-and-true flavors and opt for wholesome options. Food is a way for you to showcase your individuality.`,
    image: balancedTraditionalistImage,
  },
];

/**
 *
 * @param {{
 *  Openness: string,
 *  Control: string,
 *  Social_Function: string
 * }} param
 * @returns {CulinaryProfile}
 */
export function getPersonality({ Openness, Control, Social_Function }) {
  try {
    const culinaryProfile = personalityRules.find((criteria) => {
      return (
        Openness?.toLowerCase().trim() ===
          criteria.openness.toLowerCase().trim() &&
        Control?.toLowerCase().trim() ===
          criteria.control.toLowerCase().trim() &&
        Social_Function?.toLowerCase() ===
          criteria.socialFunction.toLowerCase().trim()
      );
    });

    if (!culinaryProfile) {
      return null;
    }

    return culinaryProfile;
  } catch (error) {
    console.log(error);
    return null;
  }
}
