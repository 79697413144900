import React from 'react';
import { Grid, Box } from '@chakra-ui/react';
import Navbar from 'components/navbar';

export const Scaffold = ({
  backgroundColor,
  backgroundImage,
  children,
  body,
  appBar,
  onClose,
  containerProps,
  scrollAreaProps,
  padding = '8'
}) => {
  return (
    <Grid
      backgroundColor={backgroundColor || null}
      backgroundImage={backgroundImage || null}
      backgroundRepeat="no-repeat"
      backgroundPosition="center center"
      backgroundSize="cover"
      width="full"
      gridTemplateRows="auto 1fr"
      rowGap="6"
      {...containerProps}
    >
      {appBar || <Navbar onClose={onClose} />}
      <Box
        p={padding}
        width="full"
        overflowX="hidden"
        {...scrollAreaProps}
      >
        {body || children}
      </Box>
    </Grid>
  );
};
