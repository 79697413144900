import { Box } from '@chakra-ui/react';
import Fallback from 'components/fallback';
import { analyticsEvents } from 'constants';
import { logEvent } from 'firebase/analytics';
import { useEffect } from 'react';
import { isAndroid, isBrowser, isIOS, isMacOs } from 'react-device-detect';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { externalUrls } from 'utils/const';
import { analytics } from 'utils/firebase';

export default function StoreRedirector() {
  const location = useLocation();
  const KEY_APP_ONLY = 'app_only';

  useEffect(() => {
    const param = new URLSearchParams(location.search);

    let storeURL = '';
    let platform = '';
    if (isBrowser) {
      /**
       * check if url has param app only in browser mode or desktop mode
       */
      if (!param.get(KEY_APP_ONLY)) {
        storeURL = externalUrls.extensionURL;
        platform = isMacOs ? 'MAC_OS' : 'DESKTOP';
      } else {
        /**
         * KEY APP ONLY FOUND
         * if param exists if user open with macOS
         * it will open app store url
         * else google play url
         */
        console.log('KEY APP ONLY FOUND');
        if (isMacOs) {
          storeURL = externalUrls.appStoreURL;
          platform = 'MAC_OS';
        } else {
          storeURL = externalUrls.googlePlayURL;
          platform = 'DESKTOP';
        }
      }
    } else if (isIOS) {
      /**
       * other than browser it will check
       * if user using IOS or Android
       */
      storeURL = externalUrls.appStoreURL;
      platform = 'IOS';
    } else if (isAndroid) {
      /**
       * other than browser it will check
       * if user using IOS or Android
       */
      storeURL = externalUrls.googlePlayURL;
      platform = 'ANDROID';
    } else {
      if (!param.get(KEY_APP_ONLY)) {
        storeURL = externalUrls.extensionURL;
      } else {
        /**
         * KEY APP ONLY FOUND
         */
        console.log('KEY APP ONLY FOUND');
        storeURL = externalUrls.googlePlayURL;
      }
      platform = 'UNKNOWN';
    }
    try {
      logEvent(analytics, analyticsEvents.redirectToStore.name, {
        storeURL: storeURL,
        platform: platform,
        referer: document.referrer,
      });
      setTimeout(() => {
        console.log({
          storeURL: storeURL,
          platform: platform,
          referer: document.referrer,
        });
        window.location.href = storeURL;
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  }, [location]);

  return (
    <Box
      width={'full'}
      h={'100vh'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Fallback />
    </Box>
  );
}
