import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Heading,
  Link,
  FormErrorMessage,
  Grid,
} from '@chakra-ui/react';

import SEO from 'components/SEO';
import ErrorBox from 'components/error-box';
// import ConfirmEmail from 'subviews/confirm-email';

import { path } from 'utils/const';
import { tests } from 'utils/validate';
import { actions } from 'slices/app.slice';
import FontIcon from 'components/font-icon';
import getFirstNameAndLastName from 'utils/get-first-and-last-name';
import { MobileSignUp } from './mobile-signup';

const contentMaxWidth = ['md', '480px'];

const Signup = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  const {
    me,
    loggedIn,
    loginType,
    retailerCurrentUrl,
    onboardingPayload,
    refCookie,
  } = useSelector((state) => state.app);

  // const [input, setInput] = useState({
  //   name: '',
  //   email: '',
  //   password: '',
  // });
  const [resErr, setResError] = useState('');
  const [isLoading, setLoading] = useState({
    email: false,
    anon: false,
    google: false,
    facebook: false,
  });

  // const [isOpen, setOpen] = useState(false);

  const {
    setFocus,
    getValues,
    handleSubmit,
    register,
    formState: { errors /* isSubmitting */ },
  } = useForm();

  // ------------------------------------
  // Handlers
  // ------------------------------------
  const onSubmit = async (data) => {
    // save form data
    // setInput(data);

    // merge flag to into form data
    const newData = {
      ...data,
      loggedIn,
      loginType,
      sign_up_source: !isEmpty(onboardingPayload) ? 'onboarding' : 'welcome',
    };
    if (data.referral) {
      newData.referral = {
        code: data.referral.toLowerCase().replace(/[^A-Z0-9]+/gi, ''),
        name: data.referral,
        isVerified: false,
      };
    }

    setLoading((previousState) => ({ ...previousState, email: true }));

    try {
      await dispatch(
        actions.signup(newData, retailerCurrentUrl, window.location.href)
      );

      // Save the onboarding data, if the
      // user authenticate from the onboarding page
      if (!isEmpty(onboardingPayload?.selectedPreferences)) {
        const userId = me?.id;
        const name = getFirstNameAndLastName(data.name);
        const subMemberId = uuidv4();

        const dataProfile = {
          userId: userId,
          name: name,
          subMemberId: subMemberId,
          preferences: onboardingPayload.selectedPreferences,
        };

        await dispatch(actions.addNewProfile(dataProfile));

        history.replace(path.onboardingFour);
      }

      // setOpen(true);
      setResError('');
    } catch (err) {
      setResError(t(err.code));
    } finally {
      setLoading((previousState) => ({ ...previousState, email: false }));
    }
  };

  if (process.env.REACT_APP_RUN_ON_FLUTTER) {
    return (
      <MobileSignUp
        formRegister={register}
        getFormValue={getValues}
        focusSetter={setFocus}
        error={resErr}
        isLoading={isLoading}
        onSignUpClick={handleSubmit(onSubmit)}
        key={'mobile-signup'}
      />
    );
  }

  return (
    <Fragment>
      <SEO title="FoodLama | Sign up" />
      <Box textAlign="left" width={contentMaxWidth}>
        {/* Render navigation button and error box when is not referall user  */}
        {!refCookie.isSupported ? (
          <Fragment>
            {resErr && <ErrorBox>{resErr}</ErrorBox>}
            <Grid
              templateColumns="15px 1fr"
              gap={['3', '5', '6']}
              mb="2"
              _hover={{ cursor: 'pointer' }}
              onClick={() => history.goBack()}
            >
              <Box fontSize="3xl">
                <FontIcon name="chevron-left" />
              </Box>
              <Heading fontSize="4xl" _hover={{ textDecoration: 'underline' }}>
                {t('sign-up')}
              </Heading>
            </Grid>
          </Fragment>
        ) : (
          // only render error box when is referall user
          <Fragment>
            {resErr && (
              <ErrorBox style={{ marginBottom: '0px', marginTop: '20px' }}>
                {resErr}
              </ErrorBox>
            )}
          </Fragment>
        )}
        <form>
          <Box boxSize="5" />
          <FormControl id="name" isInvalid={errors.name}>
            <FormLabel htmlFor="name">{t('name')}</FormLabel>
            <Input
              type="text"
              placeholder={t('auth-page/name-placeholder')}
              defaultValue={
                refCookie && refCookie.isSupported ? refCookie.name : null
              }
              {...register('name', {
                pattern: {
                  value: tests.name.test,
                  message: t(tests.name.error),
                },
              })}
            />
            <FormErrorMessage color="orange.500">
              {errors.name && errors.name.message}
            </FormErrorMessage>
          </FormControl>
          <Box boxSize="4" />
          <FormControl id="email" isInvalid={errors.email}>
            <FormLabel htmlFor="email">{t('email')}</FormLabel>
            <Input
              type="email"
              placeholder={t('auth-page/email-placeholder')}
              defaultValue={
                refCookie && refCookie.isSupported ? refCookie.email : null
              }
              {...register('email', {
                pattern: {
                  value: tests.email.test,
                  message: t(tests.email.error),
                },
              })}
            />
            <FormErrorMessage color="orange.500">
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
          <Box boxSize="4" />
          <FormControl id="password" isInvalid={errors.password}>
            <FormLabel>{t('auth-page/create-password')}</FormLabel>
            <Input
              type="password"
              placeholder={t('auth-page/password-placeholder')}
              {...register('password', {
                pattern: {
                  value: tests.password.test,
                  message: t(tests.password.error),
                },
              })}
            />
            <FormErrorMessage color="orange.500">
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>
          {refCookie && refCookie.isSupported && (
            <>
              <Box boxSize="4" />
              <FormControl id="referral" isInvalid={errors.referral}>
                <FormLabel htmlFor="referral">Referral</FormLabel>
                <Input
                  variant="filled"
                  type="text"
                  placeholder={t('auth-page/name-placeholder')}
                  value={refCookie.code}
                  readOnly={true}
                  {...register('referral', {
                    pattern: {
                      value: tests.referral.test,
                      message: t(tests.referral.error),
                    },
                  })}
                />
                <FormErrorMessage color="orange.500">
                  {errors.referral && errors.referral.message}
                </FormErrorMessage>
              </FormControl>
            </>
          )}
          <Box boxSize="6" />
          <Button
            width="full"
            color="white"
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading.email}
          >
            {t('continue')}
          </Button>
          <Box boxSize="5" />
          {/* don't render disclaimer text when the user is cuk */}
          {!refCookie.isSupported && (
            <Text color="gray">
              {t('auth-page/disclaimer')}{' '}
              <Link
                color="teal.500"
                textDecoration="underline"
                href="https://heyfoodlama.com/terms-and-conditions/"
                isExternal
              >
                {t('auth-page/terms')}
              </Link>{' '}
              {t('and')}{' '}
              <Link
                color="teal.500"
                textDecoration="underline"
                href="https://heyfoodlama.com/privacy-policy/"
                isExternal
              >
                {t('auth-page/privacy')}
              </Link>
            </Text>
          )}
          <Box boxSize="2.5" />
          <Heading
            size="sm"
            color="teal.500"
            // based on figma design cuk user sign-in link is center aligned
            textAlign={refCookie.isSupported ? 'center' : undefined}
          >
            {t('auth-page/already-member')}{' '}
            <Button
              variant="link"
              textDecoration="underline"
              fontWeight={400}
              onClick={() => history.push(path.login)}
            >
              {t('sign-in')}
            </Button>
          </Heading>
        </form>
        {/* <ConfirmEmail */}
        {/*   email={input.email} */}
        {/*   isOpen={isOpen} */}
        {/*   toggle={() => setOpen((prev) => !prev)} */}
        {/*   onSubmit={() => { */}
        {/*     setOpen((prev) => !prev); */}
        {/*     history.push(path.login); */}
        {/*   }} */}
        {/* /> */}
      </Box>
    </Fragment>
  );
};

export default Signup;
