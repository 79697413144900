import { isEmpty } from 'lodash';
import { t } from 'i18next';

import {
  analyticsEvents,
  authenticationMethod,
  supportedRetailers,
} from '../constants';

/**
 *
 * @param {{name: string, type: 'active' | 'interactive'}} event
 * @param {Object<string, any>} params
 * @returns {{state: 'pass' | 'fail', error?: string}}
 */
const checkEventSanity = (event, params) => {
  if (event.name === analyticsEvents.appInstalled.name) {
    if (!params.version) {
      return { state: 'fail', error: t('invalid-app-version-error') };
    }

    if (!params.firstSession) {
      return {
        state: 'fail',
        error: t('sanity-check/first-session-error'),
      };
    }
  }

  if (event.name === analyticsEvents.appUninstalled.name) {
    if (!params.version) {
      return { state: 'fail', error: t('invalid-app-version-error') };
    }

    if (!params.clickeranceCurrentUrl.includes('/uninstall')) {
      return {
        state: 'fail',
        error: t('sanity-check/uninstall-event-outside-url-error'),
      };
    }
  }

  if (
    event.name === analyticsEvents.login.name ||
    event.name === analyticsEvents.signUp.name
  ) {
    if (
      params.method === authenticationMethod.email ||
      params.method === authenticationMethod.google ||
      params.method === authenticationMethod.facebook
    ) {
      const email = params.loginEmail || params.signUpEmail;

      if (!email) {
        return {
          state: 'fail',
          error: t('sanity-check/email-auth-no-email-error'),
        };
      }
    }

    if (
      !['/welcome', '/login', '/signup'].some((term) =>
        params.clickeranceCurrentUrl?.includes(term)
      )
    ) {
      return {
        state: 'fail',
        error: t('sanity-check/auth-outside-url-error'),
      };
    }
  }

  if (
    event.name === analyticsEvents.addPreferenceProfile.name ||
    event.name === analyticsEvents.editPreferenceProfile.name ||
    event.name === analyticsEvents.deletePreferenceProfile.name
  ) {
    if (
      !params.memberId ||
      isEmpty(params.selectedPreferences) ||
      (!event.name === analyticsEvents.deletePreferenceProfile.name &&
        !params.name)
    ) {
      return {
        state: 'fail',
        error: t('sanity-check/preference-profile-form-are-empty'),
      };
    }

    if (!params.clickeranceCurrentUrl.includes('preferences')) {
      return {
        state: 'fail',
        error: t('sanity-check/preference-profile-outside-url-error'),
      };
    }
  }

  if (event.name === analyticsEvents.togglePreferenceProfile.name) {
    if (!params.memberId && !params.toggleState) {
      return {
        state: 'fail',
        error: t('sanity-check/preference-profile-state-are-invalid'),
      };
    }

    if (!params.clickeranceCurrentUrl.includes('/dashboard')) {
      return {
        state: 'fail',
        error: t('sanity-check/preference-profile-outside-url-error'),
      };
    }
  }

  if (event.name === analyticsEvents.stickerClick.name) {
    if (!params.productId) {
      return {
        state: 'fail',
        error: t('sanity-check/sticker-event-invalid-product-id'),
      };
    }

    if (params.currentPreferenceProfilesAmount === 0) {
      return {
        state: 'fail',
        error: t('sanity-check/sticker-event-empty-profile'),
      };
    }

    if (
      !supportedRetailers
        .map((supportedRetailer) => supportedRetailer.name)
        .some((term) => params.retailerCurrentUrl.includes(term))
    ) {
      return {
        state: 'fail',
        error: t('sanity-check/sticker-event-outside-url-error'),
      };
    }
  }

  if (event.name === analyticsEvents.recommendationProductClick.name) {
    if (params.isSidebarHidden) {
      return {
        state: 'fail',
        error: t('sanity-check/recommendation-event-click-when-sidebar-hidden'),
      };
    }
  }

  if (event.name === analyticsEvents.beginCheckout.name) {
    if (isEmpty(params.items)) {
      return {
        state: 'fail',
        error: t('sanity-check/checkout-event-items-is-empty'),
      };
    }

    if (!params.retailerCurrentUrl.includes('checkout')) {
      return {
        state: 'fail',
        error: t('sanity-check/checkout-event-outside-url-error'),
      };
    }
  }

  return { state: 'pass' };
};

export default checkEventSanity;
