export const getIndefiniteArticle = (str) => {
  if (str.length === 0) return false;

  const firstChar = str[0].toLowerCase();
  const isVowel = ['a', 'e', 'i', 'o', 'u'].includes(firstChar);
  if (isVowel) {
    return 'an';
  }

  return 'a';
};
