import '@fontsource/roboto/400.css';

import { Fragment, useCallback, useEffect, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import {
  FirebaseAppProvider,
  FirestoreProvider,
  AnalyticsProvider,
  useFirebaseApp,
} from 'reactfire';
import { QueryClient, QueryClientProvider } from 'react-query';

import { loadFontIcons } from 'components/font-icon';
import FontsLoader from 'components/fonts-loader';
import store from 'utils/store';
import Router from './router';
import theme from './theme';
import config from './utils/config';
import { actions } from 'slices/app.slice';
import {
  FL_INDEXED_DB_NAME,
  FL_INDEXED_DB_VERSION,
  getIDBData,
  openIndexedDB,
} from 'utils/indexed-db-manager/indexed-db';
import { availableReferrals } from 'constants';
import { debounce } from 'lodash';
import Fallback from 'components/fallback';
import OverlayFallback from 'components/fallback/overlay-fallback';
import ModalAvatarForm from 'components/modal/ModalAvatarForm';
import OverlaySubscription from 'components/subscription/OverlaySubscription';
import { ErrorBoundary } from 'react-error-boundary';
import logEvent from 'utils/log-event';
import { analyticsEvents } from 'constants';
import ErrorBoundaryFallback from 'components/fallback/error-boundary/ErrorBoundaryFallback';

function ReactQueryWrapper({ children }) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnMount: false, // prevent refetch when page mounting,
        staleTime: Infinity,
        cacheTime: Infinity,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

function RouterWrapper() {
  const firebaseApp = useFirebaseApp();
  const firestoreInstance = getFirestore(firebaseApp);
  const analyticsInstance = getAnalytics(firebaseApp);

  const dispatch = useDispatch();
  const {
    refCookie,
    isShowTransparentOverlayLoader,
    isShowOverlayLoader,
    isShowOverlaySubscription,
    isPremiumSubscriber,
  } = useSelector((state) => state.app);

  /**
   * Function to check data inside IndexedDB and save it to refCookie state
   */
  const checkIndexedDB = useCallback(async () => {
    try {
      const refferallKeyName = 'ref_code';
      const db = await openIndexedDB(FL_INDEXED_DB_NAME, FL_INDEXED_DB_VERSION);
      const ref = await getIDBData(db, refferallKeyName);
      if (!ref) {
        // referral code not found
        return;
      }
      const isSupported =
        availableReferrals.findIndex(
          (availableRef) =>
            availableRef.code === ref.toLowerCase().replace(/[^A-Z0-9]+/gi, '')
        ) !== -1;
      if (!isSupported) {
        dispatch(actions.clearRefCookie());
        return;
      }
      if (Object.keys(refCookie).length === 0) {
        dispatch(
          actions.setRefCookieOnly({
            code: ref,
            isSupported: true,
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, refCookie]);

  useEffect(() => {
    const debounceCheckDB = debounce(checkIndexedDB, 100);
    const intervalCheck = setInterval(() => {
      debounceCheckDB();
    }, 500);
    // Cleanup interval when the component unmounts
    return () => {
      clearInterval(intervalCheck);
    };
  }, [checkIndexedDB]);

  // init feature flags
  useEffect(() => {
    const fetchFeatureFlags = async () => {
      await dispatch(actions.getFeatureFlag());
    };

    fetchFeatureFlags();
  }, [dispatch]);

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <AnalyticsProvider sdk={analyticsInstance}>
        <div data-testid="app" className="app">
          <ReactQueryWrapper>
            <Fragment>
              <Router />
              {isShowTransparentOverlayLoader && (
                <OverlayFallback isUseAlpha={true} />
              )}
              {isShowOverlayLoader && <OverlayFallback isUseAlpha={false} />}
              {isShowOverlaySubscription && !isPremiumSubscriber && (
                <OverlaySubscription />
              )}
              <ModalAvatarForm />
            </Fragment>
          </ReactQueryWrapper>
        </div>
      </AnalyticsProvider>
    </FirestoreProvider>
  );
}

function App() {
  /**
   * boolean state for
   */
  const [isOtMetaRendered, setIsMetaMetaRendered] = useState(false);

  /**
   * function to render origin trials token
   * this token is used to enable deprecation feature
   * of storage partitioning
   * this trials only valid until 03/09/2024
   * developer need to find new way to update authentication flow
   * or anything related to browser storage
   */
  const renderOTToken = useCallback(() => {
    const TOKEN = process.env.REACT_APP_DISABLE_PARTITIONING_TOKEN;
    const otMeta = document.createElement('meta');
    otMeta.httpEquiv = 'origin-trial';
    otMeta.content = TOKEN;
    document.head.prepend(otMeta);
    setIsMetaMetaRendered(true);
  }, []);

  /**
   * @param {{
   *  error: Error,
   *  info: {
   *    componentStack: string,
   *  }
   * }} param
   */
  const logErrorBoundary = (error, info) => {
    const dataToSave = {
      error: error,
      info: info.componentStack,
    };
    logEvent(analyticsEvents.errorBoundary, dataToSave);
  };

  useEffect(() => {
    loadFontIcons();
  }, []);

  useEffect(() => {
    /**
     * render token when app run on the first time
     */
    renderOTToken();
  }, [renderOTToken]);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onError={logErrorBoundary}
    >
      <FirebaseAppProvider firebaseConfig={config.firebase}>
        <Provider store={store}>
          <ChakraProvider theme={theme}>
            {isOtMetaRendered ? (
              <Fragment>
                <FontsLoader />
                <RouterWrapper />
              </Fragment>
            ) : (
              <Fallback />
            )}
          </ChakraProvider>
        </Provider>
      </FirebaseAppProvider>
    </ErrorBoundary>
  );
}

export default App;
