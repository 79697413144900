import asdaCategories from 'datas/asda_categories.json';

import { isEmpty, negate } from 'lodash';

const isNotEmpty = negate(isEmpty);

const transformCategoriesIdsIntoCategoriesNames = (ids) => {
  if (isNotEmpty(ids)) {
    const categoriesNames = ids.reduce((acc, curr) => {
      const categories = asdaCategories.find(({ id }) => id === curr);

      if (categories) {
        return [...acc, categories.name];
      }

      return acc;
    }, []);

    return categoriesNames;
  }

  return [];
};

export default transformCategoriesIdsIntoCategoriesNames;
