import productViewMessages from './product-view.json';
import createRecommendationCongrats from './create-recommendation-congrats.json';
import alternativesPage from './recommendation-alternatives.json';

const pageMessages = {
  ...productViewMessages,
  ...alternativesPage,
  ...createRecommendationCongrats,
};

export default pageMessages;
