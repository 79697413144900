import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Spacer, Image, Spinner, Center } from '@chakra-ui/react';

import Logo from '../../assets/images/foodlama-logo-long.svg';
import { CloseButton } from './components';

import { actions } from '../../slices/app.slice';

import './custom-header.scss';
import { checkCUKReferralOnMeObject } from 'utils/cuk-module/cuk-module';
import { CUKxFLNavbar } from 'components/navbar/navbar';
import { parentPostMessage } from 'utils/postmessage-module/postMessage';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { path } from 'utils/const';

// TODO: Replace `trailing` props with `actions`
const Navbar = ({
  height,
  leading,
  title,
  trailing,
  headerIcon,
  backgroundImage,
  backgroundColor,
  borderless,
  isCentered,
  onClose,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const { me, refCookie } = useSelector((state) => state.app);

  
  const isOpenedInIframe = window.self !== window.top;

  const history = useHistory();

  const minimizeSidebar = () => {

    parentPostMessage('MINIMIZE_SIDEBAR_AND_ENABLE_HOVER', '*');
    history.replace(path.dashboard);

    // set this global state to keep track of spotlight status
    dispatch(actions.setSpotlightStatus('off'));
  };

  const renderDefaultTrailing = () => {
    if (!isOpenedInIframe) {
      return <Box />;
    }

    return (
      <Box pr={4}>
        <CloseButton onCloseClick={onClose || minimizeSidebar} />
      </Box>
    );
  };

  return (
    <Box
      height={height || null}
      borderBottom={borderless ? null : '1px'}
      backgroundColor={backgroundColor || 'white'}
      backgroundImage={backgroundImage || null}
      backgroundRepeat="no-repeat"
      backgroundPosition="center center"
      backgroundSize="cover"
      width="full"
      borderColor="white.300"
      position="sticky"
      top="0px"
      py="4"
      overflow="hidden"
      zIndex="99" // prevent content scroll above navbar
    >
      <Flex
        transform={isCentered ? 'translateY(-50%)' : null}
        top={isCentered ? '50%' : null}
        position="relative"
        alignItems="center"
      >
        <Flex justifyContent="center" alignItems="center">
          {leading}
          {checkCUKReferralOnMeObject(me) ||
          (refCookie && refCookie.isSupported) ? (
            <CUKxFLNavbar />
          ) : (
            title || (
              <Box pl={8}>
                <Image
                  width={['40', '56', '64']}
                  src={Logo}
                  alt="foodlama-logo"
                  loading="lazy"
                />
              </Box>
            )
          )}
        </Flex>
        <Spacer />
        {isLoading ? (
          <Box p={4}>
            <Center>
              <Spinner color="teal.500" size="sm" />
            </Center>
          </Box>
        ) : (
          trailing || renderDefaultTrailing()
        )}
      </Flex>
      {headerIcon}
    </Box>
  );
};

export default Navbar;
