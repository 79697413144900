import { auth } from './firebase';

const getToken = async () => {
  if (!auth.currentUser) {
    return null;
  }

  const token = await auth.currentUser.getIdToken();

  return token;
};

export default getToken;
