import getSymbolFromCurrency from 'currency-symbol-map';
import { numericFormatter } from 'react-number-format';

export const convertPriceToValidStringPrice = (price) => {
  let priceToConvert = `${price}`.toLowerCase();
  priceToConvert = priceToConvert.replace(',', '.');
  let isPennies = priceToConvert.includes('p');

  try {
    let finalNumber;
    if (priceToConvert.includes('.')) {
      finalNumber = Number.parseFloat(priceToConvert);
    } else {
      finalNumber = Number.parseInt(priceToConvert);
    }
    if (isPennies) {
      finalNumber /= 100;
    }

    if (finalNumber === 0) {
      return '???';
    }

    return finalNumber.toLocaleString('en-GB', {
      style: 'currency',
      currency: 'GBP',
    });
  } catch (error) {
    return '???';
  }
};

/**
 * Format Price to Currency Price
 * @param {number} price
 * @param {string} currencyCode
 * @returns
 */
export const convertNumberToCurrency = (price, currencyCode) => {
  try {
    let symbol = getSymbolFromCurrency(currencyCode);
    if (!symbol) {
      symbol = currencyCode;
    }
    const numberFormat = numericFormatter(`${price}`, {
      thousandSeparator: true,
      decimalScale: 2,
    });
    return `${symbol}${numberFormat}`;
  } catch (error) {
    console.log(error);
    return price;
  }
};
