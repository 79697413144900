import {
  extendTheme,
  withDefaultColorScheme,
  withDefaultSize,
} from '@chakra-ui/react';

import componentsTheme from './components';

const theme = extendTheme(
  {
    styles: {
      global: {
        body: {
          color: 'black.500',
        },
      },
    },
    fonts: {
      heading: 'TT Interphases, sans-serif',
      body: 'Roboto, sans-serif',
    },
    components: componentsTheme,
    // Generated by https://colors.eva.design/
    // From these base colors:
    // orange: #FF7250
    // yellow: #FEC50B
    // teal: #045050
    // green: #04CEAA
    // black: #191A1A
    // white: #C4CCCC
    // cultured: #F7F8F8
    // light cyan: #D9DEDE
    // white smoke: #F4F5F5
    // light green: #C6FF6E
    // dark cyan: #195851
    // light red: #FFCDC7
    // light yellow: #F8EAA5
    // red orange: #FE9168
    // gray: #777777
    // lightTeal: #008080
    // greenCyan: #E6FAF6
    // tealBlue: #204F4F
    // softOrange: #F09670
    colors: {
      orange: {
        100: '#FFEDDC',
        200: '#FFD6B9',
        300: '#FFBA96',
        400: '#FF9F7B',
        500: '#FF7250',
        600: '#DB4D3A',
        700: '#B72E28',
        800: '#93191D',
        900: '#7A0F1A',
      },
      yellow: {
        100: '#FEF8CE',
        200: '#FEEF9D',
        300: '#FEE36C',
        400: '#FED847',
        500: '#FEC50B',
        600: '#DAA308',
        700: '#B68405',
        800: '#936703',
        900: '#795202',
      },
      teal: {
        100: '#C7F6E6',
        200: '#93EDD6',
        300: '#57CAB6',
        400: '#2B968C',
        500: '#045050', // Primary Color
        600: '#023F44',
        700: '#022F39',
        800: '#01222E',
        900: '#001926',
      },
      green: {
        100: '#CBFCE1',
        200: '#98FACC',
        300: '#62F0BC',
        400: '#3BE1B4',
        500: '#04CEAA',
        600: '#02B1A2',
        700: '#029294',
        800: '#016B77',
        900: '#004F62',
      },
      black: {
        100: '#F1F3F2',
        200: '#E4E8E7',
        300: '#B6BAB9',
        400: '#727575',
        500: '#191A1A',
        600: '#121616',
        700: '#0C1112',
        800: '#070D0F',
        900: '#04090C',
      },
      white: {
        100: '#FAFCFB',
        200: '#F5F9F8',
        300: '#EAEFEE',
        400: '#D9E0DF',
        500: '#C4CCCC',
        600: '#8FACAF',
        700: '#628A92',
        800: '#3E6776',
        900: '#254D61',
      },
      cultured: {
        100: '#FEFEFE',
        200: '#FDFEFE',
        300: '#FCFCFC',
        400: '#FAFAFA',
        500: '#F7F8F8',
        600: '#B4D2D5',
        700: '#7CA9B2',
        800: '#4E7E8F',
        900: '#2F5E77',
      },
      lightCyan: {
        100: '#FCFDFC',
        200: '#F9FBFB',
        300: '#F1F5F4',
        400: '#E7EBEA',
        500: '#D9DEDE',
        600: '#9EBCBE',
        700: '#6D979F',
        800: '#457180',
        900: '#29546A',
      },
      whiteSmoke: {
        100: '#FEFEFE',
        200: '#FDFEFD',
        300: '#FBFCFB',
        400: '#F8F9F8',
        500: '#F4F5F5',
        600: '#B2CFD2',
        700: '#7AA7B0',
        800: '#4D7D8E',
        900: '#2E5D75',
      },
      lightGreen: {
        100: '#F8FFE2',
        200: '#EFFFC5',
        300: '#E3FFA8',
        400: '#D8FF92',
        500: '#C6FF6E',
        600: '#9FDB50',
        700: '#7AB737',
        800: '#5A9323',
        900: '#427A15',
      },
      darkCyan: {
        100: '#D3F6E5',
        200: '#AAEED3',
        300: '#74CCB2',
        400: '#479A89',
        500: '#195851',
        600: '#124B4A',
        700: '#0C3B3F',
        800: '#072B33',
        900: '#04202A',
      },
      lightRed: {
        100: '#FFF8F3',
        200: '#FFF0E8',
        300: '#FFE6DD',
        400: '#FFDCD5',
        500: '#FFCDC7',
        600: '#DB9391',
        700: '#B76468',
        800: '#933F4A',
        900: '#7A2638',
      },
      lightYellow: {
        100: '#FEFCED',
        200: '#FEFADC',
        300: '#FCF6CA',
        400: '#FAF1BB',
        500: '#F8EAA5',
        600: '#D5C478',
        700: '#B29F53',
        800: '#8F7B34',
        900: '#77621F',
      },
      redOrange: {
        100: '#FEF1E0',
        200: '#FEDFC2',
        300: '#FEC9A4',
        400: '#FEB48D',
        500: '#FE9168',
        600: '#DA694C',
        700: '#B64634',
        800: '#932821',
        900: '#791314',
      },
      gray: {
        100: '#F8F8F8',
        200: '#F1F1F1',
        300: '#D6D6D6',
        400: '#ADADAD',
        500: '#777777',
        600: '#665658',
        700: '#553B40',
        800: '#45252E',
        900: '#391622',
      },
      lightTeal: {
        100: '#C6F8E7',
        200: '#91F2D9',
        300: '#56D8C2',
        400: '#2CB2A7',
        500: '#008080',
        600: '#00646E',
        700: '#004C5C',
        800: '#00364A',
        900: '#00283D',
      },
      greenCyan: {
        100: '#FAFEFC',
        200: '#F6FEFA',
        300: '#F1FDF8',
        400: '#ECFBF7',
        500: '#E6FAF6',
        600: '#A8D7D2',
        700: '#73B3B3',
        800: '#498A90',
        900: '#2C6A77',
      },
      tealBlue: {
        100: '#D8F6EC',
        200: '#B4EDDE',
        300: '#82CABD',
        400: '#52958F',
        500: '#204F4F',
        600: '#174043',
        700: '#103138',
        800: '#0A242D',
        900: '#061A25',
      },
      softOrange: {
        100: '#FEF2E3',
        200: '#FDE2C7',
        300: '#FACCAA',
        400: '#F6B793',
        500: '#F09670',
        600: '#CE6E51',
        700: '#AC4B38',
        800: '#8B2D23',
        900: '#731815',
      },
      coeliacUkTheme: {
        sponsorBannerBackground: '#C2F7FF',
      },
      borderColor: '#1515151A',
    },
  },
  withDefaultColorScheme({
    colorScheme: 'teal',
    components: [
      'Textarea',
      'Button',
      'Link',
      'Input',
      'FormControl',
      'Spinner',
      'Checkbox',
      'Switch',
    ],
  }),
  withDefaultSize({
    size: 'lg',
    components: ['Button'],
  }),
  withDefaultSize({
    size: 'xl',
    components: ['Spinner'],
  })
);

export default theme;
