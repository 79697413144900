import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isNil, negate } from 'lodash';
import { actions } from 'slices/app.slice';
import postProduct from 'utils/post-product';
import { parentPostMessage } from 'utils/postmessage-module/postMessage';
import { getProductByID } from 'utils/fetch-product-module';
import { checkCUKReferralOnMeObject } from 'utils/cuk-module/cuk-module';

const isNotEmpty = negate(isEmpty);
const isBlank = (v) => isEmpty(v) || isNil(v);

export function useProductStream() {
  const dispatch = useDispatch();
  const { loggedIn, members, me, isCreatorsFeatureEnabled } = useSelector(
    (state) => state.app
  );
  const { recommendations: creatorRecommendations } = useSelector(
    (state) => state.creators
  );

  useEffect(() => {
    function sendProductToExt({ analyzedProduct, origin }) {
      // get creator recommendation
      const currentRecData = creatorRecommendations.find(
        (product) => product.productId === analyzedProduct.productId
      );

      // check if product already recommended
      const isProductAlreadyRecommended =
        !isBlank(currentRecData?.note) || !isBlank(currentRecData?.tags);

      dispatch(
        actions.postProduct({
          origin,
          analyzedProduct,
        })
      );

      const message = {
        channel: 'PROCESS_PRODUCT',
        analyzedProduct,
        members, // need to add this property to render empty label
        isCreator: isCreatorsFeatureEnabled,
        isProductAlreadyRecommended,
      };

      parentPostMessage(message, '*');
    }

    async function listenProductMobileAppVersion(product, retailer, origin) {
      try {
        const productId = product.productId;
        const retailerName = retailer.name;

        const result = await getProductByID(retailerName, productId);

        if (!result) {
          sendProductToExt({
            origin,
            analyzedProduct: {
              ...product,
              isDetailed: false,
              hasError: true,
            },
          });
          return;
        }

        const analyzedProduct = {
          ...result.product,
          userPrefMatch: result.userPrefMatch,
          url: result.product.url ?? '',
        };

        const dataToSent = {
          analyzedProduct,
          origin,
        };

        sendProductToExt(dataToSent);
      } catch (error) {
        sendProductToExt({
          origin,
          analyzedProduct: {
            ...product,
            isDetailed: false,
            hasError: true,
          },
        });
        console.error('Error posting product', error);
      }
    }

    // Listen and receive the product data from the parent extension.
    async function listenForProducts(event) {
      // Verify whether the data received is a valid object,
      // so it should confirm that the data is a raw product sent by the extension itself.
      if (
        isNotEmpty(event.data?.retailer) &&
        isNotEmpty(event.data?.product) &&
        creatorRecommendations !== null &&
        // Only post the product if the user is authenticated
        loggedIn &&
        me
      ) {
        try {
          // Valid raw product data.
          // TODO: sanity check the payload and make sure extension doesn't
          // send any empty data.

          const { product, retailer, origin } = event.data;

          if (process.env.REACT_APP_RUN_ON_FLUTTER) {
            listenProductMobileAppVersion(product, retailer, origin);
            return;
          }

          const result = await postProduct({
            product,
            retailer,
            options: { withJustifications: false },
          });

          if (!result.data) {
            sendProductToExt({
              analyzedProduct: {
                ...product,
                hasError: true,
                isDetailed: false,
              },
              origin,
            });

            return;
          }

          const resultData = result.data.data;
          if (!checkCUKReferralOnMeObject(me)) {
            try {
              let updatedFlags = resultData.flags.filter(
                (f) => f.name !== 'crossedgrain_certified'
              );
              resultData.flags = updatedFlags;
            } catch (error) {
              console.log(error);
            }
          }

          const analyzedProduct = {
            ...resultData,
            url: result.data.data.url || product.url, // fallback to url we extract from client
          };

          sendProductToExt({
            analyzedProduct,
            origin,
          });
        } catch (error) {
          const { product, origin } = event.data;
          sendProductToExt({
            origin,
            analyzedProduct: {
              ...product,
              isDetailed: false,
              hasError: true,
            },
          });

          console.error('Error posting product', error);
        }
      }
    }

    window.addEventListener('message', listenForProducts);

    return function cleanup() {
      window.removeEventListener('message', listenForProducts);
    };
  }, [
    loggedIn,
    dispatch,
    members,
    me,
    creatorRecommendations,
    isCreatorsFeatureEnabled,
  ]);
}
