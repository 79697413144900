import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import app from 'slices/app.slice';
import creators from 'slices/creators.slice';
import products from 'slices/products.slice';
import landing from 'slices/landing.slice';

const store = configureStore({
  reducer: {
    app,
    creators,
    products,
    landing,
  },
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV === 'production'
      ? [...getDefaultMiddleware({
        serializableCheck: false
      })]
      : [...getDefaultMiddleware({
        serializableCheck: false
      }), logger],
});

export default store;
