import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { t } from 'i18next';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Box,
  Heading,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react';

import { tests } from 'utils/validate';

const SendPasswordResetEmail = ({ isOpen, onSubmit, onClose }) => {
  const {
    handleSubmit,
    register,
    formState: { errors /* isSubmitting */ },
    reset,
  } = useForm();

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {process.env.REACT_APP_RUN_ON_FLUTTER ? <Box height={"6"}/> : (
          <ModalHeader textAlign="center">
            {t('auth-page/step-one')}
          </ModalHeader>
        )}
        <ModalBody pb={4}>
          <Box
            display="flex"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box maxW="71%">
              <Heading size="md" textAlign="center">
                {t('auth-page/reset-password')}
              </Heading>
              <Text mt="4" color="gray" textAlign={'center'}>
                {t('auth-page/enter-email')}
              </Text>
            </Box>
            {/* <form> */}
            <FormControl maxW="85%" mt="8" id="email" isInvalid={errors.email}>
              <FormLabel htmlFor="email">{t('email')}</FormLabel>
              <Input
                type="email"
                placeholder={t('email')}
                {...register('email', {
                  pattern: {
                    value: tests.email.test,
                    message: t(tests.email.error),
                  },
                })}
              />
              <FormErrorMessage color="orange.500">
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            {/* </form> */}
          </Box>
        </ModalBody>
        <ModalFooter justifyContent="center" pb={'6'}>
          <Button width="85%" onClick={handleSubmit(onSubmit)}>
            {t('auth-page/send-reset-email')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SendPasswordResetEmail;
