/* eslint-disable no-use-before-define */
/**
 * Get categories ids from the current page
 *
 * @example
 * ```js
 * const url = "https://groceries.asda.com/aisle/food-cupboard/biscuits-crackers/crackers-rice-cakes/1215337189632-1215686353224-1215686353211"
 *
 * console.log(getCategoriesIdsFromUrl(url))
 *
 * // output
 * ["1215337189632", "1215337189632-1215686353224", "1215337189632-1215686353224-1215686353211"]
 * ```
 *
 * @param {string} url
 * @returns {string[]}
 */
const getCategoriesIdsFromUrl = (url) => {
  const texts = url.split('/');

  const categoriesPhrases = ['cat', 'dept', 'aisle', 'shelf'];

  const hasCategoriesPhrases = texts.some((text) =>
    categoriesPhrases.includes(text)
  );

  const hasGroupedCategoriesId =
    texts.findIndex((item) => /^\d+$/.test(item.replace(/-/g, ''))) > -1;

  if (hasCategoriesPhrases && hasGroupedCategoriesId) {
    const groupedCategoriesId = getGroupedCategoriesId(texts);

    const categoriesIds = getCategoriesIds(groupedCategoriesId.split('-'));

    return categoriesIds;
  }

  return [];
};

export default getCategoriesIdsFromUrl;

/**
 * Get categories id from URL
 *
 * @example
 * ```js
 * const url = "https://groceries.asda.com/aisle/food-cupboard/biscuits-crackers/crackers-rice-cakes/1215337189632-1215686353224-1215686353211"
 *
 * console.log(getGroupedCategoriesId(url))
 *
 * // output
 * "1215337189632-1215686353224-1215686353211"
 * ```
 *
 * @param {string} textUrl
 * @returns {string}
 */
const getGroupedCategoriesId = (textUrl) => {
  const numberIndex = textUrl.findIndex((item) =>
    /^\d+$/.test(item.replace(/-/g, ''))
  );

  const groupedCategoriesId = textUrl[numberIndex];

  return groupedCategoriesId;
};

const getCategoriesIds = (rawIds, categoriesIds = []) => {
  const _rawIds = JSON.parse(JSON.stringify(rawIds));
  const _categoriesIds = JSON.parse(JSON.stringify(categoriesIds));

  if (_rawIds.length === 1) {
    _categoriesIds.push(_rawIds[0]);

    return _categoriesIds;
  }

  _categoriesIds.push(_rawIds.join('-'));

  _rawIds.pop();

  return getCategoriesIds(_rawIds, _categoriesIds);
};
