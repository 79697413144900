import { useQueries } from 'react-query';
import { isNil, isEmpty, negate, sortBy } from 'lodash';
import fetchRecommendations from '../utils/query-functions/fetch-recommendations';
import filterValidProducts from '../utils/filter-valid-products';
import { useSelector } from 'react-redux';
import scoreProduct from '../utils/score-product';
import { recommendationExcludedCats } from '../constants';
import analyzeProductForPreference from '../utils/analyze-product-for-preference';
import deduplicatePreferences from '../utils/deduplicate-preferences';
import parseProfiles from '../utils/get-active-profiles';
import { checkCUKReferralOnMeObject } from 'utils/cuk-module/cuk-module';

const isNotNil = negate(isNil);
const isNotEmpty = negate(isEmpty);

const useRecommendations = ({
  categories = [],
  retailerCurrentUrl,
  profiles,
}) => {
  const recommendationsQueries = useQueries(
    categories.map((cat) => {
      return {
        // TODO: Consider adding preference combination as part of the key so we don't
        // need to clear the cache when user modifies their profile.
        queryKey: ['recommendations', { categories: cat }],
        queryFn: () =>
          fetchRecommendations({
            categories: [cat],
            retailerCurrentUrl,
            profiles,
          }),
      };
    })
  );

  const categoriesRecommendation =
    recommendationsQueries
      ?.flatMap((recommendation) => recommendation.data)
      .filter((data) => isNotNil(data)) ?? [];

  /*
   * Use products on visited page to recommendation list.
   */

  const { productIdFromExtension, productGroups, me } = useSelector(
    (state) => state.app
  );

  const productsOnCurrentPage = productGroups.flatMap(
    ({ analyzedProduct }) => analyzedProduct
  );

  const mergedRecommendations = deduplicateProducts([
    ...categoriesRecommendation.map((rec) => ({ ...rec, source: 'category' })),
    ...productsOnCurrentPage.map((rec) => ({ ...rec, source: 'page' })),
  ])
    // Exclude product that's being viewed.
    .filter(({ productId }) => productId !== productIdFromExtension)
    .map((product) => {
      let updatedProduct = { ...product };
      if (!checkCUKReferralOnMeObject(me)) {
        try {
          let updatedFlags = updatedProduct.flags.filter(
            (f) => f.name !== 'crossedgrain_certified'
          );
          updatedProduct.flags = updatedFlags;
        } catch (error) {
          console.log(error);
        }
      }
      return scoreProductAndAddStickerVariant({
        product: updatedProduct,
        categories,
        profiles,
      });
    });

  const sortedRecommendations = sortBy(mergedRecommendations, ['score']);

  return {
    isLoading: recommendationsQueries.some(
      (recommendation) => recommendation.isLoading === true
    ),
    datas: isNotEmpty(sortedRecommendations)
      ? sortedRecommendations
      : undefined,
  };
};

export default useRecommendations;

const deduplicateProducts = (products) => {
  const deduplicatedProducts = products.reduce((acc, curr) => {
    if (!acc.find((product) => product.productId === curr.productId)) {
      return [...acc, curr];
    }
    return acc;
  }, []);

  return filterValidProducts(deduplicatedProducts);
};

const scoreProductAndAddStickerVariant = ({
  product,
  profiles,
  categories,
}) => {
  // Lowest Score -> most recommended product
  const score = scoreProduct({
    product,
    preferences: deduplicatePreferences(
      parseProfiles(profiles).flatMap(({ preferences }) => preferences)
    ),
    categories,
  });

  const hasCrossedGrainCertification = product.flags?.some(
    (flag) => flag.name === 'crossedgrain_certified' && flag.value === 1
  );

  const crossGrainScore = hasCrossedGrainCertification ? -20000 : 0;

  const scoreAddition = !product.categories?.some(
    (cat) =>
      typeof cat === 'string' &&
      recommendationExcludedCats.includes(cat.toLowerCase())
  )
    ? 0
    : 1000;

  return {
    ...product,
    score: score.violatedPreferences
      ? score.totalScore + scoreAddition
      : score.totalScore + scoreAddition + crossGrainScore,
    scoreObj: score,
    // Check whether product contain "excluded" categories.
    category: analyzeProductForPreference(
      product.flags,
      parseProfiles(profiles)
    ),
  };
};
