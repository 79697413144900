import { isNil, negate } from 'lodash';

const isNotNil = negate(isNil);

/**
 * Use most occuring categories for prefetching in general
 * page
 *
 * @param {import('../types').ProductGroup[]} productGroups
 */
const getMostFrequentlyOccuringCategories = (productGroups) => {
  const analyzedProducts = productGroups.flatMap(
    ({ analyzedProduct }) => analyzedProduct
  );

  // Get all categories from detected products
  const listOfCategories = analyzedProducts
    .flatMap(({ categories }) => categories)
    .filter(isNotNil);

  // Count how many times categories occur
  const occurrencesOfCategories = listOfCategories.reduce((acc, curr) => {
    // eslint-disable-next-line no-prototype-builtins
    if (acc.hasOwnProperty(curr)) {
      acc[curr] += 1;
      return acc;
    }

    acc[curr] = 1;
    return acc;
  }, {});

  // Sort and get the 1st rank
  const sortedOccurencesOfCategories = Object.entries(occurrencesOfCategories)
    .sort(([, a], [, b]) => b - a)
    .map(([key]) => key);

  // return in array format
  return sortedOccurencesOfCategories.slice(0, 1);
};

export default getMostFrequentlyOccuringCategories;
