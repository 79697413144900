import { Flex } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { SubMenuNavigation } from 'components/navbar/components';
import { actions } from 'slices/app.slice';
import SubscriptionStarter from './SubscriptionStarter';
import { parentPostMessage } from 'utils/postmessage-module/postMessage';

/**
 * @typedef ReduxVar
 * @property {boolean} isShowOverlaySubscription
 * @property {import('utils/subscription/subscription-module').PremiumPlan} premiumPlans
 */

const OverlaySubscription = () => {
  const dispatch = useDispatch();

  /** @type {ReduxVar} */
  const { isShowOverlaySubscription, premiumPlans } = useSelector(
    (state) => state.app
  );

  const handleStartSubscription = () => {
    if (premiumPlans?.monthly === null) {
      return;
    }
    if (premiumPlans?.yearly == null) {
      parentPostMessage({
        channel: 'REQUEST_PURCHASE',
        product_id: premiumPlans?.monthly?.id,
      });
    } else {
      parentPostMessage({
        channel: 'REQUEST_PURCHASE',
        product_id: premiumPlans?.yearly?.id,
      });
    }
  };

  // to disable the scrollable page when the overlay is up
  useEffect(() => {
    if (isShowOverlaySubscription) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    // Cleanup function
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isShowOverlaySubscription]);

  return (
    <Flex
      pos="fixed"
      left={0}
      right={0}
      w="full"
      h="100vh"
      zIndex={99}
      backgroundColor="white"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
    >
      <SubMenuNavigation
        onClick={() => dispatch(actions.setIsShowOverlaySubscription(false))}
        withPattern={false}
        withBackButton={false}
        withNextButton={false}
        withCloseButton={true}
        onClose={() => dispatch(actions.setIsShowOverlaySubscription(false))}
        closeButtonColor={'white'}
        closeButtonZIndex={'unset'}
      />

      <SubscriptionStarter onClick={handleStartSubscription} />
    </Flex>
  );
};

export default OverlaySubscription;
