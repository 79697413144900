import axios from 'axios';
import { goalAllergens } from 'constants';
import { goalTypes } from 'constants';
import { isArray, isEmpty } from 'lodash';
import config from 'utils/config';

/**
 * @typedef {Object} UserGoals
 * @property {string} goalTitle
 * @property {string} goalType
 * @property {string} questionType
 * @property {string} question
 * @property {string | string[]} value
 * @property {string | number} minValue
 * @property {string | number} maxValue
 */

/**
 * @typedef {Object} PersonalityTraits
 * @property {string} motivators
 * @property {string} openness
 * @property {string} control
 * @property {string} socialFunction
 */

/**
 * @typedef {Object} UserPersonality
 * @property {string} type
 * @property {PersonalityTraits} traits
 */

/**
 * to generate JWT using public key
 * @returns {{
 *  message: string,
 *  token: string,
 * }}
 */
export const generateJWT = async () => {
  if (!process.env.REACT_APP_JWT_PUBLIC_KEY) {
    return false;
  }

  const payload = { apiKey: process.env.REACT_APP_JWT_PUBLIC_KEY };
  const res = await axios.post(
    config.endpoint.mobileAPI.concat('auth/generate-key'),
    payload
  );

  return res.data;
};

/**
 * to get personality by jwt & username
 * @param {string} token
 * @param {string} username
 * @returns {UserPersonality}
 */
export const getPersonalityByUsername = async ({ token, username }) => {
  if (!token || !username) {
    return false;
  }

  const headers = { Authorization: `Bearer ${token}` };
  const res = await axios.get(
    config.endpoint.mobileAPI.concat(
      `users/questionnaire?username=${username}`
    ),
    { headers }
  );

  return res.data.personality;
};

/**
 * check if user has take quiz
 * @param {boolean} loggedIn
 * @param {string} loginType
 * @param {any} me
 * @returns {boolean}
 */
export function checkUserFilledQuiz(loggedIn, loginType, me) {
  if (!process.env.REACT_APP_RUN_ON_FLUTTER) {
    return false;
  }

  if (!loggedIn) {
    return false;
  }

  if (loginType === 'anonymous') {
    return false;
  }

  if (isEmpty(me)) {
    return false;
  }

  if (isEmpty(me.personality)) {
    return false;
  }

  return true;
}

/**
 * check if user has take goals
 * @param {boolean} loggedIn
 * @param {boolean} loginType
 * @param {any} me
 * @returns {boolean}
 */
export function checkUserFilledGoals(loggedIn, loginType, me) {
  if (!process.env.REACT_APP_RUN_ON_FLUTTER) {
    return false;
  }

  if (!loggedIn) {
    return false;
  }

  if (loginType === 'anonymous') {
    return false;
  }

  if (isEmpty(me)) {
    return false;
  }

  if (isEmpty(me.goals)) {
    return false;
  }

  return true;
}

/**
 * check user need to start quiz
 * @param {boolean} loggedIn
 * @param {string} loginType
 * @param {any} me
 * @returns {boolean}
 */
export function checkUserNeedStartQuiz(loggedIn, loginType, me) {
  if (!process.env.REACT_APP_RUN_ON_FLUTTER) {
    return false;
  }

  if (!loggedIn) {
    return false;
  }

  if (loginType === 'anonymous') {
    return false;
  }

  if (isEmpty(me)) {
    return false;
  }

  if (!isEmpty(me.personality)) {
    // TODO: enable this checker right now questionnaire site sometime not sent personality type
    // if (me.personality.type === undefined || isEmpty(me.personality.type)){
    //   return true;
    // }

    if (me.personality.traits === undefined || isEmpty(me.personality.traits)) {
      return true;
    }

    return false;
  }

  return true;
}

/**
 * check user need to start goals
 * @param {boolean} loggedIn
 * @param {string} loginType
 * @param {any} me
 * @returns {boolean}
 */
export function checkUserNeedStartGoals(loggedIn, loginType, me) {
  if (!process.env.REACT_APP_RUN_ON_FLUTTER) {
    return false;
  }

  if (!loggedIn) {
    return false;
  }

  if (loginType === 'anonymous') {
    return false;
  }

  if (isEmpty(me)) {
    return false;
  }

  if (!isEmpty(me.goals)) {
    return false;
  }

  return true;
}

/**
 * compile embrace plant diets
 * @type {UserGoals[]}
 * @returns {null | string[]}
 */
export function getCompiledPlantDietsGoals(goals) {
  try {
    const goal = goals.find(
      (g) =>
        g.goalType === goalTypes.embrace_plant_based_diets &&
        g.questionType === 'MULTIPLE'
    );
    const plantBaseDietsGoal = isArray(goal?.value) ? goal?.value : [];

    if (plantBaseDietsGoal.some((pbd) => pbd.toLowerCase().includes('vegan'))) {
      return ['vegan'];
    }

    if (
      plantBaseDietsGoal.some((pbd) =>
        pbd.toLowerCase().includes('vegetarian')
      ) ||
      plantBaseDietsGoal.some((pbd) =>
        pbd.toLowerCase().includes('flexitarian')
      )
    ) {
      return ['vegetarian'];
    }

    if (
      plantBaseDietsGoal.some((pbd) =>
        pbd.toLowerCase().includes('pescatarian')
      )
    ) {
      return ['mammals_free', 'poultry_free', 'dairy_free', 'egg_free'];
    }

    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
}

/**
 * compile allergens goals
 * @type {UserGoals[]}
 * @returns {null | string[]}
 */
export function getCompiledAllergensGoals(goals) {
  try {
    const allergy = goalAllergens.find((ga) =>
      goals.some((g) =>
        g.value.toString().toLowerCase().includes(ga.toLowerCase())
      )
    );

    if (allergy === 'gluten') {
      return ['gluten_free'];
    }

    if (allergy === 'dairy') {
      return ['dairy_free'];
    }

    if (allergy === 'nut') {
      return ['peanut_free'];
    }

    if (allergy === 'shellfish') {
      return ['shellfish_free'];
    }

    if (allergy === 'eggs') {
      return ['egg_free'];
    }

    if (allergy === 'soys') {
      return ['soya_free'];
    }

    // get other value
    const goalOtherValue = goals.find(
      (g) =>
        g.goalType === goalTypes.avoid_allergens &&
        g.question.includes('Which allergens')
    );

    return [goalOtherValue.value?.toString().toLowerCase() ?? ''];
  } catch (error) {
    console.error(error);
    return null;
  }
}
