import { availableReferrals } from "constants";

/**
 * check if me object contain valid referall
 * @param {any} me 
 * @returns {boolean}
 */
export function checkCUKReferralOnMeObject(me){

    if (process.env.REACT_APP_RUN_ON_FLUTTER){
        return false;
    }

    if (!me){
        return false;
    }
    if (Object.keys(me).length === 0){
        return false;
    }

    if (!me.referral){
        return;
    }

    if (Object.keys(me.referral) === 0){
        return false;
    }
    return availableReferrals.findIndex((ref) => ref.code === me.referral?.code) !== -1;
}