import { Global } from '@emotion/react';

const FontsLoader = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'TT Interphases';
        font-weight: 900;
        font-display: swap;
        src: url('./fonts/TT Interphases Black.ttf') format('ttf');
      }
      @font-face {
        font-family: 'TT Interphases';
        font-weight: 800;
        font-display: swap;
        src: url('./fonts/TT Interphases ExtraBold.ttf') format('ttf');
      }
      @font-face {
        font-family: 'TT Interphases';
        font-weight: bold;
        font-display: swap;
        src: url('./fonts/TT Interphases Bold.ttf') format('ttf');
      }
      /* Heading font, uses 600 weight which is semibold variant */
      @font-face {
        font-family: 'TT Interphases';
        font-weight: 600;
        font-display: swap;
        src: url('./fonts/TT Interphases DemiBold.ttf') format('ttf');
      }
      /* Other variant of TT Interphases */
      @font-face {
        font-family: 'TT Interphases';
        font-display: swap;
        src: url('./fonts/TT Interphases Regular.ttf') format('ttf');
      }
      @font-face {
        font-family: 'TT Interphases';
        font-weight: lighter;
        font-display: swap;
        src: url('./fonts/TT Interphases Light.ttf') format('ttf');
      }
      @font-face {
        font-family: 'TT Interphases';
        font-style: italic;
        font-display: swap;
        src: url('./fonts/TT Interphases Italic.ttf') format('ttf');
      }
      `}
  />
);

export default FontsLoader;
