import { createRoot } from 'react-dom/client';

import App from 'app';

import 'utils/i18next';
import './app.scss';
import 'material-symbols';

import reportWebVitals from './reportWebVitals';
import { windowGlobalVar } from 'utils/window-var';

const container = document.getElementById('root');
const root = createRoot(container);

window[windowGlobalVar.FOODLAMA_WEB_APP_BUILD_VERSION] = 32;

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
