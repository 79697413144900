import { lazy, useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import BackgroundService from '../components/background-service';
import Fallback from '../components/fallback';
import { foodFacts, sponsoredBrandBanners } from '../constants';
import { actions } from '../slices/app.slice';
import { path } from '../utils/const';
import mulberry32 from '../utils/mulberry';
import Auth from '../pages/auth/auth';
import Resizer from '../components/resizer';
import MyFoodPersonality from 'pages/public';
import StoreRedirector from 'pages/store-redirector/store-redirector';

const ExtensionRoute = lazy(() => import('./extension'));
const InShopRoute = lazy(() => import('./inshop'));
const OutShopRoute = lazy(() => import('./outshop'));

const Router = () => {
  const dispatch = useDispatch();

  const {
    checked: isChecked,
    loggedIn,
    loginType,
  } = useSelector((state) => state.app);

  useEffect(() => {
    dispatch(actions.authenticate());
  }, [dispatch]);

  useEffect(() => {
    // After login success, a food fact will be picked based on the current hour.
    // This ensure every user has the exact same food fact.

    // Create a seed for the random number generator with current date / one hour
    const currentHour = Math.floor(Date.now() / (3600 * 1000));
    const randomNumber = Math.floor(
      (mulberry32(currentHour) * 3600) % foodFacts.length
    );

    dispatch(actions.setRandomFoodFact(foodFacts[randomNumber]));
    dispatch(
      actions.setRandomSponsorBanner(
        sponsoredBrandBanners[
          Math.floor(Math.random() * sponsoredBrandBanners.length)
        ]
      )
    );
  }, [dispatch]);

  const rootRouteRenderer = () => {
    // Wait until the auth process is complete
    if (!isChecked) {
      return <Fallback />;
    }
    // User is authenticated, redirect to the dashboard page
    if (isChecked && loggedIn) {
      return <Redirect to={path.dashboard} />;
    }
    // User is unauthenticated, redirect to the welcome page
    return <Redirect to={path.welcome} />;
  };

  return (
    <BrowserRouter>
      <BackgroundService>
        <Resizer>
          <Suspense fallback={<Fallback />}>
            <Switch>
              {/* IMPORTANT: Don't change the route order */}
              <Route
                // Will match "/auth" and it's subpaths
                path={path.auth}
                children={({ location: { state } }) => {
                  /**
                   * When an anonymous user is signed-in
                   * to the app, we'll set the loginType value into empty
                   * string, thus it'll let us navigate back to the Login/
                   * Signup route.
                   *
                   * Additional note: This is just a "hacky" approach.
                   * Once one of us has a better approach, we could
                   * refactor this.
                   *
                   * Additional note: we use children as props here
                   * to have an access to state object
                   */
                  if (
                    (isChecked && !loggedIn) ||
                    (isChecked && loggedIn && loginType === 'anonymous') ||
                    // Because we already handle the redirect manually
                    state?.onboarding
                  ) {
                    return <Auth />;
                  }
                  return <Redirect to={path.root} />;
                }}
              />
              <Route
                // Will match "/extension" and it's subpaths
                path={path.extension}
              >
                <ExtensionRoute />
              </Route>
              <Route
                // Will match "/inshop" and it's subpaths
                path={path.inshop}
              >
                <InShopRoute />
              </Route>
              <Route
                // Will match only "/~/:username"
                path={path.toStore}
                strict={true}
                component={StoreRedirector}
              />
              <Route
                // Will match only "/~/:username"
                path={path.myFoodPersonality}
                strict={true}
                component={MyFoodPersonality}
              />
              <Route
                // Will match any path with the beginning of "/v0." and "/v1. and fluttter"
                path={[
                  path.versionZeroRoot,
                  path.versionOneRoot,
                  '/flutter*',
                  '/~*',
                ]}
                render={rootRouteRenderer}
              />
              <Route
                // Will match only "/username"
                path={path.outshop}
                strict={true}
              >
                <OutShopRoute />
              </Route>
              <Route
                // Will match "/" or ""
                path={path.root}
                render={rootRouteRenderer}
                exact={true}
              />
            </Switch>
          </Suspense>
        </Resizer>
      </BackgroundService>
    </BrowserRouter>
  );
};

export default Router;
