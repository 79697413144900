/**
 * TODO:
 * - Prevent content from overflow on desktop viewport.
 */

import { Flex, Box, Grid, Image, Spacer, Avatar, Heading } from '@chakra-ui/react';
import { Switch, Route } from 'react-router-dom';
import Scaffold from 'components/scaffold';
import { CloseButton } from 'components/navbar';
import SEO from 'components/SEO';
import { path } from '../../../utils/const';
import Login from '../login';
import Signup from '../signup';

import FodlamaOrangePattern from '../../../assets/images/patterns/foodlama-orange-pattern.svg';
import Logo from '../../../assets/images/foodlama-logo-long.svg';
import { useSelector } from 'react-redux';
import { ReactComponent as FodlamaMascot } from '../../../assets/icon/foodlama-mascot.svg';
import { Fragment } from 'react';
import Navbar from 'components/custom-header/custom-header';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { parentPostMessage } from 'utils/postmessage-module/postMessage';

const AuthPage = () => {
  
  const isOpenedInIframe = window.self !== window.top;

  const location = useLocation();

  const {
    refCookie,
  } = useSelector((state) => state.app);


  const minimizeSidebar = () => {
    parentPostMessage('MINIMIZE_SIDEBAR', '*');
  };

  /**
   * Auth Page APP Bar
   * @returns {JSX.Element}
   */
  const AuthAppBar = () => {
    // remove auth bar when on flutter version
    if (process.env.REACT_APP_RUN_ON_FLUTTER){
      return <Fragment/>
    }
    return (
      <Box
        backgroundColor={!refCookie.isSupported ? "#f7e79b" : "#FFFFFF"}
        width="full"
        position="sticky"
        top="0px"
      >
        {!refCookie.isSupported ?
          <Box
            backgroundImage={FodlamaOrangePattern}
            height="56"
            backgroundRepeat="no-repeat"
            backgroundPosition="center center"
            backgroundSize="cover"
          >
            <Flex alignItems="center" justifyContent="center">
              <Box pl={8} pt={3}>
                <Image
                  width={['40', '56', '64']}
                  src={Logo}
                  alt="foodlama-logo"
                  loading="lazy"
                />
              </Box>
              <Spacer />
              {isOpenedInIframe ? (
                <Box pr={4} pt={3}>
                  <CloseButton onCloseClick={minimizeSidebar} />
                </Box>
              ) : (
                <Box />
              )}
            </Flex>
          </Box>
          :
          <Navbar />
        }
      </Box>
    )
  }

  /**
   * Components for CUK user introduction
   * @returns {JSX.Element}
   */
  const CUKIntroduction = () => {
    return (
      <Box
        display={"flex"}
        flexDir={"column"}
        alignItems={"center"}
      >
        <Avatar
          padding="5"
          bg="lightGreen.500"
          borderWidth="medium"
          borderColor="darkCyan.500"
          width="52"
          height="52"
          icon={<FodlamaMascot />}
        />
        <Heading textAlign="center" mt={"25px"} color={"#008080"} maxW={"md"} mx={"auto"}>
          {location.pathname.includes("signup") ?
            t('auth-page/cuk-sign-up-introduction')
            :
            /**
             * temporary: render sign-up text introdution for referall user
             * even it's on sign-in page.
             */
            (refCookie.isSupported ? 
              t('auth-page/cuk-sign-up-introduction'):
              t('auth-page/cuk-sign-in-introduction')
            )
          }
        </Heading>
      </Box>
    )
  }

  return (
    <Fragment>
      <SEO title="FoodLama | Auth" />
      <Scaffold
        appBar={<AuthAppBar />}
        // remove padding when on mobile version
        padding={process.env.REACT_APP_RUN_ON_FLUTTER ? '0' : '8'}
      >
        {refCookie.isSupported && <CUKIntroduction />}
        {/* set height of container dynamic based on content */}
        <Grid maxHeight="100%" justifyContent="center">
          <Switch>
            <Route path={path.login}>
              <Login />
            </Route>
            <Route path={path.signup}>
              <Signup />
            </Route>
            {/* <Route path={path.resetPassword}><ResetPassword /></Route> */}
            {/* <Route><Redirect to={path.signup} /></Route> */}
          </Switch>
        </Grid>
      </Scaffold>
    </Fragment>
  );
};

export default AuthPage;
