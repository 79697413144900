import { Spinner, Flex } from '@chakra-ui/react';

const OverlayFallback = ({isUseAlpha = false}) => {
  
  const isOpenedInIframe = window.self !== window.top;

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      w="full"
      h={isOpenedInIframe ? `${window.innerHeight}px` : '100vh'}
      overflow="hidden"
      position={"fixed"}
      left={0}
      top={0}
      zIndex={999}
      backgroundColor={isUseAlpha ? "whiteAlpha.600" : "white"}
    >
      <Spinner
        thickness="7px"
        speed="0.65s"
        emptyColor="gray.200"
        color="teal.500"
        size="xl"
      />
    </Flex>
  );
};

export default OverlayFallback;
