const filterValidProducts = (products, highlightedProduct) => {
  return products.filter((product) => {
    /**
     * remove highlighted product and product that having missing property
     * (failing products from service) from recommendations
     */
    return (
      product.productId !== highlightedProduct && product.flags
      //   !analyzedProduct.categories?.some(
      //   (cat) =>
      //     typeof cat === 'string' &&
      //     recommendationExcludedCats.includes(cat.toLowerCase())
      // )
    );
  });
};

export default filterValidProducts;
