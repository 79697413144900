import { isEmpty } from 'lodash';
/* eslint-disable no-use-before-define */
/**
 * @description
 * Return 'conflict' category or return single category name when there's no conflict
 * between single category
 *
 * @param {import('../types').Flag[]} flags
 * @param {import('../types').PreferenceProfile[]} profiles
 * @returns {import('../types').Product['category']}
 */
const analyzeProductForPreference = (flags, profiles) => {
  if (isEmpty(profiles)) return 'unknown';

  const category = profiles
    .map(({ preferences, strictMode }) =>
      generateSingleCategory(flags, preferences, strictMode)
    )
    .reduce((previousCategory, currentCategory) => {
      if (currentCategory !== previousCategory) {
        return 'conflict';
      }
      return currentCategory;
    });

  return category;
};

/**
 * @param {import('../types').Flag[]} flags - product flags.
 * @param {import('../types').PreferenceProfile['preferences']} preferences
 * @param {boolean=} strictMode
 * @returns {import('../types').Product['category']}
 */
export const generateSingleCategory = (flags, preferences, strictMode) => {
  if (!flags || flags.length === 0) {
    return 'unknown';
  }

  const isUnwanted = filterProductFlags('unwanted').length > 0;
  const isUnknown = filterProductFlags('unknown').length > 0;

  if (isUnwanted) {
    return 'unwanted';
  }

  const isCrossedGrainCertified = flags.some((flag) => flag.name === 'crossedgrain_certified' && flag.value === 1);
  if (isCrossedGrainCertified && !process.env.REACT_APP_RUN_ON_FLUTTER) {
    return 'crossedgrain_certified';
  }

  if (!isUnwanted && !isUnknown) {
    return 'wanted';
  }

  return 'unknown';

  function filterProductFlags(by) {
    return flags.filter((flag) =>
      preferences.some((preference) => {
        // Skip check for non matching name beetwen user product preference and flag
        if (preference.name !== flag.name) {
          return false;
        }
        // [1, 2, 4] means [likely to be, unlikely to be, certainly is not] on order
        if (by === 'unwanted') {
          // If the strict mode value is undefined, the strict mode value will be inferred as true
          // On strict mode, the uncertain value (likely to be and unlikely to be) will be marked as unwanted
          if (strictMode === undefined || strictMode === true) {
            return flag.value === 1 || flag.value === 2 || flag.value === 4;
          }
          // Otherwise, only (certainly is not) will be marked as unwanted
          return flag.value === 4;
        }
        if (by === 'unknown') {
          return flag.value === 3;
        }
        return false;
      })
    );
  }
};

export default analyzeProductForPreference;
