import { premiumFeatureRules } from 'constants';
import { isEmpty, isNil } from 'lodash';
import moment from 'moment';
import { firestore } from 'utils/firebase';
import { Timestamp } from 'firebase/firestore';
import logEvent from 'utils/log-event';
import { analyticsEvents } from 'constants';

/**
 * @typedef QonProduct
 * @property {string} id
 * @property {string} priceTag
 * @property {number} realNumber
 * @property {string} currencyCode
 */

/**
 * @typedef PremiumPlan
 * @property {QonProduct} monthly
 * @property {QonProduct} yearly
 */

/**
 * @typedef SubscriptionDetail
 * @property {string} productId
 * @property {string} id
 * @property {boolean} isActive
 * @property {string} startedDate
 * @property {string} expirationDate
 * @property {string | null} trialStartDate
 * @property {string} priceTag
 */

export const START_TRIAL_FIREBASE_KEY = 'startTrialAt';
export const SUBSCRIPTION_FEATURE_FLAG = 'SUBSCRIPTION';

/**
 * check if user trial period has ended
 * @param {any} me
 * @returns {boolean}
 */
export function checkTrialEnded(me) {
  if (!process.env.REACT_APP_RUN_ON_FLUTTER) {
    return false;
  }

  if (isEmpty(me)) {
    return false;
  }

  if (isNil(me[START_TRIAL_FIREBASE_KEY])) {
    return false;
  }

  const startTrialDate = moment(me[START_TRIAL_FIREBASE_KEY].toDate());
  const dateAfterTrial = startTrialDate.add(
    premiumFeatureRules.TRIAL_DAYS_LIMIT,
    'days'
  );
  const isTrialEndedNow = moment().isSameOrAfter(dateAfterTrial);
  if (!isTrialEndedNow) {
    return false;
  }

  return true;
}

/**
 * Update start trial at on firebase
 * @param {{
 *  uid: string
 * }} param
 */
export const updateStartTrialAt = async ({ uid }) => {
  try {
    const userRef = firestore.collection('users').doc(uid);
    await userRef.update({
      [START_TRIAL_FIREBASE_KEY]: Timestamp.now(),
    });
  } catch (error) {
    console.log(error);
  }
};

/**
 * record purchase event
 * @param {{
 *  productId: string,
 *  status: string
 * }} param
 */
export const recordPurchaseEvent = ({ productId, status }) => {
  try {
    logEvent(analyticsEvents.makePurchase, {
      productId: productId,
      status: status,
    });
  } catch (error) {
    console.log(error);
  }
};
