import { buttonTheme } from './button';


const floatingStyle = {
  container: {
    borderRadius: '100px',
    position: 'relative',
    bg: '#FFFFFF',
    h: '56px',
    border: '1px solid #A4A6A5',
    _focusWithin: {
      borderColor: 'teal.500',
      label: {
        transform: 'scale(0.75) translateY(-12px)',
      },
      input: {
        transform: 'translateY(5px)',
      }
    },
    'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
    {
      transform: 'scale(0.75) translateY(-12px)',
    },
    'input:not(:placeholder-shown)':{
      transform: 'translateY(5px)',
    },
    'input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active': {
      transition: "background-color 5000s ease-in-out 0s",
      backgroundColor: "transparent",
    },
    input: {
      bg: 'rgba(0,0,0,0)',
      _focus: {
        border: 'none',
        outline: '0',
        boxShadow: 'none',
      },
      border: 'none',
      outline: '0',
      pos: 'absolute',
      top: '16px',
      px: '12px',
      fontSize: '16px',
      lineHeight: '24px',
      height: 'fit-content',
      _placeholder: {
        color: 'rgba(0,0,0,0)',
      },
      transformOrigin: 'left top',
      transition: "all 0.3s"
    },
    label: {
      top: '16px',
      left: '12px',
      zIndex: 2,
      position: 'absolute',
      transformOrigin: 'left top',
      fontSize: '16px',
      color: '#999CA0',
      transition: "all 0.3s"
    },
  }
}


const components = {
  Button: buttonTheme,
  Input: {
    defaultProps: {
      focusBorderColor: 'teal.500',
      errorBorderColor: 'orange.500',
    },
  },
  Form: {
    variants: {
      floating: floatingStyle,
    },
  },
};

export default components;
