const getDetailedProductIfAny = (productGroup, idx, arr) => {
  const { analyzedProduct } = productGroup;

  const hasDetailedVersion = arr.some(
    (_pref) =>
      _pref.analyzedProduct.productId === analyzedProduct.productId &&
      _pref.analyzedProduct.isDetailed
  );

  if (hasDetailedVersion) {
    return analyzedProduct.isDetailed;
  }

  return true;
};

export default getDetailedProductIfAny;
