const getFallbackProductUrl = (retailerName, productId) => {
  if (retailerName === 'asda') {
    return `https://groceries.asda.com/product/${productId}`;
  }

  if (retailerName === 'ocado') {
    return `https://www.ocado.com/products/${productId}`;
  }

  if (retailerName === 'tesco') {
    return `https://www.tesco.com/groceries/en-GB/products/${productId}`;
  }

  return '';
};

export default getFallbackProductUrl;
