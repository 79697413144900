import { useState, useEffect } from 'react';
import { useInterval } from './use-interval';

/**
 *
 * @param {number} completeTime - Date in miliseconds.
 * @returns
 */
export function useCountdown(initCompleteTime) {
  // Time when the countdown started.
  const [completeTime, setCompleteTime] = useState(
    typeof initCompleteTime === 'number' ? initCompleteTime : Date.now()
  );
  const [remainingTime, setRemainingTime] = useState(0);

  useEffect(() => {
    setRemainingTime(completeTime - Date.now());
  }, [completeTime]);

  useInterval(
    () => {
      setRemainingTime(completeTime - Date.now());
    },
    // Shutdown the interval once the remainingTime reach 0
    remainingTime > 0 ? 1000 : null
  );

  return {
    initCountdown: (val) => {
      // refresh init time
      setCompleteTime(val);
    },
    remainingTime,
  };
}
