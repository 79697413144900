import { isEmpty } from 'lodash';
// Inspired by: https://www.tutorialspoint.com/javascript-function-that-generates-all-possible-combinations-of-a-string

// TODO: Optimization, provide option to generate only certain
// number of combinations.

export const generateAllCombinations = (arr = []) => {
  // Limit number of combinations to be generated. Otherwise,
  // there'd be a memory issue.
  arr = arr.slice(0, 7);
  const combination = [];

  let temp = [];

  let len = Math.pow(2, arr.length);

  for (let i = 0; i < len; i++) {
    temp = [];
    for (let j = 0; j < arr.length; j++) {
      if (i & Math.pow(2, j)) {
        temp.push(arr[j]);
      }
    }
    if (!isEmpty(temp)) {
      combination.push(temp);
    }
  }

  return combination;
};
