import {
  Text,
  Box,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Image,
  Spinner,
  Grid,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'slices/app.slice';
import { getUserName } from 'utils/misc';
import { useEffect, useRef, useState } from 'react';
import ModalConfirm from 'components/modal/ModalConfirm';
import SelectImage from 'components/select/SelectImage';
import {
  avatarSeeds,
  dicebarDefaultStyle,
  dicebarListStyle,
} from 'utils/dicebar-avatar-module/dicebar-avatar-seeds';
import {
  AVATAR_URL_FIREBASE_KEY,
  generateAvatar,
  updateAvatarURL,
} from 'utils/dicebar-avatar-module/dicebar-avatar-module';
import icon404ImageNotFound from '../../assets/images/404-image-not-found.svg';
import TabsPills from 'components/tabs-pills/TabsPills';
import { isNull } from 'lodash';

const AvatarCard = ({
  imageUrl,
  w = 36,
  h = 36,
  hoverShadow = null,
  bg = 'transparent',
  rounded = 'none',
  onClick = () => {},
}) => {
  return (
    <Box
      bg={bg}
      w={w}
      h={h}
      mx="auto"
      rounded={rounded}
      onClick={onClick}
      _hover={{
        boxShadow: hoverShadow,
      }}
    >
      <Image
        objectPosition="center"
        objectFit="contain"
        w="full"
        h="full"
        minH={'80px'}
        src={imageUrl}
        fallbackSrc={icon404ImageNotFound}
        alt="avatar-preview"
        rounded={rounded}
        fallback={
          imageUrl && (
            <Flex
              minH={'80px'}
              w="full"
              h="full"
              justifyContent="center"
              alignItems="center"
              bg="#ddd"
            >
              <Spinner
                thickness="7px"
                speed="0.65s"
                emptyColor="gray.200"
                color="teal.500"
                size="xl"
              />
            </Flex>
          )
        }
      />
    </Box>
  );
};

const ModalAvatarForm = () => {
  const dispatch = useDispatch();

  const { me, isOpenModalAvatarForm } = useSelector((state) => state.app);

  const avatarURL = me[AVATAR_URL_FIREBASE_KEY];

  /**
   * @type {import('react').LegacyRef<HTMLDivElement>}
   */
  const refTopSection = useRef(null);
  /**
   * @type {import('react').LegacyRef<HTMLDivElement>}
   */
  const refOuterSection = useRef(null);

  const avatarOptions = Object.keys(dicebarListStyle).map((key) => ({
    value: key,
    label: dicebarListStyle[key].name,
    image: dicebarListStyle[key].svgURL,
  }));

  const avatarDefaultValue = avatarURL
    ? avatarOptions.find((v) => avatarURL.split('?')[0].includes(v.image))
    : avatarOptions.find((v) => v.value === dicebarDefaultStyle);

  const [avatarPreviewStyle, setAvatarPreviewStyle] = useState(
    avatarDefaultValue.value
  );
  const [avatarPreviewSeed, setAvatarPreviewSeed] = useState(getUserName(me));
  const [avatarPreviewUrl, setAvatarPreviewUrl] = useState(
    me[AVATAR_URL_FIREBASE_KEY]
  );
  const [avatarSelectionsHeight, setAvatarSelectionsHeight] = useState('400px');

  const handleAvatarStyleSelect = (selectedStyle) => {
    setAvatarPreviewStyle(selectedStyle.value);
    handleGenerateAvatarPreview(selectedStyle.value);
  };

  const handleGenerateAvatarPreview = (newStyle, seed) => {
    const styleToUse = newStyle || avatarPreviewStyle;
    const seedToUse = seed || avatarPreviewSeed;
    setAvatarPreviewUrl(
      generateAvatar({
        styleName: styleToUse,
        seedName: seedToUse,
      })
    );
  };

  const handleUpdateAvatar = async () => {
    dispatch(actions.setIsOpenModalAvatarForm(false));
    // add loader screen
    dispatch(actions.setIsShowOverlayLoader(true));

    // update avatar
    await updateAvatarURL({
      avatarURL: avatarPreviewUrl,
      uid: me.id,
    });

    // update me object after user change avatar
    const updatedMeObject = await actions.getMeObject(me.id);
    if (!updatedMeObject.id) {
      dispatch(actions.setIsShowOverlayLoader(false));
      return;
    }

    // update me object after re-fetch user data from fire store
    let userData = updatedMeObject.data();
    userData[AVATAR_URL_FIREBASE_KEY] = avatarPreviewUrl;
    dispatch(
      actions.setMe({
        me: {
          id: updatedMeObject.id,
          emailVerified: me.emailVerified,
          ...userData,
        },
      })
    );

    // remove loader screen
    dispatch(actions.setIsShowOverlayLoader(false));
  };

  useEffect(() => {
    const meAvatar = me[AVATAR_URL_FIREBASE_KEY];
    if (!isNull(meAvatar) && typeof meAvatar === 'string') {
      const style = meAvatar.match(/\/7\.x\/(.*?)\/svg/)?.[1];
      setAvatarPreviewStyle(style);
      setAvatarPreviewUrl(meAvatar);
    }
  }, [me]);

  useEffect(() => {
    setTimeout(() => {
      if (
        isOpenModalAvatarForm &&
        refOuterSection.current !== null &&
        refTopSection.current !== null
      ) {
        const tabPillsHeight = 90;
        const avatarGridHeight =
          refOuterSection.current.clientHeight -
          refTopSection.current.clientHeight -
          tabPillsHeight;
        setAvatarSelectionsHeight(`${avatarGridHeight}px`);
      }
    }, 100);
  }, [isOpenModalAvatarForm, refOuterSection, refTopSection]);

  return (
    <ModalConfirm
      isOpen={isOpenModalAvatarForm}
      onSubmit={handleUpdateAvatar}
      onClose={() => dispatch(actions.setIsOpenModalAvatarForm(false))}
      isCentered={false}
      modalTitleSize="2xl"
      cancelBtnText={t('cancel')}
      confirmBtnText={t('profile.generate-avatar.save-changes')}
      isFullScreen={true}
      textAlign="center"
    >
      <Box h={'calc(100vh - 125px)'} overflow={'hidden'} ref={refOuterSection}>
        <Flex flexDir="column" gap="6" mb={'16px'} ref={refTopSection}>
          <Box textAlign="center">
            <Text fontSize={{ base: '3xl', md: '4xl' }} fontWeight="600">
              {t('profile.generate-avatar.title')}
            </Text>
            <Text fontSize={{ base: '2xl', md: '3xl' }}>
              {t('profile.generate-avatar.body')}
            </Text>
          </Box>

          {avatarPreviewUrl && (
            <AvatarCard
              rounded="full"
              w="44"
              h="44"
              imageUrl={avatarPreviewUrl}
              bg="repeating-conic-gradient(#00000005 0% 25%,#00000012 0% 50%) 50% / 12px 12px"
            />
          )}

          <FormControl id="avatar-style" mb="4">
            <FormLabel
              fontSize={{ base: '2xl', md: '3xl' }}
              htmlFor="avatar-style"
            >
              {t('profile.generate-avatar.style')}
            </FormLabel>
            <SelectImage
              options={avatarOptions}
              defaultValue={avatarDefaultValue}
              placeholder={`-- Select ${t('profile.generate-avatar.style')}--`}
              onChange={handleAvatarStyleSelect}
            />
          </FormControl>
        </Flex>

        <TabsPills
          navGap={'5px'}
          content={[
            {
              tabTitle: t('profile.generate-avatar.tab-template.title'),
              tabPanelPadding: 0,
              tabPanel: (
                <Grid
                  gridTemplateColumns="auto auto auto auto"
                  gap={2}
                  p={'5px'}
                  maxH={avatarSelectionsHeight}
                  overflowY={'auto'}
                >
                  {avatarSeeds.map((v, i) => {
                    return (
                      <AvatarCard
                        bg="transparent"
                        key={`avatar-template-${i}`}
                        w="full"
                        h="full"
                        imageUrl={`${
                          avatarOptions.find(
                            (opt) => opt.value === avatarPreviewStyle
                          ).image
                        }?seed=${v}`}
                        onClick={() => {
                          setAvatarPreviewSeed(v);
                          handleGenerateAvatarPreview(avatarPreviewStyle, v);
                        }}
                        rounded="8px"
                        hoverShadow="0 0 8px #045050"
                      />
                    );
                  })}
                </Grid>
              ),
            },
            {
              tabTitle: t('profile.generate-avatar.tab-custom.title'),
              tabPanelPadding: 0,
              tabPanel: (
                <FormControl id="avatar-seeds" variant="floating">
                  <Input
                    placeholder={t('profile.generate-avatar.you-can-use')}
                    defaultValue={getUserName(me)}
                    onChange={(e) => {
                      setAvatarPreviewSeed(e.target.value);
                      handleGenerateAvatarPreview(
                        avatarPreviewStyle,
                        e.target.value
                      );
                    }}
                  />
                  <FormLabel
                    fontSize={{ base: '2xl', md: '3xl' }}
                    htmlFor="avatar-seeds"
                  >
                    {t('profile.generate-avatar.seeds')}
                  </FormLabel>
                </FormControl>
              ),
            },
          ]}
        />
      </Box>
    </ModalConfirm>
  );
};

export default ModalAvatarForm;
