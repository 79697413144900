import { isPlainObject, isArray, snakeCase } from 'lodash';

const convertObjectKeysFromCamelCaseToSnakeCase = (object) => {
  if (isPlainObject(object)) {
    const n = {};
    Object.keys(object).forEach(
      (k) =>
        (n[snakeCase(k)] = convertObjectKeysFromCamelCaseToSnakeCase(object[k]))
    );
    return n;
  }
  if (isArray(object))
    object.map((i) => convertObjectKeysFromCamelCaseToSnakeCase(i));
  return object;
};

export { convertObjectKeysFromCamelCaseToSnakeCase };
