import axios from 'axios';
import { uniqueId } from 'lodash';
import { Timestamp } from 'firebase/firestore';
import { logEvent as analyticsLogEvent } from 'firebase/analytics';

import config from './config';
import { analytics, auth, firestore } from './firebase';
import checkEventSanity from './event-sanity-check';
import { convertObjectKeysFromCamelCaseToSnakeCase } from './convert-object-keys-case';
import { isIOS } from 'react-device-detect';

/**
 * Wrapper fo logging an event to Firebase Analytics
 * but determine wether the log happen directly or with extension.
 *
 * @param {{name: string, type: 'active' | 'interactive'}} event
 * @param {Object<string, any>} params
 */
const logEvent = async (event, params) => {
  // console.log(event, params, 'fld: log event result');

  const { currentUser } = auth;

  if (!currentUser) {
    return;
  }

  const { uid, email } = currentUser;

  const environment = process.env.NODE_ENV;
  const mainAppVersion = `v${process.env.REACT_APP_VERSION}`;

  const isOpenedInIframe = window.self !== window.top;

  const convertedParams = convertObjectKeysFromCamelCaseToSnakeCase(params);

  if (isOpenedInIframe) {
    const token = await currentUser.getIdToken();
    if (!token) return;

    const events = [
      {
        name: event.name,
        params: {
          ...convertedParams,
          environment,
          source: 'extension',
          type: event.type,
          device_platform: window.flutter_inappwebview
            ? isIOS
              ? 'IOS'
              : 'ANDROID'
            : 'BROWSER',
        },
      },
    ];

    await axios.post(
      `${config.endpoint.analytics}`,
      {
        client_id: uniqueId(`${uid}_`),
        events,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } else {
    analyticsLogEvent(analytics, event.name, {
      ...convertedParams,
      environment,
      source: 'standalone',
      type: event.type,
      device_platform: window.flutter_inappwebview
        ? isIOS
          ? 'IOS'
          : 'ANDROID'
        : 'BROWSER',
    });
  }

  const timestamp = Timestamp.now();

  const sanityCheckResult = checkEventSanity(event, params);

  // console.log(sanityCheckResult, 'sanity check result');

  if (currentUser === null) {
    return;
  }

  await firestore
    .collection('shopping_history')
    .doc(email ?? uid)
    .collection('url_history')
    .doc()
    .set({
      ...convertedParams,
      event_name: event.name,
      event_type: event.type,
      // browser_agent: window.navigator.userAgent,
      main_app_version: mainAppVersion,
      user_uid: uid,
      user_email: email,
      environment,
      device_platform: window.flutter_inappwebview
        ? isIOS
          ? 'IOS'
          : 'ANDROID'
        : 'BROWSER',
      timestamp,
    });

  if (sanityCheckResult.state === 'fail') {
    await firestore
      .collection('error_log')
      .doc()
      .set({
        ...convertedParams,
        event_name: event.name,
        event_type: event.type,
        // browser_agent: window.navigator.userAgent,
        main_app_version: mainAppVersion,
        user_uid: uid,
        user_email: email,
        sanity_check_error: sanityCheckResult.error,
        environment,
        device_platform: window.flutter_inappwebview
          ? isIOS
            ? 'IOS'
            : 'ANDROID'
          : 'BROWSER',
        timestamp,
      });
  }
};

export default logEvent;
