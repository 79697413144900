// TODO: Split page messages to separate files.

import customErrors from './custom-error-message.json';
import firebaseErrors from './firebase-error-message.json';
import pageText from './page-text-message.json';
import genericText from './generic-text-message.json';
import mobileAppText from './mobile-app-text.json';
import componentText from './component-text-message.json';
import notificationText from './notification-text-message.json';
import pageMessages from './pages';

const messages = {
  ...customErrors,
  ...firebaseErrors,
  ...pageText,
  ...genericText,
  ...componentText,
  ...notificationText,
  ...pageMessages,
  ...mobileAppText,
};

export default messages;
