import axios from 'axios';
import config from './config';
import getToken from './get-token';
import { supportedRetailers, analyticsEvents } from 'constants';
import logEvent from './log-event';
import { getExistingScanIDs } from './scan-history-module';
import { auth } from './firebase';
import { firestore } from 'utils/firebase';
import { isEmpty, isNil } from 'lodash';

export const SCAN_PRODUCT_COUNTER_FIREBASE_KEY = 'scanProductCounter';

const openProductAnalysisEmptyMessage = {
  productId: '000000',
  productName: '',
  productImageURL: '',
  preferenceCategories: [],
  retailerURL: '',
  channel: 'STICKER_CLICK',
};

/**
 * @param {any} dataToSave
 * @returns
 */
const logFirstScanID = async (dataToSave) => {
  try {
    const existingScanIDs = await getExistingScanIDs({
      emailOrUID: auth.currentUser.email ?? auth.currentUser.uid,
    });

    if (existingScanIDs.length === 0) {
      logEvent(analyticsEvents.firstScanProduct, dataToSave);
    }
  } catch (error) {
    console.log(error);
    return;
  }
};

/**
 * @param {any} me
 * @returns {number}
 */
export function getScanProductCounter(me) {
  if (!process.env.REACT_APP_RUN_ON_FLUTTER) {
    return 0;
  }

  if (isEmpty(me)) {
    return 0;
  }

  if (isNil(me[SCAN_PRODUCT_COUNTER_FIREBASE_KEY])) {
    return 0;
  }

  return me[SCAN_PRODUCT_COUNTER_FIREBASE_KEY];
}

/**
 * @param {{
 *  uid: string,
 *  totalScanned: number,
 * }} param
 */
export const updateScanProductCounter = async ({ uid, totalScanned = 0 }) => {
  try {
    const userRef = firestore.collection('users').doc(uid);
    await userRef.update({
      [SCAN_PRODUCT_COUNTER_FIREBASE_KEY]: totalScanned + 1,
    });
  } catch (error) {
    console.log(error);
  }
};

/**
 * open product analysisWithBarcode
 * @param {string | null} scanID
 * @param {string | null} barcode
 * @param {string | null} url
 * @param {boolean} isUsingBackButton
 * @param {string} backButtonTitle
 * @param {boolean} saveToGA
 * @param {boolean} showReScanButton
 */
export const openProductAnalysisWithBarcodeOrUrl = async (
  scanID = null,
  barcode = null,
  url = null,
  isUsingBackButton = false,
  backButtonTitle = 'Back',
  saveToGA = true,
  showReScanButton = false
) => {
  const messageToSent = { ...openProductAnalysisEmptyMessage };
  try {
    if (!barcode && !url) {
      throw new Error(
        'Invalid request! Please enter the product barcode or URL.'
      );
    }
    const identifier = barcode ? `barcode=${barcode}` : `url=${url}`;

    /**
     * @var {object} product
     */
    const product = await fetchProductByIdentifier(identifier);
    if (typeof product !== 'object') {
      throw new Error('Fetch product error');
    }

    messageToSent.productId = product.product.productId;
    messageToSent.preferenceCategories = [
      product.userPrefMatch.type.toLowerCase(),
    ];
    messageToSent.retailerURL =
      product.product.availableAt[product.product.retailer].url;

    if (saveToGA) {
      const dataToSave = {
        isFound: true,
        scanID: scanID,
        barcode: product.product.barcode,
        productID: messageToSent.productId,
        productName: product.product.name,
        productImageURL: product.product.imageUrl,
        productURL: messageToSent.retailerURL,
        foundAt: product.product.retailer,
        availableAt: Object.values(product.product.availableAt),
      };
      // check logged scan id
      await logFirstScanID(dataToSave);
      writeScanAnalytics(dataToSave);
    }
  } catch (error) {
    console.log(error);
    // random index 0 - 2 to get random retailer url
    const randomIndexRetailer = Math.floor(Math.random() * 3);
    messageToSent.productId = '000000000';
    messageToSent.preferenceCategories = ['unknown'];
    messageToSent.retailerURL =
      supportedRetailers[randomIndexRetailer].launchUrl;
    if (saveToGA) {
      const dataToSave = {
        isFound: false,
        scanID: scanID || 'SC000',
        barcode: barcode || '000000000',
        productID: '',
        productName: '',
        productImageURL: '',
        productURL: '',
        foundAt: '',
        availableAt: [],
      };
      // check logged scan id
      await logFirstScanID(dataToSave);
      writeScanAnalytics(dataToSave);
    }
  }

  // trigger post message to open analysis page
  if (isUsingBackButton) {
    messageToSent.isUsingBackButton = true;
    messageToSent.backButtonTitle =
      backButtonTitle === null ? 'Back' : backButtonTitle;
  }
  messageToSent.showReScanButton = showReScanButton;
  window.postMessage(messageToSent);
};

/**
 *
 * @param {{
 *  isFound: boolean,
 *  barcode: string,
 *  productID: string,
 *  productName: string,
 *  productImageURL: string,
 *  productURL: string,
 *  foundAt: string,
 *  availableAt: string[]
 * }} param
 */
export const writeScanAnalytics = ({
  isFound,
  scanID,
  barcode,
  productID,
  productName,
  productImageURL,
  productURL,
  foundAt,
  availableAt,
}) => {
  const dataToSave = {
    isFound,
    scanID,
    barcode,
    productID,
    productName,
    productImageURL,
    productURL,
    foundAt,
    availableAt,
  };
  try {
    logEvent(analyticsEvents.scanProduct, dataToSave);
  } catch (error) {
    console.log(error);
  }
};

/**
 * fetch product data by identifier
 * @param {string} identifier (/:barcode | /find?url=:url)
 */
export const fetchProductByIdentifier = async (identifier) => {
  const token = await getToken();
  const headers = { Authorization: `Bearer ${token}` };

  const productData = await axios.get(
    config.endpoint.mobileAPI.concat(`products/find?${identifier}`),
    {
      headers,
    }
  );

  return productData.data;
};

/**
 * @param {{
 *  productId: string,
 *  retailer: string,
 *  isForGoals: boolean
 * }} param
 */
export const fetchProductRecommendation = async ({
  productId,
  retailer,
  isForGoals,
}) => {
  const token = await getToken();
  const headers = { Authorization: `Bearer ${token}` };

  const productData = await axios.get(
    config.endpoint.mobileAPI.concat(
      isForGoals
        ? `products/goal-recommendation?retailer=${retailer}&productId=${productId}`
        : `products/recommendation?retailer=${retailer}&productId=${productId}`
    ),
    {
      headers,
    }
  );

  return productData.data;
};

/**
 * get product by id
 * @param {string} retailer
 * @param {string} productID
 */
export const getProductByID = async (retailer, productID) => {
  const token = await getToken();
  const headers = { Authorization: `Bearer ${token}` };

  const product = await axios.get(
    config.endpoint.mobileAPI.concat(`products/${retailer}/${productID}`),
    {
      headers,
    }
  );

  return product.data;
};
