function appendUrlPrefix(path) {
  // just add flutter as url prefix;
  // for flutter app;
  if (process.env.REACT_APP_RUN_ON_FLUTTER) {
    const versionPrefix = `/flutter`;
    return versionPrefix.concat(path);
  }

  // just add tilde (~) as url prefix;
  // for public url;
  if (process.env.REACT_APP_RUN_ON_PUBLIC) {
    const versionPrefix = `/~`;
    return versionPrefix.concat(path);
  }

  const versionPrefix = process.env.REACT_APP_VERSION
    ? `/v${process.env.REACT_APP_VERSION}`
    : '';
  return versionPrefix.concat(path);
}

export function appendPrefixToPaths(paths) {
  return (
    Object.entries(paths)
      .map(([k, v]) => ({
        // modify the value to array: ['originalUrl', '/:version/originalUrl']
        [k]: appendUrlPrefix(v),
      }))
      // Reduce to original form
      .reduce((acc, item) => {
        return {
          ...acc,
          ...item,
        };
      }, {})
  );
}
