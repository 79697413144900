import Logo from '../../../assets/images/foodlama-logo-long.svg';
import AuthBG from '../../../assets/images/auth-bg/auth-bg.png';
import GoogleIcon from '../../../assets/icon/google-circle.svg';
import { isEmpty } from 'lodash';
import React, { Fragment, useState } from 'react';
import { t } from 'i18next';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Heading,
  useToast,
  Image,
  Flex,
  Text,
} from '@chakra-ui/react';

import SEO from 'components/SEO';
import ErrorBox from 'components/error-box';

import { tests } from 'utils/validate';
import { path } from 'utils/const';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { parentPostMessage } from 'utils/postmessage-module/postMessage';
import { isFeatureOn } from 'utils/feature-flags/feature-flags-module';
import { useSelector } from 'react-redux';
import { isAndroid } from 'react-device-detect';

/**
 * @param {{
 *  isLoading: {
 *    email: boolean,
 *    anon: boolean,
 *    google: boolean,
 *    facebook: boolean
 *  },
 *  error: string,
 *  onLoginClick: () => void,
 *  onOpenResetPassword: () => void,
 *  formRegister: UseFormRegister<FieldValues>,
 *  getFormValue: UseFormGetValues<FieldValues>,
 *  focusSetter: UseFormSetFocus<FieldValues>
 * }} props
 * @returns Element
 */
export const MobileLoginScreen = ({
  isLoading,
  error,
  onLoginClick,
  onOpenResetPassword,
  formRegister,
  getFormValue,
  focusSetter,
}) => {
  const { me, featureFlags } = useSelector((state) => state.app);

  const toast = useToast();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [isAddPadding, setIsAddPadding] = useState(false);

  const history = useHistory();

  const showToast = (msg) => {
    toast({
      description: msg,
      status: 'warning',
      duration: 1000,
      position: 'top',
      isClosable: true,
    });
  };

  const handleOnSubmit = () => {
    if (!showPasswordInput) {
      if (isEmpty(getFormValue().email)) {
        showToast(t('authScreen.emailWarning'));
        focusSetter('email');
        return;
      }
      setShowPasswordInput(true);
      return;
    }

    if (isEmpty(getFormValue().password)) {
      showToast(t('authScreen.passwordWarning'));
      focusSetter('password');
      return;
    }

    onLoginClick();
  };

  const submitLoginWithGoogle = async () => {
    if (process.env.REACT_APP_RUN_ON_FLUTTER) {
      parentPostMessage('REQUEST_GOOGLE_LOGIN');
      return;
    }
  };

  return (
    <Fragment>
      <SEO title="FoodLama | Sign In" />
      <Box
        w={'100vw'}
        h={'100vh'}
        maxW={'430px'}
        pos={'absolute'}
        left={'0px'}
        right={'0px'}
        mx={'auto'}
      >
        <Box px={'16px'} pt={'30px'}>
          <Image h={'20px'} src={Logo} />
        </Box>
        <Box mt="9" overflow="hidden">
          <Image
            src={AuthBG}
            w="full"
            h="full"
            mt="-40"
            objectFit="cover"
            alt="auth-bg"
          />
        </Box>
        <Box
          bg={'#F2F5FA'}
          pos={'absolute'}
          left={'0px'}
          bottom={'0px'}
          w={'100vw'}
          maxW={'430px'}
          px={'16px'}
          pt={'24px'}
          pb={isAndroid && isAddPadding ? '150px' : '30px'}
          borderRadius={'40px 40px 0px 0px'}
          transition={'all 0.3s ease'}
        >
          <Heading textAlign={'center'} mb={'30px'}>
            {t('authScreen.loginTitle')}
          </Heading>

          {error && (
            <ErrorBox
              style={{
                marginBottom: '16px',
                marginTop: '20px',
                borderRadius: '100px',
                fontSize: '16px',
                paddingLeft: '16px',
                paddingRight: '16px',
                lineHeight: '1.2',
                backgroundColor: 'white',
              }}
            >
              {error}
            </ErrorBox>
          )}

          <FormControl id="email" variant={'floating'} mb={'16px'}>
            <Input
              type="email"
              placeholder={t('authScreen.emailPlaceholder')}
              onFocus={() => setIsAddPadding(true)}
              {...formRegister('email', {
                pattern: {
                  value: tests.email.test,
                  message: t(tests.email.error),
                },
                onBlur: () => {
                  setIsAddPadding(false);
                },
              })}
            />
            <FormLabel htmlFor="email">
              {t('authScreen.emailPlaceholder')}
            </FormLabel>
          </FormControl>

          <FormControl
            id="password"
            variant={'floating'}
            height={showPasswordInput ? '56px' : '0'}
            overflow={showPasswordInput ? undefined : 'hidden'}
            border={showPasswordInput ? undefined : 'none'}
            mb={showPasswordInput ? '16px' : undefined}
            transition={'all 0.3s'}
            transformOrigin={'top'}
          >
            <Input
              type={showPassword ? 'text' : 'password'}
              placeholder={t('authScreen.passwordPlaceholder')}
              onFocus={() => setIsAddPadding(true)}
              {...formRegister('password', {
                pattern: {
                  value: tests.password.test,
                  message: t(tests.password.error),
                },
                onBlur: () => {
                  setIsAddPadding(false);
                },
              })}
              w={'calc(100% - 55px)'}
            />
            <FormLabel htmlFor="password">
              {t('authScreen.passwordPlaceholder')}
            </FormLabel>

            <Flex
              pos={'absolute'}
              fontSize={'14px'}
              right={'16px'}
              top={'0px'}
              textDecor={'underline'}
              h={'100%'}
              alignItems={'center'}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? 'Hide' : 'Show'}
            </Flex>
          </FormControl>

          <Button
            variant={'mobileRounded'}
            w={'full'}
            onClick={handleOnSubmit}
            isLoading={isLoading.email}
            mb={'20px'}
          >
            {!showPasswordInput
              ? t('authScreen.continueLoginButton')
              : t('authScreen.loginButton')}
          </Button>

          {isFeatureOn({
            featureKey: 'MOBILE_GOOGLE_AUTH',
            me: me,
            featureFlags: featureFlags,
            noAuth: true,
          }) && (
            <Flex
              flexDirection="row"
              alignItems={'center'}
              justifyContent={'center'}
              mb={'10px'}
            >
              <Text
                fontSize={'16px'}
                textAlign={'center'}
                fontWeight={'semibold'}
                mr={'10px'}
              >
                {t('authScreen.or-signin-with')}
              </Text>

              <Box display={'flex'} justifyContent={'center'}>
                <Image
                  src={GoogleIcon}
                  cursor={'pointer'}
                  onClick={submitLoginWithGoogle}
                />
              </Box>
            </Flex>
          )}

          <Text fontSize={'16px'} textAlign={'center'}>
            {t('authScreen.signUpCta')}
          </Text>

          <Button
            variant={'mobileRoundedOutlined'}
            w={'full'}
            mt={'16px'}
            mb={'16px'}
            onClick={() => history.push(path.signup)}
          >
            {t('authScreen.createAnAccount')}
          </Button>

          <Box display={'flex'} justifyContent={'center'}>
            <Button
              variant="link"
              textDecoration="underline"
              onClick={onOpenResetPassword}
            >
              {t('button-component/forgot-password')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
