import axios from 'axios';
import getFallbackProductUrl from '../get-fallback-product-url';
import getToken from '../get-token';
import { supportedRetailers } from '../../constants';
import config from '../config';
import validateImageUrl from 'utils/validate-image-url';

/**
 * To fetch sponsored product data based on the
 * current url and the analyzed categories.
 *
 * @param string retailerCurrentUrl
 * @param array categories
 * @returns object
 */
const fetchSponsoredProductData = async (retailerCurrentUrl, categories) => {
  const retailers = supportedRetailers.filter(
    (v) => v.isSupported && retailerCurrentUrl?.includes(v.name)
  );

  // Won't send network request if required properties missing
  if (retailers.length === 0 || !categories) return {};

  try {
    const token = await getToken();
    const urlApi = `${
      process.env.NODE_ENV === 'development'
        ? '/api/v0.2/products'
        : config.endpoint.product
    }/sponsored?retailer=${retailers[0].name}&categories=${categories}`;

    const response = await axios.get(urlApi, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const res = response.data.data;
    if (res.productId === undefined) return {};

    // default image resize param for asda & tesco
    let imageUrl = res.imageUrl + retailers[0].imageResizeUrl;
    if (retailers[0].name === 'ocado') {
      imageUrl = res.imageUrl.replace(/\d+x\d+/, retailers[0].imageResizeUrl);
    }

    // validate image url
    const validImageUrl = await validateImageUrl(imageUrl);
    if (!validImageUrl) return {};

    return {
      ...res,
      imageUrl: imageUrl,
      url: res.url || getFallbackProductUrl(retailers[0].name, res.productId),
    };
  } catch (error) {
    // TODO: DELETE THIS CONSOLE ERROR IN THE FUTURE
    console.error('An error occurred:', error);
    // Return fetch data as empty array
    return {};
  }
};

export default fetchSponsoredProductData;
