import { isNil, negate, isEmpty, isArray } from 'lodash';
import { getProductUid } from '../product';

// Support old fieldname.
function getAlternativeFallback(alt) {
  const { originalUrl, productUrl, ...data } = alt;
  return {
    ...data,
    productUrl: productUrl ?? originalUrl,
  };
}

/**
 * Include all alternatives a recommendation is related to and mark
 * whether a recommendation document has been recommended.
 * A recommendation will be added to `recData`'s `alternatives` field if they have `recData` in their `alternativesTo`.
 *
 *
 * @param {object} recData - Target recommendation
 * @param {null} _ - placeholder so we can call this function without creating anonymous fuction. (`list.map(transformRecommendation)` compared to `list.map((item, idx, arr) => transformRecommendation(item, arr)))
 * @param {object[]} recommendations - Complete list of recommendation
 * @returns object
 */
export function transformRecommendation(recData, _, recommendations) {
  const alternatives =
    recommendations
      .map((rec) => {
        // Product that contain `recData` in its `alternativesTo` list.
        const product = rec?.alternativesTo?.find((alt) => {
          return getProductUid(alt) === getProductUid(recData);
        });

        if (isNil(product)) return null;

        const { originalUrl, productUrl, ...data } = rec;
        return {
          ...data,
          productUrl: productUrl ?? originalUrl,
          quantity: product.quantity,
        };
      })
      .filter(negate(isNil)) ?? [];

  const hasRecommended = ['tags', 'tagsNotRecommended', 'alternativesTo'].some(
    (key) => !isNil(recData[key]) || !isEmpty(recData[key])
  );

  return {
    ...recData,
    alternatives,
    hasRecommended,
    ...(isArray(recData.alternativesTo)
      ? { alternativesTo: recData.alternativesTo.map(getAlternativeFallback) }
      : {}),
  };
}
