import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faExclamationTriangle,
  faEnvelopeOpenText,
  faPaperPlane,
  faPlus,
  faUserCog,
  faUser,
  faCog,
  faChevronLeft,
  faTimes,
  faPencilAlt,
  faMinus,
  faCheck,
  faCheckCircle,
  faChevronRight,
  faChevronUp,
  faBan,
  faExclamation,
  faQuestionCircle,
  faFlag,
  faBars,
  faAngleDoubleRight,
  faEllipsisV,
  faDownload,
  faShare,
  faShareAlt,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import {
  faTwitter,
  faWhatsapp,
  faWhatsappSquare,
  faGoogle,
  faFacebook,
  faFacebookSquare,
  faTiktok,
  faInstagram,
  faInstagramSquare,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

// TODO: add icons here...
export const loadFontIcons = () =>
  library.add(
    faChevronLeft,
    faChevronRight,
    faExclamationTriangle,
    faEnvelopeOpenText,
    faPaperPlane,
    faPlus,
    faUserCog,
    faUser,
    faCog,
    faChevronLeft,
    faTimes,
    faPencilAlt,
    faMinus,
    faCheck,
    faCheckCircle,
    faGoogle,
    faTwitter,
    faWhatsapp,
    faWhatsappSquare,
    faFacebook,
    faFacebookSquare,
    faChevronUp,
    faBan,
    faExclamation,
    faQuestionCircle,
    faFlag,
    faBars,
    faAngleDoubleRight,
    faEllipsisV,
    faTiktok,
    faInstagram,
    faInstagramSquare,
    faYoutube,
    faDownload,
    faShare,
    faShareAlt,
    faArrowRight,
  );

const FontIcon = ({ type, name, className, style, onClick, size, color }) => (
  <FontAwesomeIcon
    icon={[type ?? 'fas', name]}
    className={className}
    style={style}
    onClick={onClick}
    size={size}
    color={color}
  />
);

FontIcon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.object),
};

FontIcon.defaultProps = {
  name: '',
  className: '',
  style: {},
};

export default FontIcon;
