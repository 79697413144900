import Logo from '../../../assets/images/foodlama-logo-long.svg';
import AuthBG from '../../../assets/images/auth-bg/auth-bg.png';
import GoogleIcon from '../../../assets/icon/google-circle.svg';
import { isEmpty } from 'lodash';
import React, { Fragment, useState } from 'react';
import { t } from 'i18next';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Heading,
  useToast,
  Image,
  Flex,
  Text,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';

import { CheckIcon } from '@chakra-ui/icons';

import SEO from 'components/SEO';
import ErrorBox from 'components/error-box';

import { tests } from 'utils/validate';
import { path } from 'utils/const';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { parentPostMessage } from 'utils/postmessage-module/postMessage';
import { isFeatureOn } from 'utils/feature-flags/feature-flags-module';
import { useSelector } from 'react-redux';
import { isAndroid } from 'react-device-detect';

/**
 * @param {{
 *  isLoading: {
 *    email: boolean,
 *    anon: boolean,
 *    google: boolean,
 *    facebook: boolean
 *  },
 *  error: string,
 *  onSignUpClick: () => void,
 *  formRegister: UseFormRegister<FieldValues>,
 *  getFormValue: UseFormGetValues<FieldValues>,
 *  focusSetter: UseFormSetFocus<FieldValues>,
 * }} props
 * @returns Element
 */
export const MobileSignUp = ({
  isLoading,
  error,
  onSignUpClick,
  formRegister,
  getFormValue,
  focusSetter,
}) => {
  const { me, featureFlags } = useSelector((state) => state.app);

  const toast = useToast();

  const history = useHistory();

  const showToast = (msg) => {
    toast({
      description: msg,
      status: 'warning',
      duration: 1000,
      position: 'top',
      isClosable: true,
    });
  };

  // state for password
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRules, setShowPasswordRules] = useState(false);
  const [borderColor, setBorderColor] = useState('#A4A6A5');
  const [isLongEnough, setIsLongEnough] = useState(false);
  const [hasDigit, setHasDigit] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [isAddPadding, setIsAddPadding] = useState(false);

  const validatePassword = (password) => {
    const longEnough = password.length >= 8;
    const digit = /[0-9]/.test(password);
    const lowercase = /[a-z]/.test(password);
    const uppercase = /[A-Z]/.test(password);

    setIsLongEnough(longEnough);
    setHasDigit(digit);
    setHasLowercase(lowercase);
    setHasUppercase(uppercase);

    setBorderColor(
      !longEnough || !digit || !lowercase || !uppercase
        ? 'orange.500'
        : '#A4A6A5'
    );
  };

  const handleOnSubmit = () => {
    if (isEmpty(getFormValue().name)) {
      showToast(t('authScreen.nameWarning'));
      focusSetter('name');
      return;
    }

    if (isEmpty(getFormValue().email)) {
      showToast(t('authScreen.emailWarning'));
      focusSetter('email');
      return;
    }

    if (isEmpty(getFormValue().password)) {
      showToast(t('authScreen.passwordWarning'));
      focusSetter('password');
      return;
    }

    if (!isLongEnough) {
      showToast(t('authScreen.must-at-least-8'));
      focusSetter('password');
      return;
    }

    if (!hasDigit) {
      showToast(t('authScreen.must-contain-digit'));
      focusSetter('password');
      return;
    }

    if (!hasLowercase) {
      showToast(t('authScreen.must-contain-lowercase'));
      focusSetter('password');
      return;
    }

    if (!hasUppercase) {
      showToast(t('authScreen.must-contain-uppercase'));
      focusSetter('password');
      return;
    }

    onSignUpClick();
  };

  const submitLoginWithGoogle = async () => {
    if (process.env.REACT_APP_RUN_ON_FLUTTER) {
      parentPostMessage('REQUEST_GOOGLE_LOGIN');
      return;
    }
  };

  return (
    <Fragment>
      <SEO title="FoodLama | Sign In" />
      <Box
        w={'100vw'}
        h={'100vh'}
        maxW={'430px'}
        pos={'absolute'}
        left={'0px'}
        right={'0px'}
        mx={'auto'}
      >
        <Box px={'16px'} pt={'30px'}>
          <Image h={'20px'} src={Logo} />
        </Box>
        <Box mt="9" overflow="hidden">
          <Image
            src={AuthBG}
            w="full"
            h="full"
            mt="-40"
            objectFit="cover"
            alt="auth-bg"
          />
        </Box>
        <Box
          bg={'#F2F5FA'}
          pos={'absolute'}
          left={'0px'}
          bottom={'0px'}
          w={'100vw'}
          maxW={'430px'}
          px={'16px'}
          pt={'24px'}
          pb={isAndroid && isAddPadding ? '150px' : '30px'}
          borderRadius={'40px 40px 0px 0px'}
          transition={'all 0.3s ease'}
        >
          <Heading textAlign={'center'} mb={'30px'}>
            {t('authScreen.signUpTitle')}
          </Heading>
          {error && (
            <ErrorBox
              style={{
                marginBottom: '16px',
                marginTop: '20px',
                borderRadius: '100px',
                fontSize: '16px',
                paddingLeft: '16px',
                paddingRight: '16px',
                lineHeight: '1.2',
                backgroundColor: 'white',
              }}
            >
              {error}
            </ErrorBox>
          )}
          <FormControl id="name" variant={'floating'} mb={'16px'}>
            <Input
              type="name"
              placeholder={t('authScreen.namePlaceholder')}
              onFocus={() => setIsAddPadding(true)}
              {...formRegister('name', {
                pattern: {
                  value: tests.name.test,
                  message: t(tests.name.error),
                },
                onBlur: () => setIsAddPadding(false),
              })}
            />
            <FormLabel htmlFor="name">
              {t('authScreen.namePlaceholder')}
            </FormLabel>
          </FormControl>
          <FormControl id="email" variant={'floating'} mb={'16px'}>
            <Input
              type="email"
              placeholder={t('authScreen.emailPlaceholder')}
              onFocus={() => setIsAddPadding(true)}
              {...formRegister('email', {
                pattern: {
                  value: tests.email.test,
                  message: t(tests.email.error),
                },
                onBlur: () => setIsAddPadding(false),
              })}
            />
            <FormLabel htmlFor="email">
              {t('authScreen.emailPlaceholder')}
            </FormLabel>
          </FormControl>
          <FormControl
            id="password"
            variant={'floating'}
            transition={'all 0.3s'}
            transformOrigin={'top'}
            mb="4"
            borderColor={borderColor}
            _focusWithin={{
              borderColor: borderColor,
            }}
          >
            <Input
              type={showPassword ? 'text' : 'password'}
              placeholder={t('authScreen.passwordPlaceholder')}
              onFocus={() => setIsAddPadding(true)}
              {...formRegister('password', {
                pattern: {
                  value: tests.password.test,
                  message: t(tests.password.error),
                },
                onBlur: () => setIsAddPadding(false),
                onChange: (e) => {
                  setShowPasswordRules(!!e.target.value);
                  validatePassword(e.target.value);
                },
              })}
              w={'calc(100% - 55px)'}
            />
            <FormLabel htmlFor="password">
              {t('authScreen.passwordPlaceholder')}
            </FormLabel>

            <Flex
              pos={'absolute'}
              fontSize={'14px'}
              right={'16px'}
              top={'0px'}
              textDecor={'underline'}
              h={'100%'}
              alignItems={'center'}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? 'Hide' : 'Show'}
            </Flex>
          </FormControl>
          {/* password rules */}
          {showPasswordRules && (
            <List
              ml="2"
              mb="6"
              fontSize={{ base: 'lg', md: 'xl' }}
              color="blackAlpha.700"
            >
              <ListItem>
                <ListIcon
                  as={CheckIcon}
                  color={isLongEnough ? '#04CEAA' : 'orange.500'}
                />
                {t('authScreen.must-at-least-8')}
              </ListItem>
              <ListItem>
                <ListIcon
                  as={CheckIcon}
                  color={hasDigit ? '#04CEAA' : 'orange.500'}
                />
                {t('authScreen.must-contain-digit')}
              </ListItem>
              <ListItem>
                <ListIcon
                  as={CheckIcon}
                  color={hasLowercase ? '#04CEAA' : 'orange.500'}
                />
                {t('authScreen.must-contain-lowercase')}
              </ListItem>
              <ListItem>
                <ListIcon
                  as={CheckIcon}
                  color={hasUppercase ? '#04CEAA' : 'orange.500'}
                />
                {t('authScreen.must-contain-uppercase')}
              </ListItem>
            </List>
          )}

          <Button
            variant="mobileRounded"
            w="full"
            onClick={handleOnSubmit}
            isLoading={isLoading.email}
            mb={
              isFeatureOn({
                featureKey: 'MOBILE_GOOGLE_AUTH',
                me: me,
                featureFlags: featureFlags,
                noAuth: true,
              })
                ? '30px'
                : 0
            }
          >
            {t('authScreen.signUpButton')}
          </Button>

          {isFeatureOn({
            featureKey: 'MOBILE_GOOGLE_AUTH',
            me: me,
            featureFlags: featureFlags,
            noAuth: true,
          }) && (
            <Flex
              flexDirection="row"
              alignItems={'center'}
              justifyContent={'center'}
              mb={'10px'}
            >
              <Text
                fontSize={'16px'}
                textAlign={'center'}
                fontWeight={'semibold'}
                mr={'10px'}
              >
                {t('authScreen.or-signup-with')}
              </Text>

              <Box display={'flex'} justifyContent={'center'}>
                <Image
                  src={GoogleIcon}
                  cursor={'pointer'}
                  onClick={submitLoginWithGoogle}
                />
              </Box>
            </Flex>
          )}

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt="16px"
            columnGap="1ch"
          >
            <Text fontSize="14px" fontWeight="semibold">
              {t('auth-page/already-member')}
            </Text>
            <Button
              variant="link"
              textDecoration="underline"
              fontWeight={400}
              fontSize="14px"
              onClick={() => history.push(path.login)}
            >
              {t('sign-in')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
