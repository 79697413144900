const FLUTTER_CHANNEL = 'WEB_APP_CHANNEL';

/**
 * Substations function to handle window.parent.postMessage it will run to send data to flutter channel
 * @param {any} message
 * @param {string} targetOrigin
 */
export const parentPostMessage = (message, targetOrigin) => {
  console.log('POST_MESSAGE', message);
  var insideIframe = window === window.parent ? false : true;
  if (insideIframe) {
    // normal window.parent.postMessage because we now it run on iframe
    window.parent.postMessage(message, targetOrigin);
  } else {
    if (typeof message == 'string' && process.env.REACT_APP_RUN_ON_FLUTTER) {
      console.log(message);
    }
    if (!window.flutter_inappwebview) {
      return;
    }
    try {
      // send data to flutter channel
      window.flutter_inappwebview.callHandler(FLUTTER_CHANNEL, message);
    } catch (error) {
      console.warn(error);
    }
  }
};
