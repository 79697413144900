import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isEmpty, negate } from 'lodash';
import getRetailerNameFromUrl from '../utils/get-retailer-name-from-url';
import postProduct from '../utils/post-product';
import { getProductIdFromUrl, getProductUid } from '../utils/product';

const productsSlice = createSlice({
  name: 'products',
  // Store analyzed product inside a hashmap. Use retailer+productId or url as the key.
  initialState: {
    reqStatus: 'success',
    error: null,
    products: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductsByUrl.fulfilled, (state, action) => {
        const updatedProducts = action.payload.reduce((acc, product) => {
          return {
            ...acc,
            [getProductUid(product)]: product,
          };
        }, {});

        state.products = {
          ...state.products,
          ...updatedProducts,
        };

        state.reqStatus = 'success';
        state.error = null;
      })
      .addCase(fetchProductsByUrl.rejected, (state, action) => {
        state.reqStatus = 'error';
        state.error = action.error;
      })
      .addCase(fetchProductsByUrl.pending, (state) => {
        state.reqStatus = 'loading';
      });
  },
});

const fetchProductsByUrl = createAsyncThunk(
  'products/fetchProductsByUrl',
  async (productUrls) => {
    // Request payloads generated from url.
    const payloads = productUrls.map((productUrl) => {
      const retailer = getRetailerNameFromUrl(productUrl);

      if (isEmpty(retailer)) {
        return {
          error: 'Unknown retailer.',
          flags: [],
          retailer: '',
        };
      }

      const productId = getProductIdFromUrl({ url: productUrl, retailer });

      return {
        // Keep the original url.
        productUrl,
        product: {
          productId,
        },
        retailer: { name: retailer },
        flags: [],
        options: {
          withJustification: false,
        },
      };
    });

    const allResponse = await Promise.allSettled(
      payloads.map((p) =>
        p.error
          ? Promise.resolve(p)
          : postProduct(p)
              .then((res) => {
                const productData = res.data?.data;
                return {
                  flags: [],
                  // Append original url as part of data.
                  ...p,
                  ...productData,
                  retailer: p.retailer.name,
                  ...(!productData.productId
                    ? { error: 'Product not found.' }
                    : {}),
                };
              })
              .catch(() => ({
                productId: p.product?.productId,
                retailer: p.retailer.name,
                productUrl: p.productUrl,
                flags: [],
              }))
      )
    );

    const alternativeAnalyzeResult = allResponse.map((res) => {
      if (
        res.status === 'fulfilled' &&
        !res.value.error &&
        ['productId', 'flags'].every(negate(isEmpty))
      ) {
        return {
          ...res.value,
        };
      } else {
        // Error response won't have complete object or no object at all.
        return {
          // This error will be overridden if there's already an error from previous processes.
          error: 'Product cannot be analysed.',
          ...res?.value,
        };
      }
    });

    return alternativeAnalyzeResult;
  }
);

export const actions = {
  ...productsSlice.actions,
  fetchProductsByUrl,
};

export default productsSlice.reducer;
