import {
  Button,
  Flex,
  Grid,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

const ModalConfirm = ({
  isOpen,
  onClose,
  isLoading = false,
  isCentered = false,
  onSubmit,
  modalTitle = null,
  modalBody = null,
  modalTitleSize = '3xl',
  modalTitleBody = ['xl', 'md', 'md', 'large'],
  modalTitleBodyGap = 6,
  cancelBtnText = 'No',
  confirmBtnText = 'Yes',
  children,
  isFullScreen = false,
  textAlign = 'left',
}) => {
  return (
    <Modal
      motionPreset="slideInBottom"
      isOpen={isOpen}
      onClose={onClose}
      isCentered={isCentered}
      size={isFullScreen ? 'full' : null}
    >
      <ModalOverlay />
      <ModalContent borderRadius="xl">
        <ModalCloseButton
          _focus={{ background: 'none' }}
          fontSize="md"
          top={['2', '4']}
        />
        <ModalBody my="6">
          {(modalTitle || modalBody) && (
            <Grid w="full" gap={modalTitleBodyGap}>
              {modalTitle && (
                <Heading
                  fontSize={modalTitleSize}
                  color="blackAlpha.800"
                  textAlign={textAlign}
                >
                  {modalTitle}
                </Heading>
              )}
              {modalBody && (
                <Text
                  fontSize={modalTitleBody}
                  color="blackAlpha.700"
                  textAlign={textAlign}
                >
                  {modalBody}
                </Text>
              )}
            </Grid>
          )}

          {children}
        </ModalBody>
        <ModalFooter>
          <Flex w="full" gap="4" alignItems="center" justifyContent="end">
            <Button minW="75px" variant="outline" onClick={onClose}>
              {cancelBtnText}
            </Button>
            <Button
              minW="75px"
              variant="solid"
              isLoading={isLoading}
              onClick={onSubmit}
            >
              {confirmBtnText}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalConfirm;
