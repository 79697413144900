import { isEmpty, isNull, isUndefined } from 'lodash';
import { openProductAnalysisWithBarcodeOrUrl } from './fetch-product-module';
import { firestore } from './firebase';

/**
 * Represents a scan history item.
 * @class
 */
export class ScanHistory {
  /**
   * @type {string[]}
   */
  available_at;

  /**
   * @type {string}
   */
  scan_id;

  /**
   * @type {string}
   */
  barcode;

  /**
   * @type {string}
   */
  environment;

  /**
   * @type {string}
   */
  event_name;

  /**
   * @type {string}
   */
  event_type;

  /**
   * @type {string}
   */
  found_at;

  /**
   * @type {boolean}
   */
  is_found;

  /**
   * @type {string}
   */
  main_app_version;

  /**
   * @type {number}
   */
  product_id;

  /**
   * @type {string}
   */
  product_name;

  /**
   * @type {string}
   */
  product_image_url;

  /**
   * @type {string}
   */
  product_url;

  /**
   * @type {Timestamp}
   */
  timestamp;

  /**
   * @type {string}
   */
  user_email;

  /**
   * @type {string}
   */
  user_uid;

  /**
   * Converts a Firestore object to a JavaScript object.
   * @param {object} firestoreObject - The Firestore object to convert.
   * @returns {ScanHistory} - The JavaScript object representation of the Firestore object.
   */
  static fromFirestoreObject(firestoreObject) {
    const scanHistory = new ScanHistory();

    scanHistory.available_at = firestoreObject.available_at ?? [];
    scanHistory.scan_id = firestoreObject.scan_id ?? '';
    scanHistory.barcode = firestoreObject.barcode ?? '';
    scanHistory.environment = firestoreObject.environment ?? '';
    scanHistory.event_name = firestoreObject.event_name ?? '';
    scanHistory.event_type = firestoreObject.event_type ?? '';
    scanHistory.found_at = firestoreObject.found_at ?? '';
    scanHistory.is_found = firestoreObject.is_found ?? false;
    scanHistory.main_app_version = firestoreObject.main_app_version ?? '';
    scanHistory.product_id = firestoreObject.product_id ?? '';
    scanHistory.product_name = firestoreObject.product_name ?? '';
    scanHistory.product_image_url = firestoreObject.product_image_url ?? '';
    scanHistory.product_url = firestoreObject.product_url ?? '';
    scanHistory.timestamp = firestoreObject.timestamp ?? null;
    scanHistory.user_email = firestoreObject.user_email ?? '';
    scanHistory.user_uid = firestoreObject.user_uid ?? '';

    return scanHistory;
  }

  async openProductAnalysisPage() {
    await openProductAnalysisWithBarcodeOrUrl(
      this.scan_id,
      this.barcode,
      null,
      true,
      'Back',
      false,
      false
    );
  }
}

/**
 * get scan history data
 * @param {{
 *  emailOrUID: string,
 *  limit: number,
 *  lastHistoryTimestamp: Timestamp | null
 * }} param0
 * @returns {Promise<ScanHistory[]>}
 */
export async function getScanHistoryData({
  emailOrUID,
  limit = 10,
  lastHistoryTimestamp = null,
}) {
  let scanHistories = firestore
    .collection('shopping_history')
    .doc(emailOrUID)
    .collection('url_history')
    .where('event_name', '==', 'scan_product');

  if (lastHistoryTimestamp !== null) {
    scanHistories = scanHistories.where('timestamp', '<', lastHistoryTimestamp);
  }

  scanHistories = scanHistories.orderBy('timestamp', 'desc');
  scanHistories = scanHistories.limit(limit);

  try {
    const scanHistoriesData = await scanHistories.get();
    return scanHistoriesData.docs
      .map((history, idx) => {
        const barcode = history.data().barcode;
        if (isUndefined(barcode) || isNull(barcode)) {
          return null;
        }
        return ScanHistory.fromFirestoreObject(history.data());
      })
      .filter((v) => v !== null);
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getExistingScanIDs({ emailOrUID }) {
  try {
    const scanHistories = await getScanHistoryData({
      emailOrUID: emailOrUID,
    });
    const existingScanIDs = scanHistories
      .filter((v) => v.scan_id !== '')
      .map((v) => v.scan_id);
    return existingScanIDs;
  } catch (error) {
    console.log(error);
    return [];
  }
}

/**
 * generate the next scan history ID
 * @param {existingScanIDs} string[]
 * @returns
 */
export function generateNextScanID(existingScanIDs) {
  if (isEmpty(existingScanIDs)) {
    return 'SC001';
  }

  const highestScanID = existingScanIDs.reduce((max, scanID) => {
    const numberPart = parseInt(scanID.replace(/[^\d]/g, ''), 10);
    return numberPart > max ? numberPart : max;
  }, 0);

  const nextScanID = highestScanID + 1;
  return `SC${String(nextScanID).padStart(3, '0')}`;
}
