import React from 'react';
import { Box, Button, Grid, Heading, Image, Text } from '@chakra-ui/react';
import './error-404.scss';
import { t } from 'i18next';
import FoodlamaUninstallPattern from '../../../assets/images/patterns/foodlama-uninstall-page-pattern-light-green.svg';

const Error404 = () => {
  return (
    <Grid
      w="full"
      bg="#04CEAA"
      h="100vh"
      placeItems="center"
      overflowY={'auto'}
    >
      <Box position="fixed" height="100vh" width="110vw">
        <Image
          src={FoodlamaUninstallPattern}
          objectFit="cover"
          height="full"
          width="full"
        />
      </Box>
      <Grid
        pos={'relative'}
        placeItems="center"
        maxW={'450px'}
        mx={'auto'}
        zIndex={'2'}
      >
        <Box mb="20" px="8">
          <Heading fontSize={'43px'} textAlign="center" color="black" mb="4">
            {t('error-fallback.404.title')}
          </Heading>
          <Text fontSize={'16px'} textAlign="center" color="black" mb="4">
            {t('error-fallback.404.message')}
          </Text>
        </Box>

        <Image
          className="ghost"
          src="https://raw.githubusercontent.com/bedimcode/responsive-404-page/project-from-scratch/assets/img/ghost-img.png"
        />
        <Box className="shadow" />

        <Box mt="20" w="full" px="14">
          <Button
            w="full"
            variant="mobileRounded"
            shadow="2xl"
            onClick={() => {
              window.location.href =
                process.env.REACT_APP_PLATFORM !== 'staging'
                  ? 'https://myfoodpersonality.com/'
                  : 'https://foodlama.vercel.app/';
            }}
          >
            {t('error-fallback.404.button')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Error404;
