export const recommendationItemLimit = 10;

export const recommendationExcludedCats = [
  // Exclude drink products.
  'Beer, Wine & Spirits',
  'Wine',
  'Red Wine',
  'White Wine',
  'Sauvignon Blanc',
  'Wine & Cider Vinegar',
  'Pre Mixed Spirits & Cocktails',
  'Pre mixed Spirits',
  'Beer, Lager & Ales',
  'Craft Beer',
  'Sparkling Wine',
  'Mini Wines',
  'Wine Gift Sets',
  'Prosecco',
  'Champagne',
  'Bottled Lagers',
  'Canned Lagers',
  'Ale & Bitter',
  'Bitter',
  'Stout',
  'Ale',
  'Pale Ales',
  'Pilsners & Lagers',
  'Speciality & World Craft Beer',
  'Beer Gift Sets',
  'Cider',
  'Apple Cider',
  'Fruit & Flavoured Cider',
  'Multipacks Cider',
  'Spirits',
  'Gin',
  'Classic Gin',
  'Alcohol & Mixers',
  'Flavoured & Pink Gin',
  'Vodka',
  'Classic Vodka',
  'Flavoured Vodka',
  'Whisky',
  'American Whiskey',
  'Imported Whisky',
  'Irish Whiskey',
  'Scotch & Malt Whisky',
  'Rum',
  'Dark & Golden Rum',
  'White Rum',
  'Flavoured & Spiced Rum',
  'Brandy & Cognac',
  'Liqueurs',
  'Spirits Gift Sets',
  'Ready To Drink & Cocktails',
  'Cocktails',
  'No & Low Alcohol Spirits',
  'No & Low Alcohol Beer',
  'No & Low Alcohol Wine',
  'Diet Coke',
  'Gluten Free Beer & Spirits',
  'Gluten Free Beer, Lager & Spirits',
  'No & Low Alcohol Cider',
  'Fruity Beers',
  'Boxed Red Wine',
  'Rose Wine',
  'Boxed Rose Wine',
  'Port',
  'Tequila',
  'Sparkling Rosé',
  'Vegan Alcohol',
  'Vegan Wine',
  'Sherry',
  'Vermouth',
  'Fortified Wines',
  'Extra Special Red Wine',
].map((s) => s.toLowerCase());
