import { Box, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';
import Select, { components } from 'react-select';

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <Flex gap="4" p="2" alignItems="center">
        <Box w={{ base: '36px', md: '48px' }} h={{ base: '36px', md: '48px' }}>
          <Image
            src={props.data.image}
            alt={props.data.label}
            w="full"
            h="full"
            objectFit="cover"
            loading="lazy"
          />
        </Box>
        <Text fontSize={{ base: '3xl', md: '4xl' }} fontWeight="500">
          {props.data.label}
        </Text>
      </Flex>
    </components.Option>
  );
};

const CustomSingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <Flex gap="4" alignItems="center">
        <Box w={{ base: '36px', md: '48px' }} h={{ base: '36px', md: '48px' }}>
          <Image
            src={props.data.image}
            alt={props.data.label}
            w="full"
            h="full"
            objectFit="cover"
            loading="lazy"
          />
        </Box>
        <Text fontSize={{ base: '3xl', md: '4xl' }} fontWeight="500">
          {children}
        </Text>
      </Flex>
    </components.SingleValue>
  );
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '48px',
    borderColor: state.isFocused ? '#045050' : 'inherit',
    boxShadow: state.isFocused ? '0 0 0 1px #045050' : provided.boxShadow,
    '&:hover': {
      borderColor: state.isFocused ? '#045050' : 'inherit',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? '#045050'
      : state.isFocused
      ? '#b2ddd5'
      : 'inherit',
    color: state.isSelected ? 'white' : state.isFocused ? 'black' : 'inherit',
    '&:hover': {
      backgroundColor: state.isSelected
        ? '#045050'
        : state.isFocused
        ? '#b2ddd5'
        : 'inherit',
      color: state.isSelected ? 'white' : state.isFocused ? 'black' : 'inherit',
    },
  }),
};

/**
 *
 * @param {{
 *  value: string,
 *  label: string,
 *  image: string,
 * }} options[]
 * @returns
 */
const SelectImage = ({
  options = [],
  defaultValue = options[0],
  isDisabled = false,
  isLoading = false,
  isClearable = false,
  isSearchable = true,
  isRtl = false,
  placeholder = 'Select...',
  onChange,
}) => {
  return (
    <Select
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isClearable={isClearable}
      isRtl={isRtl}
      options={options}
      placeholder={placeholder}
      onChange={onChange}
      components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
      styles={customStyles}
    />
  );
};

export default SelectImage;
