import axios from 'axios';

/**
 * To check whether the image was broken or not.
 *
 * @param {string} url
 * @returns
 */
const validateImageUrl = async (url) => {
  return axios
    .get(url, {
      // Set a small timeout so we don't wait too long.
      timeout: 5000,
      // Only retrieve headers to make it faster and consume less data.
      method: 'head',
    })
    .then((response) => {
      // Check for a 2XX success status code.
      return response.status >= 200 && response.status < 300;
    })
    .catch((error) => {
      // Any kind of error (like 404 or 500) will land here.
      console.error('Error checking image:', error);
      return false;
    });
};

export default validateImageUrl;
