import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { firestore } from 'utils/firebase';

const initialState = {
  creatorProfile: {},
  fetchProfileStatus: 'success',
  products: null,
  fetchProductsStatus: 'success',
};

const landingSlice = createSlice({
  name: 'landing',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchCreatorProfile.fulfilled, (state, { payload }) => {
      state.creatorProfile = payload[0];
      state.fetchProfileStatus = 'success';
    });
    builder.addCase(fetchCreatorProfile.pending, (state) => {
      state.fetchProfileStatus = 'loading';
    });
    builder.addCase(fetchCreatorProfile.rejected, (state) => {
      state.fetchProfileStatus = 'error';
    });
  },
});

const fetchCreatorProfile = createAsyncThunk(
  'landing/fetchCreatorProfile',
  async (username) => {
    const profile = (
      await firestore
        .collection('users')
        .where('username', '==', username)
        .limit(1)
        .get()
    ).docs.map((doc) => doc.data());

    return profile;
  }
);

export const actions = {
  ...landingSlice.actions,
  fetchCreatorProfile,
};
export default landingSlice.reducer;
