import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Button,
  Heading,
  useDisclosure,
  FormErrorMessage,
  Grid,
  useToast,
} from '@chakra-ui/react';

import SEO from 'components/SEO';
import ErrorBox from 'components/error-box';
import SendPasswordResetEmail from 'components/send-password-reset-email';

import { path } from 'utils/const';
import { tests } from 'utils/validate';
import { actions } from 'slices/app.slice';
import FontIcon from 'components/font-icon';
import getFirstNameAndLastName from 'utils/get-first-and-last-name';
import { MobileLoginScreen } from './mobile-login';

const contentMaxWidth = ['md', '480px'];

const Login = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  const { me, retailerCurrentUrl, onboardingPayload, refCookie } = useSelector(
    (state) => state.app
  );

  const toast = useToast();

  const [resErr, setResError] = useState('');
  const [keepLoggedIn, setKeepLoggedIn] = useState(true);
  const [isLoading, setLoading] = useState({
    email: false,
    anon: false,
    google: false,
    facebook: false,
  });

  const {
    getValues,
    setFocus,
    handleSubmit,
    register,
    formState: { errors /* isSubmitting */ },
  } = useForm();

  const {
    isOpen: isOpenResetPassword,
    onOpen: onOpenResetPassword,
    onClose: onCloseResetPassword,
  } = useDisclosure();

  const submitLoginWithEmailAndPassword = async (data) => {
    setLoading((previousState) => ({ ...previousState, email: true }));

    try {
      if (data.referral) {
        data.referral = {
          code: data.referral.toLowerCase().replace(/[^A-Z0-9]+/gi, ''),
          name: data.referral,
          isVerified: false,
        };
      }

      /**
       * temporary for cuk presentation purposes;
       * set referral user login label to true
       * so when referral user logged in, it will redirected to
       * setup preference page.
       */
      if (refCookie && refCookie.isSupported) {
        dispatch(actions.setAfterReferralUserSignIn(true));
      }

      await dispatch(
        actions.loginWithEmailAndPassword(
          data,
          keepLoggedIn,
          retailerCurrentUrl,
          window.location.href
        )
      );

      // Save the onboarding data, if the
      // user authenticate from the onboarding page
      if (!isEmpty(onboardingPayload?.selectedPreferences)) {
        const userId = me?.id;
        const name = getFirstNameAndLastName(me?.name);
        const subMemberId = uuidv4();

        await dispatch(
          actions.addNewProfile({
            userId,
            name,
            subMemberId,
            preferences: onboardingPayload.selectedPreferences,
          })
        );

        history.replace(path.onboardingFour);
      }

      setResError('');
    } catch (err) {
      /**
       * temporary for cuk presentation purposes;
       * if login failed the login state label for referral user will be reseted
       */
      if (refCookie && refCookie.isSupported) {
        dispatch(actions.setAfterReferralUserSignIn(false));
      }
      setResError(t(err.code));
    } finally {
      setLoading((previousState) => ({ ...previousState, email: false }));
    }
  };

  const submitPasswordReset = async ({ email }) => {
    if (email) {
      try {
        onCloseResetPassword();
        dispatch(actions.setIsShowTransparentOverlayLoader(true));
        await dispatch(actions.resetPassword(email));
        toast({
          description:
            "Success, please click the 'Reset Password' link in your email.",
          status: 'success',
          duration: 1000,
          position: 'top',
          isClosable: true,
        });
        dispatch(actions.setIsShowTransparentOverlayLoader(false));
      } catch (err) {
        onCloseResetPassword();
      }
    }
  };

  if (process.env.REACT_APP_RUN_ON_FLUTTER) {
    return (
      <Fragment>
        <MobileLoginScreen
          isLoading={isLoading}
          error={resErr}
          onLoginClick={handleSubmit(submitLoginWithEmailAndPassword)}
          onOpenResetPassword={onOpenResetPassword}
          formRegister={register}
          getFormValue={getValues}
          key={'mobile-login'}
          focusSetter={setFocus}
        />
        <SendPasswordResetEmail
          isOpen={isOpenResetPassword}
          onSubmit={submitPasswordReset}
          onClose={() => onCloseResetPassword()}
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <SEO title="FoodLama | Sign in" />
      <Box textAlign="left" width={contentMaxWidth}>
        {/* Render navigation button and error box when is not referall user  */}
        {!refCookie.isSupported ? (
          <Fragment>
            {resErr && <ErrorBox>{resErr}</ErrorBox>}
            <Grid
              templateColumns="15px 1fr"
              gap={['3', '5', '6']}
              mb="2"
              _hover={{ cursor: 'pointer' }}
              onClick={() => history.goBack()}
            >
              <Box fontSize="3xl">
                <FontIcon name="chevron-left" />
              </Box>
              <Heading fontSize="4xl" _hover={{ textDecoration: 'underline' }}>
                {t('sign-in')}
              </Heading>
            </Grid>
          </Fragment>
        ) : (
          // only render error box when is referall user
          <Fragment>
            {resErr && (
              <ErrorBox style={{ marginBottom: '0px', marginTop: '20px' }}>
                {resErr}
              </ErrorBox>
            )}
          </Fragment>
        )}

        <form>
          <Box boxSize="5" />
          <FormControl id="email" isInvalid={errors.email}>
            <FormLabel htmlFor="email">{t('email')}</FormLabel>
            <Input
              type="email"
              placeholder={t('email')}
              defaultValue={
                refCookie && refCookie.isSupported ? refCookie.email : null
              }
              {...register('email', {
                pattern: {
                  value: tests.email.test,
                  message: t(tests.email.error),
                },
              })}
            />
            <FormErrorMessage color="orange.500">
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
          <Box boxSize="4" />
          <FormControl id="password" isInvalid={errors.password}>
            <FormLabel>{t('password')}</FormLabel>
            <Input
              type="password"
              placeholder={t('password')}
              {...register('password', {
                pattern: {
                  value: tests.password.test,
                  message: t(tests.password.error),
                },
              })}
            />
            <FormErrorMessage color="orange.500">
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>
          <Box boxSize="4" />
          {refCookie && refCookie.isSupported && (
            <>
              <FormControl id="referral" isInvalid={errors.referral}>
                <FormLabel htmlFor="referral">Referral</FormLabel>
                <Input
                  variant="filled"
                  type="text"
                  placeholder={t('auth-page/name-placeholder')}
                  value={refCookie.code}
                  readOnly={true}
                  {...register('referral', {
                    pattern: {
                      value: tests.referral.test,
                      message: t(tests.referral.error),
                    },
                  })}
                />
                <FormErrorMessage color="orange.500">
                  {errors.referral && errors.referral.message}
                </FormErrorMessage>
              </FormControl>
              <Box boxSize="4" />
            </>
          )}
          <Checkbox
            color="gray"
            isChecked={keepLoggedIn}
            onChange={() => setKeepLoggedIn(!keepLoggedIn)}
          >
            {t('auth-page/keep-login')}
          </Checkbox>
          <Box boxSize="6" />
          <Button
            width="full"
            color="white"
            onClick={handleSubmit(submitLoginWithEmailAndPassword)}
            isLoading={isLoading.email}
          >
            {/* temporary for cuk user, it will say sign-up rather than sing-in */}
            {refCookie && refCookie.isSupported ? t('sign-up') : t('sign-in')}
          </Button>
          <Box boxSize="5" />
          {refCookie && refCookie.isSupported ? (
            // temporary: simulate sign up page for cuk
            <Fragment>
              <Heading
                size="sm"
                color="teal.500"
                // based on figma design cuk user sign-in link is center aligned
                textAlign={'center'}
              >
                {t('auth-page/already-member')}{' '}
                <Button
                  variant="link"
                  textDecoration="underline"
                  fontWeight={400}
                  onClick={() => history.push(path.login)}
                >
                  {t('sign-in')}
                </Button>
              </Heading>
            </Fragment>
          ) : (
            <Fragment>
              <Heading size="sm" color="teal.500">
                {t('auth-page/no-account')}{' '}
                <Button
                  variant="link"
                  textDecoration="underline"
                  onClick={() => history.push(path.signup)}
                >
                  {t('sign-up')}
                </Button>
              </Heading>
              <Box boxSize="5" />
              <Button
                variant="link"
                textDecoration="underline"
                onClick={onOpenResetPassword}
              >
                {t('button-component/forgot-password')}
              </Button>
            </Fragment>
          )}
        </form>
        <SendPasswordResetEmail
          isOpen={isOpenResetPassword}
          onSubmit={submitPasswordReset}
          onClose={() => onCloseResetPassword()}
        />
      </Box>
    </Fragment>
  );
};

export default Login;
