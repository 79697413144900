import { generateAllCombinations } from '../misc';

const filterCompatibleFlags = (flag) =>
  flag && typeof flag.value === 'number' && flag.value < 1;

export function getAlternativesAnalysis({
  allPrefs,
  flags,
  ignoredPreferences,
}) {
  const _allPrefs = allPrefs.map((pref) => pref.name);

  const compatiblePrefs = (() => {
    const a = [
      ...new Set([...flags.filter(filterCompatibleFlags).map((f) => f.name)]),
    ];

    // Append milk_free if the product is dairy_free
    return a.includes('dairy_free') ? [...a, 'milk_free'] : a;
  })();

  const incompatiblePrefs = [
    ...new Set(_allPrefs.filter((pref) => !compatiblePrefs.includes(pref))),
  ];

  const incompatibleCombinations = generateAllCombinations(
    incompatiblePrefs
      // filter out ignored preferences
      .filter((pref) => !ignoredPreferences?.includes(pref) ?? true)
  )
    .filter((comb) => comb.length > 1)
    .sort((a, b) => a.length - b.length);

  return {
    incompatibleCombinations,
    incompatiblePrefs: incompatiblePrefs,
  };
}
