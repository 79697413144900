import { useHistory } from 'react-router-dom';
import {
  Flex,
  Avatar,
  Box,
  Image,
  Spacer,
  Button,
  Divider,
} from '@chakra-ui/react';
import { path } from 'utils/const';
import HamburgerMenuIcon from '../../../assets/icon/hamburger-menu.svg';
import { Fragment } from 'react';
import ChevronCircleLeftIcon from '../../../assets/icon/chevron-circle-left.svg';
import CrossCircleIcon from '../../../assets/icon/cross-circle.svg';
import CrossCircleBlackIcon from '../../../assets/icon/cross-circle-black.svg';
import ChevronRightIcon from '../../../assets/icon/chevron-right.svg';
import SuccessPattern from '../../../assets/images/patterns/foodlama-success-pattern.svg';
import { useDispatch, useSelector } from 'react-redux';
import { AVATAR_URL_FIREBASE_KEY } from 'utils/dicebar-avatar-module/dicebar-avatar-module';
import { actions } from 'slices/app.slice';

/**
 *
 * @param {{
 *  size: string,
 *  width: number | string,
 *  height: number | string,
 *  name: string | null,
 *  avaBg: string,
 *  showBorder: boolean,
 *  borderColor: string,
 *  borderWidth: string,
 *  color: string,
 *  rounded: string,
 *  boxBg: string,
 *  boxShadow: string,
 *  boxPadding: number | string,
 * }} param0
 * @returns
 */
export const UserAvatar = ({
  size = '2xl',
  width = 24,
  height = 24,
  name = null,
  avaBg = '#D9D9D9',
  showBorder = true,
  borderColor = 'white',
  borderWidth = '2px',
  color = 'white',
  rounded = 'full',
  boxBg = 'white',
  boxShadow = 'lg',
  boxPadding = 0,
}) => {
  const { me } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  return (
    <Fragment>
      <Box
        boxShadow={boxShadow}
        rounded={rounded}
        bg={boxBg}
        padding={boxPadding}
        onClick={() => dispatch(actions.setIsOpenModalAvatarForm(true))}
      >
        <Avatar
          size={size}
          h={height}
          w={width}
          color={color}
          bg={avaBg}
          borderWidth={borderWidth}
          showBorder={showBorder}
          borderColor={borderColor}
          rounded={rounded}
          name={name}
          src={me[AVATAR_URL_FIREBASE_KEY] ?? null}
        />
      </Box>
    </Fragment>
  );
};

export const NavigationMenu = ({ navProps }) => {
  const history = useHistory();

  return (
    <Box
      width="full"
      bgColor="white"
      position="sticky"
      top="0"
      py="4"
      zIndex="4"
      {...navProps}
    >
      <Flex alignItems="center" px="8">
        <Box cursor="pointer" onClick={() => history.push(path.profile)}>
          <Image
            w="full"
            h="full"
            src={HamburgerMenuIcon}
            objectFit="cover"
            alt="hamburger-menu"
            loading="lazy"
          />
        </Box>

        <Spacer />

        <UserAvatar size="lg" width="50px" height="50px" avaBg="#b2ddd5" />
      </Flex>
    </Box>
  );
};

/**
 *
 * @param {{
 *  onClick: any,
 *  onNext: any,
 *  onClose: () => void,
 *  patternRotate: number | string,
 *  patternX: number | string,
 *  patternY: number | string,
 *  patternZ: number | string,
 *  withBackButton: boolean,
 *  withNextButton: boolean,
 *  withCloseButton: boolean,
 *  closeButtonColor: "white" | "black",
 *  closeButtonZIndex: number | null,
 *  withPattern: boolean,
 *  withProgressIndicator: boolean,
 *  completedStep: number,
 *  children: any,
 * }} param0
 * @returns
 */
export const SubMenuNavigation = ({
  onClick = () => {},
  onNext = () => {},
  onClose = () => {},
  patternRotate = -70,
  patternX = 49,
  patternY = 10,
  patternZ = -1,
  withBackButton = true,
  withNextButton = false,
  withCloseButton = false,
  closeButtonColor = 'white',
  closeButtonZIndex = 'unset',
  withPattern = false,
  withProgressIndicator = false,
  completedStep = 2,
  children,
}) => {
  return (
    <Fragment>
      {withPattern && (
        <Box
          w="full"
          h="full"
          position="fixed"
          top={0}
          right={0}
          bottom={0}
          left={0}
          bgImage={SuccessPattern}
          transform={`rotate(${patternRotate}deg) translate(${patternX}rem, ${patternY}rem)`}
          bgPosition="center"
          zIndex={patternZ}
        />
      )}

      {withBackButton && (
        <Box onClick={onClick} position="absolute" left="8" top="8">
          <Image
            src={ChevronCircleLeftIcon}
            w="full"
            h="full"
            objectFit="cover"
          />
        </Box>
      )}

      {withNextButton && (
        <Box onClick={onNext} position="absolute" right="8" top="8">
          <Image
            src={ChevronCircleLeftIcon}
            w="full"
            h="full"
            objectFit="cover"
            transform="scaleX(-1)"
          />
        </Box>
      )}

      {withCloseButton && (
        <Box
          onClick={onClose}
          position="absolute"
          right="8"
          top="8"
          zIndex={closeButtonZIndex}
        >
          <Image
            src={
              closeButtonColor === 'white'
                ? CrossCircleIcon
                : CrossCircleBlackIcon
            }
            w="full"
            h="full"
            objectFit="cover"
          />
        </Box>
      )}

      {withProgressIndicator && (
        <Flex alignItems="center" justifyContent="center" gap="6" p="8">
          <Flex
            fontSize={{ base: 'xl', md: '2xl' }}
            fontWeight="600"
            rounded="full"
            bgColor={completedStep >= 1 ? '#045050' : '#FFF'}
            border={`1px solid ${
              completedStep >= 1 ? 'transparent' : '#00000030'
            }`}
            color={completedStep >= 1 ? '#FFF' : '#1D2433A6'}
            w="50px"
            h="50px"
            alignItems="center"
            justifyContent="center"
            flexShrink="0"
          >
            1
          </Flex>
          <Divider w="50px" borderColor="#00000030" />
          <Flex
            fontSize={{ base: 'xl', md: '2xl' }}
            fontWeight="600"
            rounded="full"
            bgColor={completedStep > 1 ? '#045050' : '#FFF'}
            border={`1px solid ${
              completedStep > 1 ? 'transparent' : '#00000030'
            }`}
            color={completedStep > 1 ? '#FFF' : '#1D2433A6'}
            w="50px"
            h="50px"
            alignItems="center"
            justifyContent="center"
            flexShrink="0"
          >
            2
          </Flex>
        </Flex>
      )}

      {children}
    </Fragment>
  );
};

/**
 * @typedef {Object} menuList
 * @property {boolean} active
 * @property {string} name
 * @property {any} icon
 * @property {any} onClick
 * @type {menuList[]}
 */
export const NavigationMenuList = ({ menuList }) => {
  return (
    <Flex width="full" flexDir="column" gap="6">
      {menuList
        .filter((menu) => menu.active)
        .map((menu, i) => {
          return (
            <Button
              key={`nav-menu-${i}`}
              width="full"
              bgColor="#F8F9FB"
              py="9"
              variant="ghost"
              boxShadow="sm"
              rounded="2xl"
              justifyContent="space-between"
              rightIcon={
                <Image
                  src={ChevronRightIcon}
                  w="full"
                  h="full"
                  objectFit="cover"
                />
              }
              aria-label={menu.name}
              onClick={menu.onClick}
            >
              <Flex flex="1" gap="5" alignItems="center">
                <Box w="6">
                  <Image src={menu.icon} w="full" h="full" objectFit="cover" />
                </Box>
                {menu.name}
              </Flex>
            </Button>
          );
        })}
    </Flex>
  );
};
