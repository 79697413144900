import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { isNull, isUndefined } from 'lodash';
import { useState } from 'react';

const TabsPills = ({
  inActiveTabIndex = null,
  withComingSoon = true,
  isFitted = true,
  variant = 'soft-rounded',
  border = '1px solid #999CA0',
  borderRadius = '9999px',
  size = 'lg',
  mt = 0,
  mb = 4,
  navSelectedBg = '#04CEAA',
  navSelectedColor = 'white',
  navGap = 6,
  navPadding = 4,
  navFontSize = { base: '16px', md: '18px' },
  defaultIndex = 0,
  content = [
    {
      tabDisabled: false,
      tabTitle: '',
      tabBody: '',
      tabPanel: {},
      tabPanelPadding: '4',
      tabOnClick: () => {},
    },
  ],
}) => {
  const [tabIndex, setTabIndex] = useState(defaultIndex);
  const toast = useToast();

  const comingSoonToast = () =>
    toast({
      title: t('notification.coming-soon.title'),
      description: t('notification.coming-soon.body'),
      status: 'success',
      duration: 3000,
      position: 'top',
      isClosable: true,
    });

  return (
    <Tabs
      index={tabIndex}
      defaultIndex={defaultIndex}
      isFitted={isFitted}
      variant={variant}
      size={size}
      mt={mt}
      mb={mb}
      onChange={(index) =>
        setTabIndex(inActiveTabIndex && index === inActiveTabIndex ? 0 : index)
      }
    >
      <TabList gap={navGap}>
        {content.map((v, i) => {
          return (
            <Tab
              key={`tab-nav-${i}`}
              border={border}
              borderRadius={borderRadius}
              fontWeight="600"
              fontSize={navFontSize}
              color="black"
              isDisabled={v.tabDisabled}
              onClick={
                inActiveTabIndex && inActiveTabIndex === i && withComingSoon
                  ? comingSoonToast
                  : v.tabOnClick
              }
              p={navPadding}
              paddingInlineStart="unset"
              paddingInlineEnd="unset"
              _selected={{
                bg: navSelectedBg,
                color: navSelectedColor,
                border: 'none',
              }}
            >
              {v.tabTitle}
            </Tab>
          );
        })}
      </TabList>
      <TabPanels>
        {content.map((v, i) => {
          return (
            <TabPanel key={`tab-panel-${i}`} px="0" p={v.tabPanelPadding}>
              {!isUndefined(v.tabBody) && !isNull(v.tabBody) ? (
                <Text
                  fontSize={{ base: '16px', md: '20px' }}
                  color="black"
                  mb="4"
                >
                  {v.tabBody}
                </Text>
              ) : (
                <Box mb="8"></Box>
              )}
              {v.tabPanel && v.tabPanel}
            </TabPanel>
          );
        })}
      </TabPanels>
    </Tabs>
  );
};

export default TabsPills;
