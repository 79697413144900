import { useState } from 'react';
import { collection, query, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { collections } from '../utils/const';

export function usePreferences(preferenceCategory) {
  const supportedPrefRef = collection(
    useFirestore(),
    collections.supportedPrefs
  );

  const q = query(
    supportedPrefRef,
    where('category', preferenceCategory === 'custom' ? '==' : '!=', 'custom')
  );

  /**
   * Never use initialData property, or else the status
   * will always return success
   */
  const { data: supportedPrefs = [], status } = useFirestoreCollectionData(q);

  const [selectedPreferences, setSelectedPreferences] = useState([]);

  return {
    supportedPrefs,
    status,
    selectedPreferences,
    setSelectedPreferences,
  };
}
