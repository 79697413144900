import { useErrorBoundary } from 'react-error-boundary';
import './ErrorBoundaryFallback.scss';
import { useEffect, useState } from 'react';
import { t } from 'i18next';

const ErrorBoundaryFallback = () => {
  const { resetBoundary } = useErrorBoundary();

  const [rotation, setRotation] = useState(0);

  const handleMove = (event) => {
    const eye = document.querySelector('.eye');
    const x = eye.offsetLeft + eye.offsetWidth / 2;
    const y = eye.offsetTop + eye.offsetHeight / 2;
    const clientX = event.clientX || event.touches[0].clientX;
    const clientY = event.clientY || event.touches[0].clientY;
    const rad = Math.atan2(clientX - x, clientY - y);
    const rot = rad * (180 / Math.PI) * -1 + 180;
    setRotation(rot);
  };

  useEffect(() => {
    const eventListener = window.innerWidth > 1024 ? 'mousemove' : 'touchmove';
    document.body.addEventListener(eventListener, handleMove);

    return () => {
      document.body.removeEventListener(eventListener, handleMove);
    };
  }, []);

  const EyeBall = () => {
    return (
      <div
        className="eye"
        style={{
          transform: `rotate(${rotation}deg)`,
          WebkitTransform: `rotate(${rotation}deg)`,
        }}
      ></div>
    );
  };

  return (
    <div className="error-container">
      <div style={{ marginTop: '-5rem' }}>
        <span
          className="error-num"
          style={{ fontSize: window.innerWidth > 1024 ? '8em' : '12em' }}
        >
          5
        </span>

        {[1, 2].map((v, i) => (
          <EyeBall key={`eyeball-${i}`} />
        ))}
      </div>

      <p
        className="sub-text"
        style={{
          fontSize: window.innerWidth > 1024 ? '14px' : '16px',
        }}
      >
        {t('error-fallback.boundary.message')}
      </p>
      <button
        className="reset-button"
        onClick={() => {
          resetBoundary();
          window.location.reload();
        }}
      >
        {t('error-fallback.boundary.button')}
      </button>
    </div>
  );
};

export default ErrorBoundaryFallback;
