import amazingFactsAndNumbers from '../assets/images/facts/amazing-facts-and-numbers.svg';
import comfortFood from '../assets/images/facts/comfort-food.svg';
import fruitsAndVegetables from '../assets/images/facts/fruits-and-vegetables.svg';
import funFacts from '../assets/images/facts/fun-facts.svg';
import insectsAndAnimals from '../assets/images/facts/insects-and-animals.svg';
import bFreeFoods from '../assets/images/banner/b-free-foods.png';
import coopFreeFrom from '../assets/images/banner/coop-free-from.png';
import dammDaura from '../assets/images/banner/damm-daura.png';
import freeeFoods from '../assets/images/banner/freee-foods.png';
import nairnsGlutenFree from '../assets/images/banner/nairns-gluten-free.png';
import naturesPath from '../assets/images/banner/natures-path.png';
import oldElPasoGlutenFree from '../assets/images/banner/old-el-paso-gluten-free.png';
import warburtonsGlutenFree from '../assets/images/banner/warburtons-gluten-free.png';
import whiteRabbitPizza from '../assets/images/banner/white-rabbit-pizza.png';

const dropDownCustomStyles = {
  multiValue: (provided) => ({
    ...provided,
    borderRadius: 15,
  }),
};

// Data retrieved from
// https://docs.google.com/document/d/15L6sHz4KP6k4RR03H14rErdS2CEn4UiweJzZSwA30mo/edit?usp=sharing
// Revision: 2022-03-30 12:55
const foodFacts = [
  {
    name: 'Amazing Facts and Numbers',
    image: amazingFactsAndNumbers,
    texts: [
      'Bananas can help improve your mood and lower blood pressure',
      'The most expensive pizza in the world costs $12,000 dollars.',
      'Fruit-flavored snacks shine because of car wax.',
      'Nutmeg is a hallucinogen.',
      'Farmed salmon is dyed pink.',
      'American cheese is not American.',
      'Expiration dates on bottled water have nothing to do with the water, but with the bottle.',
      'French fries originated in Belgium, not France!',
      'Coffee beans can help eliminate bad breath.',
      'There’s digestible wood pulp in shredded cheese.',
      'Turkey consumes the most tea per person.',
      'Breakfast is NOT the most important meal of the day. General Foods used this as a marketing campaign in 1944 and it has since stuck.',
      'The Netherlands drinks the most coffee per person.',
      'There are more Indian restaurants in London than in Mumbai or Delhi.',
    ],
  },
  {
    name: 'Comfort Food',
    image: comfortFood,
    texts: [
      'Dark chocolate has huge health benefits',
      'Making Jelly beans is a lengthy process',
      'Ketchup used to be used as a medicine.',
      'White chocolate isn’t chocolate.',
      'McDonald’s sells 2.5 billion hamburgers every year.',
      'You can find peanuts in dynamite.',
      'Margherita pizza is named after a queen.',
      'Mountain Dew contains orange juice.',
      'Flamin’ Hot Cheetos were invented by a janitor.',
    ],
  },
  {
    name: 'Fruits and Vegetables',
    image: fruitsAndVegetables,
    texts: [
      'Pistachios are actually fruits',
      'Avocados are fruit',
      'Broccoli contains more protein than steak',
      'Raspberries are a member of the rose family',
      'Apples give you more energy than coffee',
      'Caesar salad originated from a Mexican city',
      "Bananas are berries...and strawberries aren't!",
      'The stickers on fruit are… edible!',
      'Ripe cranberries will bounce.',
      'There’s a small difference between jelly and jam. Jam is made from fruit, while Jelly is made from fruit juice.',
    ],
  },
  {
    name: 'Fun Facts',
    image: funFacts,
    texts: [
      'Peanut butter is good for you',
      'Hot dogs are not American',
      'Nectarine means as sweet as nectar',
      'Pecans are rich with antioxidants',
      'Bananas can float in water',
      'Humans share about 50% of their DNA with bananas!',
      'Acid is the biggest cause of tooth decay, not sugar!',
      'Cheese is the most stolen food in the world.',
      'No one knows the origin of chocolate chip cookies.',
      'Not all wine is vegan.',
      'Potatoes are 80% water.',
      'Brown sugar is no different than white sugar.',
      'Eskimos use refrigerators to stop their food from freezing.',
      'Sound can influence the taste of your food.',
    ],
  },
  {
    name: 'Insects and Animals',
    image: insectsAndAnimals,
    texts: [
      'One fast - food burger can have meat from 100 different cows.',
      'Rubbing the inside of a banana peel on a mosquito bite can help keep it from itching',
      'The red food dye for Skittles is made from boiled beetles.',
      'Honey is essentially, bee vomit.',
      'South African popcorn isn’t popcorn.It’s most likely roasted termites and ants.',
      'Food is allowed to contain some amount of insects.',
      'Goat meat accounts for 70 % of the red meat eaten globally.',
    ],
  },
].reduce((acc, curr) => {
  curr.texts.forEach((text) => {
    acc.push({
      name: curr.name,
      image: curr.image,
      text,
    });
  });
  return acc;
}, []);

// Data retrieved from
// https://foodlama-workspace.slack.com/archives/C029U85E6HL/p1693217179021249?thread_ts=1692959212.782009&cid=C029U85E6HL
// Revision: 2023-08-29 12:55
const sponsoredBrandBanners = [
  {
    name: 'B Free Foods',
    image: bFreeFoods,
    url: 'https://www.bfreefoods.com/uk/',
  },
  {
    name: 'Coop Free From',
    image: coopFreeFrom,
    url: 'https://shop.coop.co.uk/freefrom',
  },
  {
    name: 'Damm Daura',
    image: dammDaura,
    url: 'https://www.damm.com/en/beers/daura',
  },
  {
    name: 'Freee Foods',
    image: freeeFoods,
    url: 'https://www.freee-foods.co.uk',
  },
  {
    name: 'Nairns Glutten Free',
    image: nairnsGlutenFree,
    url: 'https://nairns.com/wellbeing/gluten-free',
  },
  {
    name: "Nature's Path",
    image: naturesPath,
    url: 'https://naturespath.com/',
  },
  {
    name: 'Old El Paso Glutten Free',
    image: oldElPasoGlutenFree,
    url: 'https://www.oldelpaso.co.uk/products/gluten-free',
  },
  {
    name: 'Warbuttons Gluten Free',
    image: warburtonsGlutenFree,
    url: 'https://www.warburtonsglutenfree.com',
  },
  {
    name: 'White Rabbit Pizza',
    image: whiteRabbitPizza,
    url: 'https://www.whiterabbitpizza.co.uk',
  },
];

const sponsoredProductCats = ['bakery', 'cereal', 'snack'];

/**
 * @type {import('../types').ProductPreferenceCategory}
 */
const ProductPreferenceCategory = {
  Loading: 'loading',
  CrossedGrainCertified: 'crossedgrain_certified',
  Wanted: 'wanted',
  Unwanted: 'unwanted',
  Conflict: 'conflict',
  Unknown: 'unknown',
};

const analyticsEvents = {
  appInstalled: { name: 'app_installed', type: 'interactive' },
  appUninstalled: { name: 'app_uninstalled', type: 'interactive' },
  pageView: { name: 'page_view', type: 'interactive' },
  retailerPageView: { name: 'retailer_page_view', type: 'active' },
  login: { name: 'login', type: 'interactive' },
  signUp: { name: 'sign_up', type: 'interactive' },
  stickerClick: { name: 'sticker_click', type: 'interactive' },
  feedbackButtonClick: { name: 'feedback_button_click', type: 'interactive' },
  recommendationProductClick: {
    name: 'recommendation_product_click',
    type: 'interactive',
  },
  addPreferenceProfile: { name: 'add_preference_profile', type: 'interactive' },
  editPreferenceProfile: {
    name: 'edit_preference_profile',
    type: 'interactive',
  },
  deletePreferenceProfile: {
    name: 'delete_preference_profile',
    type: 'interactive',
  },
  togglePreferenceProfile: {
    name: 'toggle_preference_profile',
    type: 'interactive',
  },
  onboardingDismissed: { name: 'onboarding_dismissed', type: 'interactive' },
  beginCheckout: { name: 'begin_checkout', type: 'active' },
  scanProduct: { name: 'scan_product', type: 'interactive' },
  firstScanProduct: { name: 'first_scan_product', type: 'interactive' },
  errorBoundary: { name: 'error_boundary', type: 'interactive' },
  makePurchase: { name: 'makePurchase', type: 'interactive' },
  shoppingAnalysis: { name: 'shopping_analysis', type: 'interactive' },
  redirectToStore: { name: 'redirect_to_store', type: 'interactive' },
};

const authenticationMethod = {
  email: 'email',
  google: 'google',
  facebook: 'facebook',
};

const supportedRetailers = [
  {
    name: 'asda',
    isSupported: true,
    launchUrl: 'https://groceries.asda.com',
    imageResizeUrl: '?$ProdList2x$=&fmt=webp', // as param
  },
  {
    name: 'tesco',
    isSupported: true,
    launchUrl: 'https://www.tesco.com/groceries/',
    imageResizeUrl: '?h=225&w=225', // as param
  },
  {
    name: 'ocado',
    isSupported: true,
    launchUrl: 'https://www.ocado.com/',
    imageResizeUrl: '280x280', // replace url before image format (*.jpg)
  },
  {
    name: 'sainsburys',
    isSupported: false,
    displayName: "SAINSBURY's",
    launchUrl:
      'https://www.sainsburys.co.uk/webapp/wcs/stores/servlet/gb/groceries',
    imageResizeUrl: '?', // TODO: TBD.
  },
  {
    name: 'amazon',
    isSupported: false,
    launchUrl: 'https://www.amazon.co.uk/b?node=21962024031',
    imageResizeUrl: '?', // TODO: TBD.
  },
];

export const availableReferrals = [
  {
    code: 'coeliacuk',
    name: 'Coeliac UK',
    isSupported: true,
    launchUrl: 'https://coeliac.org.uk/',
  },
];

export const goalTypes = {
  stretch_your_budget: 'stretch-your-budget',
  explore_new_tastes: 'explore-new-tastes',
  achieve_weight_goals: 'achieve-weight-goals',
  boost_athletic_performance: 'boost-athletic-performance',
  manage_health_conditions: 'manage-health-conditions',
  embrace_plant_based_diets: 'embrace-plant-based-diets',
  avoid_allergens: 'avoid-allergens',
  honour_religious_ethical_practices: 'honour-religious-&-ethical-practices',
};

export const goalAllergens = [
  'gluten',
  'dairy',
  'nut',
  'shellfish',
  'eggs',
  'soys',
];

export const availableSessionTimeout = {
  PERSONALITY_TRANSITION: { timeout: false },
};

// TODO: replace with qonversion
export const premiumFeatureRules = {
  TRIAL_DAYS_LIMIT: 7,
};

const foodlamaUrl = 'https://heyfoodlama.com/';

const foodlamaExtensionUrl =
  'https://chrome.google.com/webstore/detail/foodlama/nchihklmcddhbbgbkkopfgblejahnnpo?hl=en&authuser=0';

const retailerCheckoutPageUrls = [
  'https://groceries.asda.com/checkout/confirmation',
];

export {
  dropDownCustomStyles,
  foodFacts,
  sponsoredBrandBanners,
  sponsoredProductCats,
  ProductPreferenceCategory,
  analyticsEvents,
  foodlamaUrl,
  foodlamaExtensionUrl,
  retailerCheckoutPageUrls,
  authenticationMethod,
  supportedRetailers,
};

export * from './recommendation';
export * from './features';

export const recommendationTypes = {
  recommend: 'recommend',
  against: 'against',
};
