import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../slices/app.slice';
import { parentPostMessage } from 'utils/postmessage-module/postMessage';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { path } from 'utils/const';

export function useMinimizeSidebar(productId) {
  const dispatch = useDispatch();

  const productIdFromExtension = useSelector(
    (state) => state.app.productIdFromExtension
  );

  const _productId = productId || productIdFromExtension;
  
  const history = useHistory();

  const minimizeSidebar = useCallback(() => {
    parentPostMessage('MINIMIZE_SIDEBAR_AND_ENABLE_HOVER', '*');
    history.replace(path.dashboard);
    
    // set this global state to keep track of spotlight status
    dispatch(actions.setSpotlightStatus('off'));
    parentPostMessage(
      {
        channel: 'REMOVE_SPOTLIGHT_PRODUCT',
        productId: _productId,
      },
      '*'
    );
  }, [dispatch, _productId, history]);

  return minimizeSidebar;
}
