import { isArray, isBoolean, isEmpty } from 'lodash';

/**
 * check feature is on
 * @param {{
 *   featureKey: string,
 *   featureFlags: any,
 *   me: any,
 *   noAuth: boolean,
 * }} data
 * @returns {boolean}
 */
export const isFeatureOn = ({
  featureKey,
  featureFlags,
  me,
  noAuth = false,
}) => {
  try {
    if (featureFlags === undefined) {
      return false;
    }

    if (!noAuth && me === undefined) {
      return false;
    }

    if (isEmpty(featureFlags)) {
      return false;
    }

    if (!noAuth && isEmpty(me)) {
      return false;
    }

    if (isEmpty(featureKey)) {
      return false;
    }

    // return global feature flags
    if (isBoolean(featureFlags[featureKey])) {
      return featureFlags[featureKey];
    }

    if (me.features === undefined) {
      return false;
    }

    if (!isArray(me.features)) {
      return false;
    }

    return me.features.some((f) => f === featureKey);
  } catch (error) {
    return false;
  }
};
