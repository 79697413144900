import axios from 'axios';
import { isNil, isEmpty, negate } from 'lodash';

import getRetailerNameFromUrl from '../get-retailer-name-from-url';
import getFallbackProductUrl from '../get-fallback-product-url';
import getToken from '../get-token';

import config from '../config';

const isNotNil = negate(isNil);
const isNotEmpty = negate(isEmpty);

const fetchRecommendations = async ({
  retailerCurrentUrl,
  categories,
  profiles,
}) => {

  // turn off fetch recommendations if run on flutter
  if (process.env.REACT_APP_RUN_ON_FLUTTER){
    return []
  }

  const retailerName = getRetailerNameFromUrl(retailerCurrentUrl);

  const hasCategories = isNotNil(categories) && isNotEmpty(categories);

  const hasProfiles = isNotNil(profiles) && isNotEmpty(profiles);

  // Don't ever send network request if required properties missing
  if (isEmpty(retailerName) || !hasCategories || !hasProfiles) {
    return undefined;
  }

  const payload = {
    retailer: {
      name: retailerName,
    },
    categories,
    preferences: profiles.flatMap(({ preferences }) => preferences),
  };

  const token = await getToken();

  return axios
    .post(`${config.endpoint.product}/recommendation`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) =>
      res.data.data.map((p) => ({
        ...p,
        url: p.url || getFallbackProductUrl(retailerName, p.productId),
      }))
    );
};

export default fetchRecommendations;
