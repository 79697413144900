import axios from 'axios';
import { analyticsEvents } from 'constants';
import { Timestamp } from 'firebase/firestore';
import { t } from 'i18next';
import moment from 'moment';
import config from 'utils/config';
import { auth, firestore } from 'utils/firebase';
import getToken from 'utils/get-token';
import logEvent from 'utils/log-event';
import { convertPriceToValidStringPrice } from 'utils/price-formatter';
import { lowUpFirstLetter } from 'utils/string-manipulation-module';

/**
 * @typedef SHOPPING_HISTORY
 * @property {string} date
 * @property {string} id
 * @property {string} retailer
 * @property {string} url
 */

/**
 * @typedef {{
 *  name: string
 *  result: string
 * }} Justification
 */

/**
 * @typedef {{
 *  type:string,
 *  description: string
 *  category: string
 *  justifications: Justification[],
 *  justificationByLLM: string | undefined
 * }} UserPrefMatch
 */

/**
 * Represents the content of a Wraps associated with a processing request.
 * @typedef {Object} WrapsContent
 * @property {string} title - The title of the Wraps content.
 * @property {string} body - The body of the Wraps content.
 * @property {string} icon - The icon associated with the Wraps content.
 */

/**
 * @typedef SHOPPING_HISTORY_ITEM
 * @property {string} productId - The unique identifier of the product.
 * @property {string} productURL - The URL of the product.
 * @property {?string} imageUrl - The URL of the product image. Can be null.
 * @property {?string} name - The name of the product. Can be null.
 * @property {?string} qty - The quantity of the product. Can be null.
 * @property {?string} orderHistoryId - The unique identifier of the order history. Can be null.
 * @property {?UserPrefMatch} userPrefMatch - User preference match information. Can be null.
 * @property {?string[]} categories - Categories associated with the product. Can be null.
 * @property {?string} brand - The brand of the product. Can be null.
 * @property {?number} single_price - The single price of the product. Can be null.
 * @property {string} docsId - The unique identifier of the document.
 * @property {?string} description - The description of the product. Can be null.
 * @property {string} retailer - The retailer associated with the product.
 */

/**
 * @typedef {Object} RequestOrderHistoryAnalysis
 * @property {string} docsId
 * @property {Timestamp} timestamp - The timestamp of the processing request.
 * @property {string} retailer - The retailer associated with the processing request.
 * @property {"PROGRESS" | "FAILED" | "DONE"} status - The status of the processing request, can be "PROGRESS", "FAILED", or "DONE".
 * @property {string} requestID - The unique identifier of the processing request.
 * @property {string} orderId - The unique identifier of the order associated with the processing request.
 * @property {?string[]} preferences - Preferences associated with the processing request. Can be null.
 * @property {?string[]} goals - Goals associated with the processing request. Can be null.
 * @property {?string[]} personalityTraits - Personality traits associated with the processing request. Can be null.
 * @property {?WrapsContent[]} wraps - Wraps content associated with the processing request. Can be null.
 * @property {?Timestamp} process_start - The timestamp when the processing started. Can be null.
 * @property {?Timestamp} process_done - The timestamp when the processing is done. Can be null.
 * @property {?string} promptVersion - The version of the prompt. Can be null.
 * @property {?string} assistantVersion - The version of the assistant. Can be null.
 * @property {?string} error - Error information related to the processing request. Can be null.
 */

/**
 * @typedef {Object} CategoryViewData
 * @property {string} title
 * @property {string} body
 * @property {string} image
 */

/**
 * @typedef {Object} BrandsByCategory
 * @property {string[]} brands
 * @property {string} category
 * @property {string} image
 */

/**
 * @typedef {Object} SpendByCategory
 * @property {number} spend
 * @property {string} category
 * @property {string} image
 */

/**
 * call api to register tasks
 * @param {
 *  requestID: string
 * } param
 */
export async function registerShoppingHistoryAnalysisTask({ requestID }) {
  const token = await getToken();
  const headers = { Authorization: `Bearer ${token}` };
  await axios.post(
    config.endpoint.mobileAPI.concat(`order-analysis/${requestID}`),
    undefined,
    {
      headers,
    }
  );
}

/**
 * getLastDocID
 */
async function getLastDocID() {
  const { currentUser } = auth;

  const lastAnalysisRequest = await firestore
    .collection('shopping_history_analysis')
    .doc(currentUser.uid)
    .collection('request_shopping_history_analysis')
    .orderBy('timestamp', 'desc')
    .limit(1)
    .get();

  let id = 1;
  lastAnalysisRequest.docs.forEach((doc) => {
    try {
      id = Number.parseInt(doc.id.trimStart('0'));
      id += 1;
    } catch (error) {
      console.log(error);
    }
  });
  return `${id}`.padStart(4, '0');
}

/**
 * @param {{
 *  orderId: string
 *  orderHistoryItems: SHOPPING_HISTORY_ITEM[],
 *  orders: SHOPPING_HISTORY[]
 *  retailer: string
 * }} param
 * @returns {Promise<string | null>}
 */
export async function saveSelectedOrderItems({
  orderHistoryItems,
  orders,
  orderId,
  retailer,
}) {
  const requestID = await getLastDocID();
  const { currentUser } = auth;
  try {
    await firestore
      .collection('shopping_history_analysis')
      .doc(currentUser.uid)
      .set({
        uid: currentUser.uid,
        email: currentUser.email,
      });

    const refRequestHistory = firestore
      .collection('shopping_history_analysis')
      .doc(currentUser.uid)
      .collection('request_shopping_history_analysis');

    await refRequestHistory.doc(requestID).set({
      timestamp: Timestamp.now(),
      retailer: retailer,
      status: 'PROGRESS',
      requestID: requestID,
      orderId: orderId,
      wraps: null, // empty field for wraps
      process_start: Timestamp.now(),
      process_done: null, // empty field for saving when the analysis process is done
      preferences: [], // empty field for saving user preferences in backend
      goals: [], // empty field for saving user preferences in backend
      personalityTraits: [],
    });

    // initiate batch container
    const orderHistoryItemBatch = firestore.batch();
    const ordersBatch = firestore.batch();

    orderHistoryItems.map((historyItem) => {
      const orderItemRef = refRequestHistory
        .doc(requestID)
        .collection('order_items')
        .doc();
      orderHistoryItemBatch.set(orderItemRef, historyItem);
      return historyItem;
    });

    orders.map((order) => {
      const orderRef = refRequestHistory
        .doc(requestID)
        .collection('order')
        .doc();
      ordersBatch.set(orderRef, order);
      return order;
    });

    try {
      logEvent(analyticsEvents.shoppingAnalysis, {
        retailer: retailer,
        orderId: orderId,
        requestID: requestID,
      });
    } catch (error) {
      console.log(error);
    }

    // commit the process
    await orderHistoryItemBatch.commit();
    await ordersBatch.commit();
    await registerShoppingHistoryAnalysisTask({
      requestID: requestID,
    });
    return requestID;
  } catch (error) {
    console.log(error);
    // delete registered request if it has any error.
    await firestore
      .collection('shopping_history_analysis')
      .doc(currentUser.uid)
      .collection('request_shopping_history_analysis')
      .doc(requestID)
      .delete();
    return null;
  }
}

/**
 * get shopping history analysis request
 * @param {{
 *  uid: string,
 *  limit: number,
 *  lastHistoryTimestamp: Timestamp | null
 * }} param
 * @returns {Promise<RequestOrderHistoryAnalysis[]>}
 */
export async function getRequestOrderHistoryAnalysis({
  uid,
  limit = 10,
  lastHistoryTimestamp = null,
}) {
  try {
    let orderHistoryAnalysisRef = firestore
      .collection('shopping_history_analysis')
      .doc(uid)
      .collection('request_shopping_history_analysis');

    if (lastHistoryTimestamp !== null) {
      orderHistoryAnalysisRef = orderHistoryAnalysisRef.where(
        'timestamp',
        '<',
        lastHistoryTimestamp
      );
    }

    orderHistoryAnalysisRef = orderHistoryAnalysisRef.orderBy(
      'timestamp',
      'desc'
    );

    const res = [];
    const docs = await orderHistoryAnalysisRef.limit(limit).get();
    docs.forEach((doc) => {
      try {
        const _data = doc.data();
        _data.docsId = doc.id;
        res.push(_data);
      } catch (error) {
        console.log(error);
      }
    });
    return res;
  } catch (error) {
    console.log(error);
    return [];
  }
}

/**
 * Get Analysis Results
 * @param {{
 *  requestID: string
 *  uid: string
 * }} param
 * @returns {Promise<RequestOrderHistoryAnalysis | null>}
 */
export async function getAnalysisResult({ requestID, uid }) {
  let orderHistoryAnalysisRef = firestore
    .collection('shopping_history_analysis')
    .doc(uid)
    .collection('request_shopping_history_analysis')
    .doc(requestID);

  try {
    const analysisData = await orderHistoryAnalysisRef.get();
    return analysisData.data();
  } catch (error) {
    console.log(error);
    return null;
  }
}

/**
 * Get Order Item on Analysis Results
 * @param {{
 *  requestID: string
 *  uid: string
 * }} param
 * @returns {Promise<SHOPPING_HISTORY_ITEM[]>}
 */
export async function getAnalysisOrderItems({ requestID, uid }) {
  /** @type {SHOPPING_HISTORY_ITEM[]} */
  const res = [];

  let orderHistoryAnalysisRef = firestore
    .collection('shopping_history_analysis')
    .doc(uid)
    .collection('request_shopping_history_analysis')
    .doc(requestID)
    .collection('order_items');

  try {
    const orderItems = await orderHistoryAnalysisRef.get();
    orderItems.forEach((od) => {
      res.push(od.data());
    });
    return res;
  } catch (error) {
    console.log(error);
    return [];
  }
}

/**
 * Get Order History Data
 * @param {{
 *  requestID: string
 *  uid: string
 * }} param
 * @returns {Promise<SHOPPING_HISTORY[]>}
 */
export async function getAnalysisOrders({ requestID, uid }) {
  /** @type {SHOPPING_HISTORY[]} */
  let res = [];

  let orderHistoryAnalysisRef = firestore
    .collection('shopping_history_analysis')
    .doc(uid)
    .collection('request_shopping_history_analysis')
    .doc(requestID)
    .collection('order');

  try {
    const orderItems = await orderHistoryAnalysisRef.get();
    orderItems.forEach((od) => {
      res.push(od.data());
    });
    res = res.sort((a, b) => {
      return moment(a.date, 'YYYY-MM-DD').diff(moment(b.date, 'YYYY-MM-DD'));
    });
    return res;
  } catch (error) {
    console.log(error);
    return [];
  }
}

/**
 * get summary analysis to be rendered on analysis results
 * @param {{
 *  requestAnalysis: RequestOrderHistoryAnalysis
 *  products: SHOPPING_HISTORY_ITEM[]
 *  members: Object[]
 *  orders: SHOPPING_HISTORY[]
 * }} param
 * @returns {{
 *  summaryMain: string,
 *  summaryNumber: string[]
 * }}
 */
export function getSummaryAnalysis({
  requestAnalysis,
  products,
  members,
  orders,
}) {
  const isHasPreferences = members?.some((m) => m.isActive);

  let summaryNumber = [];
  if (isHasPreferences) {
    summaryNumber.push(
      t('history.my-analysis.view.analysis-summary.aligned-with-preference', {
        totalItems: products.filter((ap) => ap.userPrefMatch?.type === 'WANTED')
          .length,
      }),
      t(
        'history.my-analysis.view.analysis-summary.not-aligned-with-preference',
        {
          totalItems: products.filter(
            (ap) => ap.userPrefMatch?.type !== 'WANTED'
          ).length,
        }
      )
    );
  } else {
    summaryNumber.push(
      t('history.my-analysis.view.analysis-summary.better-with-goals', {
        totalItems: products.filter(
          (ap) => ap.userPrefMatch?.type === 'GOALS_ANY_BETTER_PRODUCT'
        ).length,
      }),
      t('history.my-analysis.view.analysis-summary.perfect-with-goals', {
        totalItems: products.filter(
          (ap) => ap.userPrefMatch?.type !== 'GOALS_ANY_BETTER_PRODUCT'
        ).length,
      })
    );
  }

  let date = '';
  let startDate = null;
  let endDate = null;

  if (orders.length === 1) {
    startDate = orders[0].date;
  } else if (orders.length > 1) {
    startDate = orders[0].date;
    endDate = orders[orders.length - 1].date;
  }

  if (startDate && endDate) {
    date = ` between ${moment(startDate, 'YYYY-MM-DD').format(
      'MMMM DD, YYYY'
    )} and ${moment(endDate, 'YYYY-MM-DD').format('MMMM DD, YYYY')}`;
  } else if (startDate) {
    date = ` on ${moment(startDate, 'YYYY-MM-DD').format('MMMM DD, YYYY')}`;
  }

  let _totalOrder = orders.length;
  if (_totalOrder === 0) {
    _totalOrder = requestAnalysis.orderId.split(',').length;
  }

  const summaryMain = t('history.my-analysis.view.analysis-summary.body', {
    retailer: lowUpFirstLetter(products[0].retailer),
    date: date,
    totalItemsWithUserPrefMatch: products.filter(
      (p) => p.userPrefMatch !== undefined && p.userPrefMatch !== null
    ).length,
    totalItems: products.length,
    totalOrders:
      _totalOrder === 1
        ? `${_totalOrder} order`
        : `${_totalOrder} different orders`,
  });

  return {
    summaryMain: summaryMain,
    summaryNumber: summaryNumber,
  };
}

/**
 * Get my top purchase
 * @param {SHOPPING_HISTORY_ITEM[]} shoppingItems
 * @returns {CategoryViewData[]}
 */
export function getMyTopPurchase(shoppingItems) {
  try {
    shoppingItems = shoppingItems.sort((item1, item2) => item2.qty - item1.qty);
    /** @type {CategoryViewData[]} */
    const res = shoppingItems.map((item) => {
      return {
        body: t(
          'history.my-analysis.view.highlight.category-body.my-top-purchase',
          {
            qty: item.qty,
          }
        ),
        title: item.name,
        image: item.imageUrl,
      };
    });
    return res;
  } catch (error) {
    return [];
  }
}

/**
 * Get my top brands
 * @param {SHOPPING_HISTORY_ITEM[]} shoppingItems
 * @returns {CategoryViewData[]}
 */
export function getMyTopBrands(shoppingItems) {
  try {
    let itemsByBrands = [];
    shoppingItems.forEach((item) => {
      const idxExists = itemsByBrands.findIndex(
        (ib) => ib.brand === item.brand
      );
      if (idxExists === -1) {
        itemsByBrands.push({
          brand: item.brand,
          qty: item.qty,
          image: item.imageUrl,
        });
      } else {
        itemsByBrands[idxExists].qty += item.qty;
      }
    });

    itemsByBrands = itemsByBrands.sort((item1, item2) => item2.qty - item1.qty);

    /** @type {CategoryViewData[]} */
    const res = itemsByBrands.map((item) => {
      return {
        body: t(
          'history.my-analysis.view.highlight.category-body.my-top-brand-purchase',
          {
            qty: item.qty,
          }
        ),
        title: item.brand,
        image: item.image,
      };
    });
    return res;
  } catch (error) {
    console.log(error);
    return [];
  }
}

/**
 * Get most brands in one category
 * @param {SHOPPING_HISTORY_ITEM[]} shoppingItems
 * @returns {CategoryViewData[]}
 */
export function getMostAdventuresCategory(shoppingItems) {
  try {
    shoppingItems = shoppingItems.filter(
      (item) =>
        item.brand !== undefined && item.brand !== null && item.brand !== ''
    );

    /** @type {BrandsByCategory[]} */
    let brandsByCategory = [];
    shoppingItems.forEach((item) => {
      item.categories.forEach((cat) => {
        const idxExists = brandsByCategory.findIndex(
          (b) => b.category.toLowerCase() === cat.toLowerCase()
        );
        if (idxExists === -1) {
          brandsByCategory.push({
            brands: [item.brand],
            category: cat,
            image: item.imageUrl,
          });
        } else {
          if (
            !brandsByCategory[idxExists].brands.some(
              (b) => b.toLowerCase() === item.brand.toLowerCase()
            )
          ) {
            brandsByCategory[idxExists].brands.push(item.brand);
          }
        }
      });
    });

    brandsByCategory = brandsByCategory
      .sort((item1, item2) => item2.brands.length - item1.brands.length)
      .filter((b) => b.brands.length > 1);

    /** @type {CategoryViewData[]} */
    const res = brandsByCategory.map((item) => {
      return {
        body: t(
          'history.my-analysis.view.highlight.category-body.my-adventurous-category',
          {
            qty: item.brands.length,
          }
        ),
        title: item.category,
        image: item.image,
      };
    });
    return res;
  } catch (error) {
    console.log(error);
    return [];
  }
}

/**
 * Get most spend in one category
 * @param {SHOPPING_HISTORY_ITEM[]} shoppingItems
 * @returns {CategoryViewData[]}
 */
export function getMostSpendByCategory(shoppingItems) {
  try {
    shoppingItems = shoppingItems.filter(
      (item) =>
        item.single_price !== undefined &&
        item.single_price !== null &&
        item.single_price !== 0
    );

    /** @type {SpendByCategory[]} */
    let spendsByCategory = [];
    shoppingItems.forEach((item) => {
      item.categories.forEach((cat) => {
        const idxExists = spendsByCategory.findIndex(
          (b) => b.category.toLowerCase() === cat.toLowerCase()
        );
        if (idxExists === -1) {
          spendsByCategory.push({
            spend: item.single_price * item.qty,
            category: cat,
            image: item.imageUrl,
          });
        } else {
          spendsByCategory[idxExists].spend += item.single_price * item.qty;
        }
      });
    });

    spendsByCategory = spendsByCategory.sort(
      (item1, item2) => item2.spend - item1.spend
    );

    /** @type {CategoryViewData[]} */
    const res = spendsByCategory.map((item) => {
      return {
        body: t(
          'history.my-analysis.view.highlight.category-body.my-top-expenditure',
          {
            totalPrice: convertPriceToValidStringPrice(item.spend),
          }
        ),
        title: item.category,
        image: item.image,
      };
    });
    return res;
  } catch (error) {
    console.log(error);
    return [];
  }
}
