/**
 * get order index url
 * @param {string} retailerName 
 * @returns {string | null}
 */
const getRetailerOrderURL = (retailerName) => {
  if (retailerName === 'ocado') {
    return 'https://www.ocado.com/webshop/displayAllOrders.do';
  }

  if (retailerName === 'tesco') {
    return 'https://www.tesco.com/groceries/en-GB/orders';
  }
  return null;
};

export default getRetailerOrderURL;
