const getFirstNameAndLastName = (name) => {
  let firstName = name.split(' ').slice(0, -1).join(' ');
  let lastName = name.split(' ').slice(-1).join(' ');

  if (firstName === '') {
    firstName = lastName;
  }

  if (firstName === lastName) {
    lastName = '';
  }

  return { firstName, lastName };
};

export default getFirstNameAndLastName;
