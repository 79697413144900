import React from 'react'
import { PropTypes } from 'prop-types'
import './errorBox.scss'
import FontIcon from '../font-icon'
import { Box } from '@chakra-ui/react'

const ErrorBox = ({ children, className, style }) => (
  <Box
    display={'flex'}
    alignItems={'center'}
    className={`error-box-root ${className}`}
    style={style}
  >
    {process.env.REACT_APP_RUN_ON_FLUTTER ? null : (
      <FontIcon name="exclamation-triangle" className="error-box-icon" />
    )}
    {children}
  </Box>
);

ErrorBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  style: PropTypes.shape({}),
}

ErrorBox.defaultProps = {
  children: null,
  className: '',
  style: {},
}

export default ErrorBox
