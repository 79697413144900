/**
 * Get all active profiles and expand `dairy_free` to include `milk_free`.
 *
 * @param {PrefProfile[]} profiles
 * @returns
 */
const parseProfiles = (profiles = []) => {
  try {
    return profiles
      .filter((profile) => profile.isActive)
      .map((profile) => {
        return {
          ...profile,
          preferences: profile.preferences.map((preference) => {
            // Having this here would prevent the duplicate dairy icon
            // While the extension would read the milk_free as dairy_free
            if (preference.name === 'milk_free') {
              return { ...preference, name: 'dairy_free' };
            }
            return preference;
          }),
        };
      });
  } catch (error) {
    return [];
  }
};

export default parseProfiles;
