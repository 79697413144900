import { createSlice } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';
import { isArray, isNil } from 'lodash';
import {
  firestore,
  auth,
  fireAuth,
  emailAuth,
  googleAuth,
  facebookAuth,
} from 'utils/firebase';
import getDetailedProductIfAny from 'utils/get-detailed-product-if-any';
import logEvent from 'utils/log-event';
import {
  analyticsEvents,
  authenticationMethod,
  availableReferrals,
  availableSessionTimeout,
  supportedRetailers,
} from '../constants';
import getRetailerNameFromUrl from '../utils/get-retailer-name-from-url';
import { actions as creatorActions } from 'slices/creators.slice';
import { useSelector } from 'react-redux';
import { removeReferralOnIDB } from 'utils/indexed-db-manager/indexed-db';
import { parentPostMessage } from 'utils/postmessage-module/postMessage';
import { getScanHistoryData } from 'utils/scan-history-module';
import {
  AVATAR_URL_FIREBASE_KEY,
  checkHasAvatarURL,
  generateAvatar,
  updateAvatarURL,
} from 'utils/dicebar-avatar-module/dicebar-avatar-module';
import { getUserName } from 'utils/misc';
import { getRequestOrderHistoryAnalysis } from 'utils/shopping-history/shopping-history-module';
import getToken from 'utils/get-token';
import axios from 'axios';
import config from 'utils/config';

/**
 * @typedef {Object} ScanHistory
 * @property {string[]} available_at
 * @property {string} scan_id
 * @property {string} barcode
 * @property {string} environment
 * @property {string} event_name
 * @property {string} event_type
 * @property {string} found_at
 * @property {boolean} is_found
 * @property {string} main_app_version
 * @property {number} product_id
 * @property {string} product_name
 * @property {string} product_image_url
 * @property {string} product_url
 * @property {Timestamp} timestamp
 * @property {string} user_email
 * @property {string} user_uid
 * @property {function(): Promise<void>} openProductAnalysisPage
 */

/**
 * @typedef {Object} ConnectedRetailerAccounts
 * @property {boolean} asda
 * @property {boolean} tesco
 * @property {boolean} ocado
 */

/**
 * local object key
 */
const localObjectKey = {
  MEMBER: 'MEMBER',
  CONNECTED_RETAILER: 'CONNECTED_RETAILER',
  FIRST_INSTALL: 'FIRST_INSTALL',
};

/**
 * get first install status to fill init value of first install
 */
const getFirstInstallStatus = () => {
  const firstInstallStatusOnLocalStorage = localStorage.getItem(
    localObjectKey.FIRST_INSTALL
  );
  if (firstInstallStatusOnLocalStorage === null) {
    localStorage.setItem(localObjectKey.FIRST_INSTALL, 'true');
    return true;
  }

  if (localStorage.getItem(localObjectKey.FIRST_INSTALL) === 'true') {
    return true;
  }

  return false;
};

// ------------------------------------
// State
// ------------------------------------
const initialState = {
  me: {},
  checked: false,
  loggedIn: false,
  loginType: '',
  productGroups: [],
  // To distinguish between empty and not yet retrieved(from DB).
  members: null,
  randomFoodFact: '',
  randomSponsorBanner: {},
  productIdFromExtension: '',
  preferenceCategoriesFromSticker: [],
  isLoading: false,
  categories: [],
  categoriesRecommendations: [],
  retailerCurrentUrl: '',
  spotlightStatus: 'off',
  cartStates: [],
  onboardingPayload: {},
  isSidebarHidden: true,
  visitedRetailer: '',
  isCreatorsFeatureEnabled: null,
  refCookie: {},
  isShowTransparentOverlayLoader: false,
  isShowOverlayLoader: false,
  productRelatedUseBackButton: false,
  productRelatedBackButtonTitle: 'Back',
  productRelatedShowReScanButton: false,
  // temporary: remove after cuk presentation period is done
  afterReferralUserSignIn: false,
  /**@type {boolean} */
  afterUserTakeGoals: false,
  /**@type {boolean} */
  afterUserTakeQuiz: false,
  /**@type {boolean} */
  isLoadScanHistory: false,
  /**@type {boolean} */
  isScanHistoryHasMoreData: true,
  /**@type {ScanHistory[]} */
  scanHistoryList: [],
  scanProductWithLLM: true,
  /**@type {ConnectedRetailerAccounts} */
  connectedRetailerAccounts: supportedRetailers.reduce((acc, retailer) => {
    if (retailer.isSupported) {
      acc[retailer.name] = false;
    }
    return acc;
  }, {}),
  isFirstInstall: getFirstInstallStatus(),
  featureFlags: {},
  isOpenModalAvatarForm: false,
  isSkipGoalsTransition: false,
  /**@type {number} */
  sessionCount: 0,
  sessionTimeoutApplied: availableSessionTimeout,
  // shopping history analysis start
  isAnalyseRoute: false,
  orderHistoryOrderList: [],
  isFetchOrderHistory: false,
  isFetchOrderHistoryItems: false,
  isFetchOrderHistoryItemsSuccess: false,
  isLoadOrderHistoryAnalysisHistory: false,
  isOrderHistoryAnalysisHistoryHasMoreData: true,
  orderHistoryAnalysisHistory: [],
  // shopping history analysis end
  isShowOverlaySubscription: false,
  isPremiumSubscriber: false,
  premiumPlans: null,
  activePlan: [],
  eligibleForFreeTrials: true,
};

// ------------------------------------
// Slices
// -----------------------------------
const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMe: (state, action) => ({
      ...state,
      me: action.payload.me,
      loggedIn: isNil(action.payload.loggedIn)
        ? state.loggedIn
        : action.payload.loggedIn, // if loggedIn undefined, set previous value
      loginType: isNil(action.payload.loginType)
        ? state.loginType
        : action.payload.loginType, // if loginType undefined, set previous value
      checked: true,
    }),
    setLoggedIn: (state, action) => ({
      ...state,
      loggedIn: action.payload,
    }),
    setAnalyzedProduct: (state, action) => ({
      ...state,
      productGroups: state.productGroups
        .concat([action.payload])
        .filter(getDetailedProductIfAny),
    }),
    flushAnalyzedProducts: (state, action) => ({
      ...state,
      // Prevent product from being flushed when the change is only
      // happened in query string.
      productGroups: state.productGroups.filter(
        (analyzedProduct) =>
          analyzedProduct.origin.split('?')[0] === action.payload.split('?')[0]
      ),
    }),
    setMembers: (state, action) => ({
      ...state,
      members: action.payload,
    }),
    setLoginType: (state, action) => ({
      ...state,
      loginType: action.payload,
    }),
    setRandomFoodFact: (state, action) => ({
      ...state,
      randomFoodFact: action.payload,
    }),
    setRandomSponsorBanner: (state, action) => ({
      ...state,
      randomSponsorBanner: action.payload,
    }),
    setProductId: (state, action) => ({
      ...state,
      productIdFromExtension: action.payload,
    }),
    setPreferenceCategories: (state, action) => ({
      ...state,
      preferenceCategoriesFromSticker: action.payload,
    }),
    setLoading: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
    setCategories: (state, action) => ({
      ...state,
      categories: isNil(action.payload) ? [] : action.payload, // to prevent undefined breaks data type,
    }),
    setCategoriesRecommendations: (state, action) => ({
      ...state,
      categoriesRecommendations: isNil(action.payload) ? [] : action.payload, // to prevent undefined breaks data type
    }),
    setRetailerCurrentUrl: (state, action) => {
      const visitedRetailer = getRetailerNameFromUrl(action.payload);
      return {
        ...state,
        retailerCurrentUrl: action.payload, // to prevent undefined breaks data type
        visitedRetailer: supportedRetailers.some(
          (re) => re.name === visitedRetailer
        )
          ? visitedRetailer // only update if the new value is registered retailer.
          : state.visitedRetailer,
      };
    },
    setSpotlightStatus: (state, action) => ({
      ...state,
      spotlightStatus: action.payload,
      isSidebarHidden: action.payload === 'on' ? false : true,
    }),
    setCartStates: (state, action) => ({
      ...state,
      cartStates: action.payload,
    }),
    setOnboardingPayload: (state, action) => ({
      ...state,
      onboardingPayload: action.payload,
    }),
    setIsSidebarHidden: (state, action) => ({
      ...state,
      spotlightStatus: action.payload ? 'off' : 'on',
      isSidebarHidden: action.payload,
    }),
    setIsCreatorsFeatureEnabled: (state, action) => ({
      ...state,
      isCreatorsFeatureEnabled: action.payload,
    }),
    setRefCookie: (state, action) => ({
      ...state,
      refCookie: action.payload,
    }),
    // Just set part of refCookie Object not totally replace them
    setRefCookieOnly: (state, action) => ({
      ...state,
      refCookie: { ...state.refCookie, ...action.payload },
    }),
    // Just set part of refCookie Object not totally replace them
    clearRefCookie: (state, action) => ({
      ...state,
      refCookie: {},
    }),
    setIsShowTransparentOverlayLoader: (state, actions) => ({
      ...state,
      isShowTransparentOverlayLoader: actions.payload,
    }),
    setIsShowOverlayLoader: (state, actions) => ({
      ...state,
      isShowOverlayLoader: actions.payload,
    }),
    setProductRelatedUseBackButton: (state, actions) => ({
      ...state,
      productRelatedUseBackButton: actions.payload,
    }),
    setProductRelatedBackButtonTitle: (state, actions) => ({
      ...state,
      productRelatedBackButtonTitle: actions.payload,
    }),
    setProductRelatedShowReScanButton: (state, actions) => ({
      ...state,
      productRelatedShowReScanButton: actions.payload,
    }),

    /**
     * temporary: remove after cuk presentation period is done
     */
    setAfterReferralUserSignIn: (state, action) => ({
      ...state,
      afterReferralUserSignIn: action.payload,
    }),

    setAfterUserTakeGoals: (state, action) => ({
      ...state,
      afterUserTakeGoals: action.payload,
    }),
    setAfterUserTakeQuiz: (state, action) => ({
      ...state,
      afterUserTakeQuiz: action.payload,
    }),

    /**
     * Scan History State Start
     */
    setScanHistoryList: (state, action) => ({
      ...state,
      scanHistoryList: action.payload,
    }),
    insertScanHistoryList: (state, action) => ({
      ...state,
      scanHistoryList: [...state.scanHistoryList, ...action.payload],
    }),
    setIsLoadScanHistory: (state, action) => ({
      ...state,
      isLoadScanHistory: action.payload,
    }),
    setIsScanHistoryHasMoreData: (state, action) => ({
      ...state,
      isScanHistoryHasMoreData: action.payload,
    }),
    setScanProductWithLLM: (state, action) => ({
      ...state,
      scanProductWithLLM: action.payload,
    }),
    /**
     * Scan History State End
     */
    setConnectedRetailerAccounts: (state, actions) => ({
      ...state,
      connectedRetailerAccounts: actions.payload,
    }),
    /**
     * set first install status
     */
    setIsFirstInstall: (state, actions) => {
      let installStatus;
      if (actions.payload === 'true' || actions.payload === true) {
        localStorage.setItem(localObjectKey.FIRST_INSTALL, 'true');
        installStatus = true;
      } else {
        localStorage.setItem(localObjectKey.FIRST_INSTALL, 'false');
        installStatus = false;
      }

      // sent post message to flutter if first install status need to be changed
      parentPostMessage({
        channel: 'FIRST_INSTALL_STATUS',
        status: installStatus,
      });

      return {
        ...state,
        isFirstInstall: installStatus,
      };
    },
    setFeatureFlags: (state, actions) => ({
      ...state,
      featureFlags: actions.payload,
    }),
    setIsOpenModalAvatarForm: (state, action) => ({
      ...state,
      isOpenModalAvatarForm: action.payload,
    }),
    setIsSkipGoalsTransition: (state, action) => ({
      ...state,
      isSkipGoalsTransition: action.payload,
    }),
    setSessionCount: (state, action) => ({
      ...state,
      sessionCount: action.payload,
    }),
    setSessionTimeoutApplied: (state, action) => {
      const { key, timeout } = action.payload;
      state.sessionTimeoutApplied[key] = { timeout };
    },
    // shopping history analysis start
    setIsAnalyseRoute: (state, action) => {
      return {
        ...state,
        isAnalyseRoute: action.payload,
      };
    },
    setOrderHistoryOrderList: (state, action) => {
      const orderList = [];
      if (isArray(action.payload)) {
        action.payload.forEach((d) => {
          if (d.url === undefined || d.id === undefined) {
            return;
          }
          orderList.push(d);
        });
      }
      return {
        ...state,
        orderHistoryOrderList: orderList,
      };
    },
    setIsFetchOrderHistory: (state, action) => {
      return {
        ...state,
        isFetchOrderHistory: action.payload,
      };
    },
    setIsFetchOrderHistoryItems: (state, action) => {
      return {
        ...state,
        isFetchOrderHistoryItems: action.payload,
      };
    },
    setIsFetchOrderHistoryItemsSuccess: (state, action) => {
      return {
        ...state,
        isFetchOrderHistoryItemsSuccess: action.payload,
      };
    },
    setIsLoadOrderHistoryAnalysisHistory: (state, action) => ({
      ...state,
      isLoadOrderHistoryAnalysisHistory: action.payload,
    }),
    setIsOrderHistoryAnalysisHistoryHasMoreData: (state, action) => ({
      ...state,
      isOrderHistoryAnalysisHistoryHasMoreData: action.payload,
    }),
    setOrderHistoryAnalysisHistory: (state, action) => ({
      ...state,
      orderHistoryAnalysisHistory: action.payload,
    }),
    insertOrderHistoryAnalysisHistory: (state, action) => ({
      ...state,
      orderHistoryAnalysisHistory: [
        ...state.orderHistoryAnalysisHistory,
        ...action.payload,
      ],
    }),
    // shopping history analysis end
    setIsShowOverlaySubscription: (state, action) => ({
      ...state,
      isShowOverlaySubscription: action.payload,
    }),
    setIsPremiumSubscriber: (state, action) => ({
      ...state,
      isPremiumSubscriber: action.payload,
    }),
    setPremiumPlans: (state, action) => ({
      ...state,
      premiumPlans: action.payload,
    }),
    setActivePlan: (state, action) => ({
      ...state,
      activePlan: action.payload,
    }),
    setEligibleForFreeTrials: (state, actions) => ({
      ...state,
      eligibleForFreeTrials: actions.payload,
    }),
  },
});
// ------------------------------------
// Actions
// -----------------------------------

const getPreferences =
  ({ userId }) =>
  () =>
    new Promise(async (resolve, reject) => {
      try {
        const userPreferences = [];

        const snapshot = await firestore
          .collection('users')
          .doc(userId)
          .collection('members')
          .get();

        snapshot.docs.forEach((doc) => {
          userPreferences.push({ id: doc.id, ...doc.data() });
        });

        if (userPreferences) {
          resolve(userPreferences);
        } else {
          reject(snapshot);
        }
      } catch (err) {
        reject(err);
      }
    });

/**
 * record me object to local storage
 * @param {any} object
 * @param {string} key
 */
const recordLocalObject = (object, key) => {
  try {
    localStorage.setItem(key, JSON.stringify(object));
  } catch (error) {
    console.log(error);
  }
};

/**
 * get me object from local storage
 * @param {string} key
 * @returns {null | any}
 */
const getLocalObject = (key) => {
  try {
    const me = localStorage.getItem(key);
    return JSON.parse(me);
  } catch (error) {
    console.log(error);
    return null;
  }
};

/**
 * @param {string} key
 * remove me object from local storage
 */
const clearLocalObject = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.log(error);
  }
};

export const getFeatureFlag = () => async (dispatch) => {
  try {
    const featureFlags = await firestore
      .collection('feature_flags')
      .limit(1)
      .get();

    const featureFlagsDocs = featureFlags.docs;
    if (featureFlagsDocs.length === 0) {
      return;
    }
    featureFlagsDocs.forEach((flags) => {
      dispatch(slice.actions.setFeatureFlags(flags.data()));
    });
  } catch (error) {
    console.log(error);
  }
};

export const authenticate =
  (forceTriggerUpdate = false, forceModeUser = null) =>
  (dispatch) => {
    return new Promise(async (resolve) => {
      async function triggerAuthStateChange(user) {
        const setMeAndMemberProperties = async () => {
          if (
            process.env.REACT_APP_RUN_ON_FLUTTER &&
            getLocalObject(localObjectKey.MEMBER) != null
          ) {
            dispatch(
              slice.actions.setMembers(getLocalObject(localObjectKey.MEMBER))
            );
          }

          /**
           * read local storage for saved retailer login state
           */
          if (process.env.REACT_APP_RUN_ON_FLUTTER) {
            if (getLocalObject(localObjectKey.CONNECTED_RETAILER) != null) {
              dispatch(
                slice.actions.setConnectedRetailerAccounts(
                  getLocalObject(localObjectKey.CONNECTED_RETAILER)
                )
              );
            } else {
              dispatch(
                slice.actions.setConnectedRetailerAccounts(
                  initialState.connectedRetailerAccounts
                )
              );
              recordLocalObject(
                initialState.connectedRetailerAccounts,
                localObjectKey.CONNECTED_RETAILER
              );
            }
          }

          // get user from firestore
          const userDoc = await firestore
            .collection('users')
            .doc(user.uid)
            .get();

          let userData = userDoc.data();
          if (!checkHasAvatarURL(userData) && userData !== undefined) {
            // default avatar url
            const avatarURL = generateAvatar({
              seedName: getUserName(userDoc.data()),
            });
            await updateAvatarURL({
              avatarURL: avatarURL,
              uid: user.uid,
            });
            userData[AVATAR_URL_FIREBASE_KEY] = avatarURL;
          }

          const mePayload = {
            me: {
              id: user.uid,
              emailVerified: user.emailVerified,
              ...userData,
            },
            loggedIn: user.isAnonymous || !isNil(user.uid) || userDoc.exists,
            loginType: user.isAnonymous ? 'anonymous' : 'registered',
            checked: true,
          };

          dispatch(slice.actions.setMe(mePayload));

          if (mePayload.loggedIn && mePayload.loginType === 'registered') {
            dispatch(actions.setIsFirstInstall(false));
          }

          const memberDoc = await dispatch(
            getPreferences({ userId: user.uid })
          );

          // save member and me object to local
          if (process.env.REACT_APP_RUN_ON_FLUTTER) {
            recordLocalObject(memberDoc, localObjectKey.MEMBER);
          }

          dispatch(slice.actions.setMembers(memberDoc));

          dispatch(creatorActions.fetchRecommendations());
        };

        // Cleanup remaining userdata after logged out
        if (user === null) {
          /**
           * Need to add this function to delete obsolete token from the cache.
           * Because when we call useFirestoreCollectionData, it'll create a cache
           * that store our auth infomation, but didn't cleanup after component unmount.
           * more info on this link: https://github.com/FirebaseExtended/reactfire/discussions/228
           */
          // eslint-disable-next-line no-undef
          const map = globalThis._reactFirePreloadedObservables;

          if (map) {
            Array.from(map.keys()).forEach(
              (key) => key.includes('firestore') && map.delete(key)
            );
          }

          dispatch(slice.actions.setMembers([]));

          dispatch(
            slice.actions.setMe({
              me: {},
              loginType: '',
              loggedIn: false,
              checked: true,
            })
          );
        } else {
          await setMeAndMemberProperties();
        }

        resolve();
      }

      /*
        We need to force the user data updates which the onAuthStateChanged
        doesn't triggered e.g. after user login, and signup.
        By having the forceTriggerUpdate, we need to retrieve the corresponding
        user data from their context instead from the onAuthStateChanged
        so the user data processed are the latest one.
        */
      if (forceTriggerUpdate) {
        await triggerAuthStateChange(forceModeUser);
      } else {
        auth.onAuthStateChanged(async (user) => {
          await triggerAuthStateChange(user);
        });
      }
    });
  };

const signup =
  (
    { name, email, password, referral },
    retailerCurrentUrl,
    clickeranceCurrentUrl
  ) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        /**
         * Links the credential to the currently signed in user.
         *
         * note: do not change this to async-await form, because
         * it will make the conversion process fail.
         */
        /** @type {import('firebase/auth').UserCredential} */
        let userAuth;
        if (!process.env.REACT_APP_RUN_ON_FLUTTER) {
          /**
           * Create the email and password credential, to upgrade
           * the anonymous user.
           */
          if (auth.currentUser === null) {
            await dispatch(loginAnonymously());
          }
          const credential = emailAuth.credential(email, password);
          userAuth = await auth.currentUser.linkWithCredential(credential);
        } else {
          userAuth = await auth.createUserWithEmailAndPassword(email, password);
        }

        const user = userAuth.user;

        // send confirmation email
        // await userCred.user.sendEmailVerification();

        /**
         * After converting process succeed, save user data.
         *
         * note: it we don't save the user data, we can't login into
         * the app.
         */
        const userDocument = await firestore
          .collection('users')
          .doc(user.uid)
          .get();

        // Check whether the user already created in the database.
        if (!userDocument.exists) {
          // store user info in firestore
          let dataUser = { name, email };
          if (referral) dataUser.referral = referral;
          await firestore.collection('users').doc(user.uid).set(dataUser);
        }

        // ignore error
        try {
          await logEvent(analyticsEvents.signUp, {
            method: authenticationMethod.email,
            name,
            signUpEmail: email,
            retailerCurrentUrl,
            clickeranceCurrentUrl,
          });
        } catch (error) {
          console.log(error);
        }

        /**
         * remove referral data after login
         */
        await removeReferralOnIDB('ref_code');
        dispatch(slice.actions.clearRefCookie(null));

        // remove existing sponsored product banner.
        parentPostMessage({ channel: 'REMOVE_SPONSORED_PRODUCT' }, '*');

        /**
         * Since the anonymous -> registered conversion
         * won't call the onAuthStateChanged listener,
         * we need to manually dispatch authenticate
         * or else it wouldn't redirect to dashboard
         */
        await dispatch(authenticate(true, user));

        resolve(user);
      } catch (err) {
        reject(err);
      }
    });
  };

// TODO: Simplify/Merge login function.
/**
 * Add referral when the logged-in user have referral cookie,
 * but in the Firestore there are no referral yet.
 */
const setUserReferral = async (referral, userId) => {
  await firestore
    .collection('users')
    .doc(userId)
    .get()
    .then(async (q) => {
      if (!q.get('referral')) {
        await firestore
          .collection('users')
          .doc(userId)
          .update({ referral: referral });
      }
    });
};
/**
 *
 * @param {} param0
 * @param {} keepLoggedIn
 * @returns Promise<firebase.User>
 */
const loginWithEmailAndPassword =
  (
    { email, password, referral },
    keepLoggedIn,
    retailerCurrentUrl,
    clickeranceCurrentUrl
  ) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        /**
         * Add condition based on keepLoggedIn flag.
         * If keepLoggedIn true, save the login information for a longer time.
         * If keepLoggedIn false, only save the login information until app closed.
         */
        await auth.setPersistence(
          keepLoggedIn
            ? fireAuth.Persistence.LOCAL
            : fireAuth.Persistence.SESSION
        );

        const { user } = await auth.signInWithEmailAndPassword(email, password);

        if (!user) {
          // TODO: More useful message. In this case, we check if the user contains the necessary data?
          reject(new Error('Failed to login. Please try again later.'));
        }

        /**
         * After converting process succeed, save user data.
         *
         * note: it we don't save the user data, we can't login into
         * the app.
         */
        const userDocument = await firestore
          .collection('users')
          .doc(user.uid)
          .get();

        // Check whether the user already created in the database.
        if (!userDocument.exists) {
          // store user info in firestore
          await firestore.collection('users').doc(user.uid).set({
            name: user.displayName,
            email,
          });
        }

        // Check referral cookie
        try {
          if (referral) await setUserReferral(referral, user.uid);
        } catch (error) {
          console.log(error);
        }

        // ignore error on log event
        try {
          await logEvent(analyticsEvents.login, {
            method: authenticationMethod.email,
            loginEmail: user.email,
            clickeranceCurrentUrl,
            retailerCurrentUrl,
          });
        } catch (error) {
          console.log(error);
        }

        /**
         * remove referral data after login
         */
        await removeReferralOnIDB('ref_code');
        dispatch(slice.actions.clearRefCookie(null));

        // remove existing sponsored product banner.
        parentPostMessage({ channel: 'REMOVE_SPONSORED_PRODUCT' }, '*');

        /**
         * Since the anonymous -> registered conversion
         * won't call the onAuthStateChanged listener,
         * we need to manually dispatch authenticate
         * or else it wouldn't redirect to dashboard
         */
        await dispatch(authenticate(true, user));

        resolve(user);
      } catch (err) {
        reject(err);
      }
    });
  };

/**
 * Will directly login with Google account.
 *
 * @param {boolean} keepLoggedIn
 * @returns Promise<firebase.User>
 */
const loginWithGoogle =
  (keepLoggedIn, retailerCurrentUrl, clickeranceCurrentUrl) => (dispatch) =>
    new Promise(async (resolve, reject) => {
      dispatch(actions.setIsShowOverlayLoader(true))
      try {
        // Launch an popup window to choose an available Google account to use.
        const result = await auth.signInWithPopup(googleAuth);

        /**
         * Add condition based on keepLoggedIn flag.
         * If keepLoggedIn true, save the login information for a longer time.
         * If keepLoggedIn false, only save the login information until app closed.
         */
        await auth.setPersistence(
          keepLoggedIn
            ? fireAuth.Persistence.LOCAL
            : fireAuth.Persistence.SESSION
        );

        // The signed-in user info.
        const { user, additionalUserInfo } = result;

        if (!user) {
          reject(new Error('Failed to login. Please try again later.'));
        }

        const userDocument = await firestore
          .collection('users')
          .doc(user.uid)
          .get();

        // Check whether the user already created in the database.
        if (!userDocument.exists) {
          // store user info in firestore
          await firestore.collection('users').doc(user.uid).set({
            name: user.displayName,
            email: user.email,
          });
        }

        // Check referral cookie
        try {
          const { refCookie } = useSelector((state) => state.app);
          if (refCookie && refCookie.isSupported) {
            const referral = {
              code: refCookie.code.toLowerCase().replace(/[^A-Z0-9]+/gi, ''),
              name: refCookie.code,
              isVerified: false,
            };
            try {
              await setUserReferral(referral, user.uid);
            } catch (error) {
              console.log(error);
            }
          }
        } catch (error) {
          console.log(error);
        }

        dispatch(actions.setIsShowOverlayLoader(false))

        // ignore log event
        try {
          logEvent(
            additionalUserInfo.isNewUser
              ? analyticsEvents.signUp
              : analyticsEvents.login,
            {
              method: authenticationMethod.google,
              ...(additionalUserInfo.isNewUser
                ? { signUpEmail: user.email }
                : { loginEmail: user.email }),
              retailerCurrentUrl,
              clickeranceCurrentUrl,
            }
          );
        } catch (error) {
          console.log(error);
        }

        /**
         * remove referral data after login
         */
        await removeReferralOnIDB('ref_code');
        dispatch(slice.actions.clearRefCookie(null));

        // remove existing sponsored product banner.
        parentPostMessage({ channel: 'REMOVE_SPONSORED_PRODUCT' }, '*');

        await dispatch(authenticate(true, user));

        resolve(user);
      } catch (err) {
        // TODO handle error e.g. popup closed.
        reject(err);
      }
    });

/**
 * login with uid, this function exists for flutter
 * @param {string} token
 * @param {boolean} keepLoggedIn
 * @returns
 */
const loginWithCustomToken = (token, keepLoggedIn) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      /**
       * Add condition based on keepLoggedIn flag.
       * If keepLoggedIn true, save the login information for a longer time.
       * If keepLoggedIn false, only save the login information until app closed.
       */
      await auth.setPersistence(
        keepLoggedIn ? fireAuth.Persistence.LOCAL : fireAuth.Persistence.SESSION
      );

      // login with custom token
      const { user } = await auth.signInWithCustomToken(token);

      if (!user) {
        // TODO: More useful message. In this case, we check if the user contains the necessary data?
        reject(new Error('Failed to login. Please try again later.'));
      }

      /**
       * After converting process succeed, save user data.
       *
       * note: it we don't save the user data, we can't login into
       * the app.
       */
      const userDocument = await firestore
        .collection('users')
        .doc(user.uid)
        .get();

      // Check whether the user already created in the database.
      if (!userDocument.exists) {
        // store user info in firestore
        await firestore.collection('users').doc(user.uid).set({
          name: user.displayName,
          email: user.email,
        });
      }

      /**
       * remove referral data after login
       */
      await removeReferralOnIDB('ref_code');
      dispatch(slice.actions.clearRefCookie(null));

      // remove existing sponsored product banner.
      parentPostMessage({ channel: 'REMOVE_SPONSORED_PRODUCT' }, '*');

      /**
       * Since the anonymous -> registered conversion
       * won't call the onAuthStateChanged listener,
       * we need to manually dispatch authenticate
       * or else it wouldn't redirect to dashboard
       */
      await dispatch(authenticate(true, user));

      resolve(user);
    } catch (err) {
      reject(err);
    }
  });
};

/**
 * Will directly login with Facebook account.
 *
 * @param {boolean} keepLoggedIn
 * @returns Promise<firebase.User>
 */
const loginWithFacebook = (keepLoggedIn) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      facebookAuth.setCustomParameters({ display: 'popup' });
      // Launch an popup window to choose an available Facebook account to use.
      const result = await auth.signInWithPopup(facebookAuth);

      /**
       * Add condition based on keepLoggedIn flag.
       * If keepLoggedIn true, save the login information for a longer time.
       * If keepLoggedIn false, only save the login information until app closed.
       */
      await auth.setPersistence(
        keepLoggedIn ? fireAuth.Persistence.LOCAL : fireAuth.Persistence.SESSION
      );

      // The signed-in user info.
      const { user } = result;

      if (!user) {
        reject(new Error('Failed to login. Please try again later.'));
      }

      const userDocument = await firestore
        .collection('users')
        .doc(user.uid)
        .get();

      // Check whether the user already created in the database.
      if (!userDocument.exists) {
        // store user info in firestore
        await firestore.collection('users').doc(user.uid).set({
          name: user.displayName,
          email: user.email,
        });
      }

      try {
        // Check referral cookie
        const { refCookie } = useSelector((state) => state.app);
        if (refCookie && refCookie.isSupported) {
          const referral = {
            code: refCookie.code.toLowerCase().replace(/[^A-Z0-9]+/gi, ''),
            name: refCookie.code,
            isVerified: false,
          };
          try {
            await setUserReferral(referral, user.uid);
          } catch (error) {
            console.log(error);
          }
        }
      } catch (error) {
        console.log(error);
      }

      // dispatch(authenticate());
      resolve(user);
    } catch (err) {
      // TODO handle error e.g. popup closed and 'auth/account-exists-with-different-credential'
      reject(err);
    }
  });

// TODO: Simplify/Merge login function.
const loginAnonymously = () => () =>
  new Promise(async (resolve, reject) => {
    try {
      /**
       * Add condition based on keepLoggedIn flag.
       * If keepLoggedIn true, save the login information for a longer time.
       * If keepLoggedIn false, only save the login information until app closed.
       */
      await auth.setPersistence(fireAuth.Persistence.LOCAL);

      const { user } = await auth.signInAnonymously();

      if (!user) {
        // TODO: More useful message. In this case, we check if the user contains the necessary data?
        reject(new Error('Failed to login. Please try again later.'));
      }

      // dispatch(authenticate());
      resolve(user);
    } catch (err) {
      reject(err);
    }
  });

const logout = () => () =>
  new Promise(async (resolve, reject) => {
    try {
      await auth.signOut();

      clearLocalObject(localObjectKey.MEMBER);
      clearLocalObject(localObjectKey.CONNECTED_RETAILER);

      // add logout message to parent
      parentPostMessage('WEB_APP_LOGOUT');

      // remove existing sponsored product banner.
      parentPostMessage({ channel: 'REMOVE_SPONSORED_PRODUCT' }, '*');

      resolve();
    } catch (err) {
      reject(err);
    }
  });

const resetPassword = (email) => () => auth.sendPasswordResetEmail(email);

const addNewProfile =
  ({ userId, name, strictMode = true, subMemberId, preferences, referral }) =>
  (dispatch) =>
    new Promise(async (resolve, reject) => {
      const { firstName, lastName } = name;

      try {
        await firestore
          .collection('users')
          .doc(userId)
          .collection('members')
          .doc(subMemberId)
          .set({
            first: firstName,
            last: lastName,
            preferences,
            strictMode,
            isActive: true,
          });

        const memberDoc = await dispatch(getPreferences({ userId }));
        dispatch(slice.actions.setMembers(memberDoc));

        resolve();
      } catch (error) {
        reject(error);
      }
    });

const editExistingProfile =
  ({ userId, name, strictMode = true, subMemberId, preferences, isActive }) =>
  (dispatch) =>
    new Promise(async (resolve, reject) => {
      const { firstName, lastName } = name;

      try {
        const existingProfile = await firestore
          .collection('users')
          .doc(userId)
          .collection('members')
          .doc(subMemberId)
          .get();

        if (existingProfile.exists) {
          await firestore
            .collection('users')
            .doc(userId)
            .collection('members')
            .doc(subMemberId)
            .update({
              first: firstName,
              last: lastName,
              preferences,
              strictMode,
              isActive: isActive ?? existingProfile.data().isActive, // use existing value if the data came from edit profile page
            });
        }

        const memberDoc = await dispatch(getPreferences({ userId }));
        dispatch(slice.actions.setMembers(memberDoc));

        resolve();
      } catch (err) {
        reject(err);
      }
    });

const deletePreferences =
  ({ userId, subMemberId }) =>
  (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        await firestore
          .collection('users')
          .doc(userId)
          .collection('members')
          .doc(subMemberId)
          .delete();

        const memberDoc = await dispatch(getPreferences({ userId }));
        dispatch(slice.actions.setMembers(memberDoc));

        resolve();
      } catch (err) {
        reject(err);
      }
    });

const deleteAccountRequest =
  ({ uid, email, reason }) =>
  () =>
    new Promise(async (resolve, reject) => {
      try {
        await firestore.collection('deletion_request').doc(uid).set(
          {
            email: email,
            timestamp: Timestamp.now(),
            reason: reason,
          },
          { merge: true } // to enables the upsert function
        );

        resolve();
      } catch (err) {
        reject(err);
      }
    });

const updateUsername =
  ({ uid, username }) =>
  () =>
    new Promise(async (resolve, reject) => {
      try {
        const token = await getToken();
        const headers = { Authorization: `Bearer ${token}` };
        const payload = { uid: uid, username: username };
        await axios.post(
          config.endpoint.mobileAPI.concat('users/save-username'),
          payload,
          {
            headers,
          }
        );

        resolve();
      } catch (err) {
        reject(err.response.data);
      }
    });

// TODO: obsolete will be deleted soon
const addMember =
  ({ userId, subMemberId, name }) =>
  () =>
    new Promise(async (resolve, reject) => {
      const { firstName, lastName } = name;

      try {
        const newUser = await firestore
          .collection('users')
          .doc(userId)
          .collection('members')
          .doc(subMemberId)
          .set({
            first: firstName,
            last: lastName,
            preferences: [],
          });
        resolve(newUser);
      } catch (err) {
        reject(err);
      }
    });

const postProduct = (productGroup) => (dispatch) => {
  dispatch(slice.actions.setAnalyzedProduct(productGroup));
};

const sendFeedback =
  ({ userId, email, reason, note }) =>
  () =>
    new Promise(async (resolve, reject) => {
      try {
        const feedbacksRes = await firestore
          .collection('feedback')
          .doc(email ?? userId)
          .set({
            id: userId,
            type: 'uninstall_reason',
            feedbacks: { uninstall_reason: reason },
            uninstall_note: note,
            uninstall_date: Timestamp.now(),
          });
        resolve(feedbacksRes);
      } catch (err) {
        reject(err);
      }
    });

const updateIsOnboardingCompletedFlag =
  ({ userId }) =>
  (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        const user = await firestore.collection('users').doc(userId).get();

        if (user.exists) {
          await firestore
            .collection('users')
            .doc(userId)
            .update({ isOnboardingCompleted: true });

          resolve();
        }
      } catch (err) {
        reject(err);
      }
    });

const setRefCookie = () => (dispatch) => {
  const refCookies = document.cookie
    .split('; ')
    .filter((v) => v.includes('ref'))
    .reduce((obj, item) => {
      const [key, value] = item.split('=');
      const formattedKey = key.split('_')[1]; // Extract the key name after "ref_"
      obj[formattedKey] = value;
      obj.isSupported = availableReferrals.some(
        (v) =>
          v.isSupported &&
          v.code === obj?.code?.toLowerCase().replace(/[^A-Z0-9]+/gi, '')
      );
      return obj;
    }, {});
  dispatch(slice.actions.setRefCookie(refCookies));
};

/**
 * reset scan history screen state
 * @returns {void}
 */
const resetStateScanHistory = () => (dispatch) => {
  dispatch(slice.actions.setScanHistoryList([]));
  dispatch(slice.actions.setIsLoadScanHistory(false));
  dispatch(slice.actions.setIsScanHistoryHasMoreData(true));
};

/**
 * @param {number} limit
 * @param {Timestamp} lastHistoryTimestamp
 * @returns {void}
 */
const loadScanHistoryList =
  (limit, lastHistoryTimestamp = null) =>
  async (dispatch) => {
    dispatch(slice.actions.setIsLoadScanHistory(true));

    const { currentUser } = auth;
    const data = await getScanHistoryData({
      emailOrUID: currentUser.email ?? currentUser.uid,
      limit: limit,
      lastHistoryTimestamp: lastHistoryTimestamp,
    });

    if (lastHistoryTimestamp === null) {
      dispatch(slice.actions.setScanHistoryList(data));
    } else {
      dispatch(slice.actions.insertScanHistoryList(data));
    }

    if (data.length === 0) {
      dispatch(slice.actions.setIsScanHistoryHasMoreData(false));
    }

    dispatch(slice.actions.setIsLoadScanHistory(false));
  };

/**
 * reset shopping history screen state
 * @returns {void}
 */
const resetStateOrderHistoryAnalysisHistory = () => (dispatch) => {
  dispatch(slice.actions.setOrderHistoryAnalysisHistory([]));
  dispatch(slice.actions.setIsLoadOrderHistoryAnalysisHistory(false));
  dispatch(slice.actions.setIsOrderHistoryAnalysisHistoryHasMoreData(true));
};

/**
 * @param {number} limit
 * @param {Timestamp} lastHistoryTimestamp
 * @returns {void}
 */
const loadOrderHistoryAnalysisHistoryList =
  (limit, lastHistoryTimestamp = null) =>
  async (dispatch) => {
    dispatch(slice.actions.setIsLoadOrderHistoryAnalysisHistory(true));

    const { currentUser } = auth;
    const data = await getRequestOrderHistoryAnalysis({
      lastHistoryTimestamp: lastHistoryTimestamp,
      limit: limit,
      uid: currentUser.uid,
    });

    if (lastHistoryTimestamp === null) {
      dispatch(slice.actions.setOrderHistoryAnalysisHistory(data));
    } else {
      dispatch(slice.actions.insertOrderHistoryAnalysisHistory(data));
    }

    if (data.length === 0) {
      dispatch(
        slice.actions.setIsOrderHistoryAnalysisHistoryHasMoreData(false)
      );
    }

    dispatch(slice.actions.setIsLoadOrderHistoryAnalysisHistory(false));
  };

/**
 * Set retailer account login state
 * @param {ConnectedRetailerAccounts} loginStates
 * @param {boolean} status
 * @returns
 */
const setRetailerAccountsLoginState = (loginStates) => async (dispatch) => {
  dispatch(actions.setConnectedRetailerAccounts(loginStates));
  recordLocalObject(loginStates, localObjectKey.CONNECTED_RETAILER);
};

/**
 * get user object
 * @param {string} uid
 * @returns {any}
 */
const getMeObject = async (uid) => {
  const userDoc = await firestore.collection('users').doc(uid).get();
  return userDoc;
};

/**
 * set fcm tokens
 * @param {{
 *  uid: string,
 *  deviceID: string,
 *  FCMToken: string
 * }} param0
 */
const setFCMTokens = async ({ uid, deviceID, FCMToken }) => {
  try {
    const fcmDocRef = firestore
      .collection('users')
      .doc(uid)
      .collection('fcmTokens');
    await fcmDocRef.doc(deviceID).set({
      token: FCMToken,
    });
  } catch (error) {
    console.log(error);
  }
};

// ------------------------------------
// Exports
// ------------------------------------

export const actions = {
  ...slice.actions,
  authenticate,
  signup,
  loginWithEmailAndPassword,
  loginWithGoogle,
  loginWithFacebook,
  loginAnonymously,
  loginWithCustomToken,
  logout,
  resetPassword,
  getPreferences,
  postProduct,
  addNewProfile,
  editExistingProfile,
  deletePreferences,
  deleteAccountRequest,
  updateUsername,
  addMember,
  sendFeedback,
  updateIsOnboardingCompletedFlag,
  setRefCookie,
  loadScanHistoryList,
  resetStateScanHistory,
  loadOrderHistoryAnalysisHistoryList,
  resetStateOrderHistoryAnalysisHistory,
  setRetailerAccountsLoginState,
  getMeObject,
  getFeatureFlag,
  getFirstInstallStatus,
  setFCMTokens,
};

export default slice.reducer;
