import { sum } from 'lodash';

/**
 * This is a new scoring formula that we use to get product recommendations
 * that are completely identical to the current category.
 *
 * You can find more info about this formula on this wiki:
 * https://gitlab.com/clickerance/clickerance-extension/-/wikis/feature/recommendation
 */
const scoreProduct = ({
  product: { flags, categories: productCategories = [] },
  preferences,
  categories,
}) => {
  const violatedPreferences = preferences.reduce((acc, curr) => {
    if (flags.some((flag) => flag.name === curr.name && flag.value > 0)) {
      return acc + 1;
    }
    return acc;
  }, 0);

  const certaintyDegreeValuesFromViolatedPreferences = flags.reduce(
    (acc, curr) => {
      if (
        preferences.some(
          (preference) => preference.name === curr.name && curr.value > 0
        )
      ) {
        return acc + curr.value;
      }
      return acc;
    },
    0
  );

  const unmatchCategories = productCategories.reduce((acc, curr) => {
    if (!categories.some((cat) => cat === curr)) {
      return acc + 1;
    }

    return acc;
  }, 0);

  // ref.: https://gitlab.com/clickerance/clickerance-extension/-/wikis/Feature/recommendations#recommendation-scoring
  const scoreA = violatedPreferences * 15;
  const scoreB = certaintyDegreeValuesFromViolatedPreferences * 10;
  const scoreC = unmatchCategories * 5;

  return {
    totalScore: sum([scoreA, scoreB, scoreC]),
    scoreA,
    scoreB,
    scoreC,
    violatedPreferences,
  };
};

export default scoreProduct;
