import { last } from 'lodash';

const asdaTransformer = {
  extractIdFromUrl: (url) => {
    const arrUrl = url.split('?');
    const hasQueryString = arrUrl.length > 1;

    if (hasQueryString) {
      return arrUrl[0].split('/').pop();
    }

    return url.split('/').pop();
  },
};

const tescoTransformer = {
  extractIdFromUrl: (url) => {
    const nums = url.match(/([\d]+)/g);

    return nums[0];
  },
};

const ocadoTransformer = {
  extractIdFromUrl: (url) => {
    return last(
      url
        // Split from query string
        .split('?')[0] // Use the first element which is the main url
        .split(/-|\//)
    );
  },
};
const transformers = {
  asda: asdaTransformer,
  tesco: tescoTransformer,
  ocado: ocadoTransformer,
};

export function getProductIdFromUrl({ url, retailer }) {
  try {
    const transformer = transformers[retailer];

    if (!transformer) {
      throw new Error(`Transformer for retailer ${retailer} is not found`);
    }

    return transformer.extractIdFromUrl(url);
  } catch (error) {
    return '';
  }
}
