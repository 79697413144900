/* eslint-disable no-console */
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { isArray, isEmpty, isNil, negate } from 'lodash';
import { useQueryClient } from 'react-query';
import { path } from 'utils/const';
import { actions } from 'slices/app.slice';
import { actions as creatorActions } from 'slices/creators.slice';
import useRecommendations from 'hooks/use-recommendations';
import usePrevious from 'hooks/use-previous';
import postProduct from 'utils/post-product';
import parseProfiles from 'utils/get-active-profiles';
import getRetailerNameFromUrl from 'utils/get-retailer-name-from-url';
import getCategoriesIdsFromUrl from 'utils/get-categories-ids-from-url';
import transformCategoriesIdsIntoCategoriesNames from 'utils/transform-categories-ids-into-categories-names';
import fetchRecommendations from 'utils/query-functions/fetch-recommendations';
import deduplicatePreferences from 'utils/deduplicate-preferences';
import isArrayEqual from 'utils/is-array-equal';
import getMostOccuringCategories from 'utils/get-most-occuring-categories';
import { t } from 'i18next';

import logEvent from 'utils/log-event';
import {
  analyticsEvents,
  features,
  sponsoredBrandBanners,
  sponsoredProductCats,
  supportedRetailers,
} from '../../constants';
import { useProductStream } from '../../hooks/use-product-stream';
import { isFeatureAvailable } from 'utils';
import { useCreatorRecommendations, useMinimizeSidebar } from '../../hooks';
import fetchSponsoredProductData from 'utils/query-functions/fetch-sponsored-product';
import getProductCategories from 'utils/query-functions/get-product-categories';
import { checkCUKReferralOnMeObject } from 'utils/cuk-module/cuk-module';
import { parentPostMessage } from 'utils/postmessage-module/postMessage';
import getToken from 'utils/get-token';
import { auth } from 'utils/firebase';
import { useToast } from '@chakra-ui/react';
import {
  getScanProductCounter,
  openProductAnalysisWithBarcodeOrUrl,
  updateScanProductCounter,
} from 'utils/fetch-product-module';
import { checkUserFilledGoals } from 'utils/questionnaire-module/questionnaire-module';
import { windowGlobalVar } from 'utils/window-var';
import {
  generateNextScanID,
  getExistingScanIDs,
} from 'utils/scan-history-module';
import getRetailerOrderURL from 'utils/get-retailer-order-index-url';
import { saveSelectedOrderItems } from 'utils/shopping-history/shopping-history-module';
import {
  SUBSCRIPTION_FEATURE_FLAG,
  recordPurchaseEvent,
} from 'utils/subscription/subscription-module';
import { isFeatureOn } from 'utils/feature-flags/feature-flags-module';

const isNotNil = negate(isNil);
const isNotEmpty = negate(isEmpty);
const isBlank = (v) => isEmpty(v) || isNil(v);

export default function BackgroundService(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search: locationSearch } = useLocation();

  /**
   * @type {{
   *   members: import('../../types').PreferenceProfile[],
   *   loggedIn: boolean,
   *   loggedIn: string,
   *   productGroups: import('../../types').ProductGroup[],
   *   analyzedProduct: import('../../types').Product,
   *   productIdFromExtension: string,
   *   preferenceCategoriesFromSticker: string[],
   *   sponsoredProduct: import('../../types').Product,
   * }}
   */
  const {
    checked,
    isSidebarHidden,
    loggedIn,
    loginType,
    me,
    members,
    productGroups,
    productIdFromExtension,
    retailerCurrentUrl,
    spotlightStatus,
    isCreatorsFeatureEnabled,
    randomSponsorBanner,
    connectedRetailerAccounts,
    isPremiumSubscriber,
    featureFlags,
  } = useSelector((state) => state.app);

  const [firstSession, setFirstSession] = useState(null);
  const [isNudgeDisplayed, setIsNudgeDisplayed] = useState(null);
  const [
    firstPreferencesReminderHasDisplayed,
    setFirstPreferencesReminderHasDisplayed,
  ] = useState(null);
  const [
    lastTimeNotificationHasDisplayed,
    setLastTimeNotificationHasDisplayed,
  ] = useState(null);
  const [lastNotificationDisplayedType, setLastNotificationDisplayedType] =
    useState(null);
  const [userHasInteracted, setUserHasInteracted] = useState(null);
  const [isWebAppReady, setIsWebAppReady] = useState(false);
  const [viewingProductId, setViewingProductId] = useState('');

  const minimizeSidebar = useMinimizeSidebar();

  useProductStream();

  const { recommendations: creatorRecommendations, recommendationsStatus } =
    useSelector((state) => state.creators);

  // States for sponsored product banner.
  const [sponsoredProductCategories, setSponsoredProductCategories] =
    useState();
  const [sponsoredProduct, setSponsoredProduct] = useState({});

  const toast = useToast();

  // To fetch product categories for sponsored product.
  const fetchSponsoredProductCategories = async () => {
    // set matched category url as the categories default value
    let categories = sponsoredProductCats.find((cat) =>
      retailerCurrentUrl.includes(cat)
    );

    if (isNotEmpty(productGroups)) {
      const analyzedCategories = await getProductCategories(
        retailerCurrentUrl,
        productGroups[0].analyzedProduct
      );

      if (analyzedCategories.length > 0) {
        const isMatchedCategory = analyzedCategories.some((category) =>
          sponsoredProductCats.some((available) =>
            category.toLowerCase().includes(available.toLowerCase())
          )
        );

        // replace the categories value when there's a matched analyzed product category
        if (isMatchedCategory) {
          categories = encodeURIComponent(analyzedCategories.join(','));
        }
      }
    }

    setSponsoredProductCategories(categories);
  };

  // To fetch sponsored product.
  const fetchSponsoredProduct = async () => {
    let resSponsoredData = {};
    if (sponsoredProductCategories !== undefined) {
      resSponsoredData = await fetchSponsoredProductData(
        retailerCurrentUrl,
        sponsoredProductCategories
      );
    }

    setSponsoredProduct(resSponsoredData);
  };

  // Effect for fetching the product categories.
  useEffect(() => {
    if (checkCUKReferralOnMeObject(me)) fetchSponsoredProductCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailerCurrentUrl, productGroups, me]);

  // Effect for fetching the sponsored product.
  useEffect(() => {
    if (checkCUKReferralOnMeObject(me)) fetchSponsoredProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sponsoredProductCategories, me]);

  // Effect for send sponsored product message to the extension.
  useEffect(() => {
    // remove existing sponsored product before injecting the new one.
    parentPostMessage({ channel: 'REMOVE_SPONSORED_PRODUCT' }, '*');
    parentPostMessage(
      {
        channel: 'SPONSORED_READY',
        sponsoredProduct: sponsoredProduct,
        isSponsored:
          checkCUKReferralOnMeObject(me) &&
          Object.keys(sponsoredProduct).length > 0,
      },
      '*'
    );
  }, [sponsoredProduct, me]);

  /**
   * handle to register login with CustomToken
   */
  useEffect(() => {
    /**
     * @param {string} token
     */
    window[windowGlobalVar.LOGIN_WITH_CUSTOM_TOKEN] = async (token) => {
      dispatch(actions.setIsShowTransparentOverlayLoader(true));
      try {
        await dispatch(actions.loginWithCustomToken(token, true));
      } catch (error) {
        toast({
          title: 'Failed to login. Please try again later.',
          status: 'error',
          position: 'top',
          isClosable: true,
          variant: 'subtle',
        });
      } finally {
        dispatch(actions.setIsShowTransparentOverlayLoader(false));
      }
    };

    /**
     * open product analysis page with barcode
     * @param {{
     * barcode: string,
     * showReScanButton: boolean,
     * isUsingBackButton: boolean,
     * backButtonTitle: string,
     * saveToGA: boolean}} param
     */
    window[windowGlobalVar.OPEN_PRODUCT_ANALYSIS_BY_BARCODE] = async ({
      barcode,
      showReScanButton = false,
      isUsingBackButton = false,
      backButtonTitle = 'Back',
      saveToGA = true,
    }) => {
      if (members.length === 0) {
        toast({
          title: 'Please set your preferences first.',
          status: 'error',
          position: 'top',
          isClosable: true,
          variant: 'subtle',
        });
        return;
      }

      dispatch(actions.setIsShowOverlayLoader(true));

      const scanID = generateNextScanID(
        await getExistingScanIDs({ emailOrUID: me.email ?? me.uid })
      );

      await openProductAnalysisWithBarcodeOrUrl(
        scanID,
        barcode,
        null,
        isUsingBackButton,
        backButtonTitle,
        saveToGA,
        showReScanButton
      );

      dispatch(actions.setIsShowOverlayLoader(false));
    };
  }, [dispatch, toast, members, me]);

  useEffect(() => {
    // additional function to get user session
    window[windowGlobalVar.GET_USER_SESSION] = async () => {
      let profiles = [];
      try {
        profiles = parseProfiles(members);
      } catch (error) {
        profiles = [];
      }
      let token = '';
      try {
        token = await getToken();
      } catch (error) {
        token = '';
      }
      return {
        loggedIn,
        me: me,
        members: profiles,
        authToken: token,
        userId: auth.currentUser.uid,
        loginType,
      };
    };

    if (loggedIn && loginType === 'registered' && !isEmpty(me)) {
      parentPostMessage('WEBAPP_LOGGED_IN');
    }
  }, [members, loggedIn, loginType, me]);

  /**
   * After completing the user questionnaire,
   * if the users already set their preferences,
   * it will redirect to the Dashboard Page directly,
   * if not it will redirect the users to the Preference Page.
   */
  useEffect(() => {
    if (process.env.REACT_APP_RUN_ON_FLUTTER) {
      window[windowGlobalVar.REDIRECT_USER_AFTER_GOALS] = async () => {
        if (!me && !me.id) {
          return;
        }

        if (!loggedIn || loginType === 'anonymous') {
          return;
        }

        // add loader screen
        dispatch(actions.setIsShowOverlayLoader(true));

        // update me object after user take questionnaire
        const updatedMeObject = await actions.getMeObject(me.id);
        if (!updatedMeObject.id) {
          dispatch(actions.setIsShowOverlayLoader(false));
          return;
        }

        // update me object after re-fetch user data from fire store
        dispatch(
          actions.setMe({
            me: {
              id: updatedMeObject.id,
              emailVerified: me.emailVerified,
              ...updatedMeObject.data(),
            },
          })
        );

        // remove loader screen
        dispatch(actions.setIsShowOverlayLoader(false));

        if (checkUserFilledGoals(loggedIn, loginType, updatedMeObject.data())) {
          dispatch(actions.setAfterUserTakeGoals(true));

          history.push(
            !isBlank(members) &&
              !isBlank(members.filter((member) => member.isActive))
              ? path.dashboard
              : path.addPreferences
          );
        }
      };

      window[windowGlobalVar.REDIRECT_USER_AFTER_QUIZ] = async () => {
        dispatch(actions.setIsShowOverlayLoader(true));
        // update me object after user take questionnaire
        const updatedMeObject = await actions.getMeObject(me.id);
        if (!updatedMeObject.id) {
          dispatch(actions.setIsShowOverlayLoader(false));
          return;
        }

        // update me object after re-fetch user data from fire store
        dispatch(
          actions.setMe({
            me: {
              id: updatedMeObject.id,
              emailVerified: me.emailVerified,
              ...updatedMeObject.data(),
            },
          })
        );

        // remove loader screen
        dispatch(actions.setIsShowOverlayLoader(false));
      };
    }
  }, [dispatch, loggedIn, loginType, history, members, me]);

  // Send ready status once the app is ready and user is authenticated.
  useEffect(() => {
    // check if members is required
    // in mobile app members is not mandatory
    const membersCheck = () => {
      if (process.env.REACT_APP_RUN_ON_FLUTTER) {
        return true;
      }
      return !isBlank(members);
    };

    if (!isBlank(me) && membersCheck && recommendationsStatus === 'success') {
      setIsWebAppReady(true);

      /**
       * Add listener for extension ready
       * this for flutter flow
       */
      window.addEventListener('message', (event) => {
        if (event.data === 'EXTENSION_READY') {
          parentPostMessage(
            {
              channel: 'WEBAPP_READY',
              loggedIn,
              referral: me.referral,
              members: parseProfiles(members),
              spotlightStatus,
            },
            '*'
          );
        }
      });

      /**
       * add event listener to send user session data
       */
      window.addEventListener('message', async (event) => {
        if (event.data === 'GET_USER_SESSION') {
          const dataSession = {
            channel: 'USER_SESSION',
            loggedIn,
            referral: me.referral,
            members: parseProfiles(members),
            authToken: await getToken(),
            userId: auth.currentUser.uid,
          };
          parentPostMessage(dataSession, '*');
        }

        if (event.data === 'BACK_TO_DASHBOARD') {
          minimizeSidebar();
          history.replace(path.dashboard);
        }
      });

      // only send status when members or loggedIn status change
      parentPostMessage(
        {
          channel: 'WEBAPP_READY',
          loggedIn,
          referral: me.referral,
          members: parseProfiles(members),
          spotlightStatus,
        },
        '*'
      );
    }
  }, [
    creatorRecommendations,
    loggedIn,
    me,
    members,
    recommendationsStatus,
    spotlightStatus,
    history,
    minimizeSidebar,
  ]);

  useEffect(() => {
    /**
     * Forward empty labels when we log out from the app.
     * So that all labels will be deleted.
     */
    if (checked && !loggedIn) {
      productGroups.forEach((productGroup) => {
        const message = {
          channel: 'PROCESS_PRODUCT',
          analyzedProduct: productGroup.analyzedProduct,
          members: [],
          creatorMode: {
            isCreator: false,
            isProductAlreadyRecommended: false,
          },
        };

        parentPostMessage(message, '*');
      });
    }
  }, [checked, loggedIn, productGroups]);

  useEffect(() => {
    // Remove the persisted user related data on chrome storage after logout.
    if (checked && !loggedIn) {
      dispatch(creatorActions.fetchRecommendations());

      const message = {
        channel: 'CLEAR_USER_RELATED_STORAGE',
      };
      parentPostMessage(message, '*');
    }
  }, [loggedIn, checked, dispatch]);

  /**
   * Re-label product when there's a change in the profiles.
   *
   * TODO: Optimize, forward product that needed to be relabeled
   */
  useEffect(() => {
    const activeMembers = !members ? [] : parseProfiles(members);

    if (isNotEmpty(productGroups) && recommendationsStatus === 'success') {
      productGroups.forEach((productGroup) => {
        try {
          const currentRecData = creatorRecommendations.find(
            (product) =>
              product.productId === productGroup.analyzedProduct.productId
          );
          const isProductAlreadyRecommended =
            !isBlank(currentRecData?.note) || !isBlank(currentRecData?.tags);
          const message = {
            channel: 'PROCESS_PRODUCT',
            analyzedProduct: productGroup.analyzedProduct,
            members: activeMembers,
            creatorMode: {
              isCreator: isCreatorsFeatureEnabled,
              isProductAlreadyRecommended,
            },
          };

          parentPostMessage(message, '*');
        } catch (error) {
          console.log(error);
        }
      });
    }
  }, [
    members,
    productGroups,
    me,
    creatorRecommendations,
    recommendationsStatus,
    isCreatorsFeatureEnabled,
  ]);

  /**
   * Flush product and minimize sidebar when url change.
   * Since other process need the retailer URL, we'll save it in redux
   */
  useEffect(() => {
    const retailerUrlChangeEventHandler = (event) => {
      if (event.data?.channel === 'RETAILER_URL_CHANGE') {
        const { newRetailerUrl, mainAppEventData } = event.data;

        if (newRetailerUrl !== retailerCurrentUrl) {
          if (
            mainAppEventData.channel !== 'WEBAPP_READY' &&
            [newRetailerUrl, retailerCurrentUrl].every(negate(isBlank))
          ) {
            dispatch(actions.setSpotlightStatus('off'));
            parentPostMessage('MINIMIZE_SIDEBAR', '*');
          }

          dispatch(actions.setRetailerCurrentUrl(newRetailerUrl));
          dispatch(actions.flushAnalyzedProducts(newRetailerUrl));
        }
      }
    };

    window.addEventListener('message', retailerUrlChangeEventHandler);

    return function cleanup() {
      window.removeEventListener('message', retailerUrlChangeEventHandler);
    };
  }, [dispatch, retailerCurrentUrl]);

  // Watch productId that come from sticker click
  useEffect(() => {
    function listenStickerClick(event) {
      if (
        event.data?.channel === 'STICKER_CLICK' &&
        isNotNil(event.data?.productId)
      ) {
        const {
          productId,
          preferenceCategories,
          retailerURL,
          isUsingBackButton,
          backButtonTitle,
          showReScanButton,
        } = event.data;

        if (process.env.REACT_APP_RUN_ON_FLUTTER && retailerURL) {
          dispatch(actions.setRetailerCurrentUrl(retailerURL));
        }

        setViewingProductId(productId);
        dispatch(actions.setProductId(productId));

        if (isNotNil(preferenceCategories)) {
          /**
           * We need to store preferenceCategories to render text
           * below the product name section
           */
          dispatch(actions.setPreferenceCategories(preferenceCategories));
        }

        /**
         * don't check creator feature
         **/
        // if (isCreatorsFeatureEnabled) {
        //   history.replace(path.manageRecommendation);
        // } else {
        //   history.replace(path.productRelated);
        // }

        if (showReScanButton) {
          dispatch(actions.setProductRelatedShowReScanButton(true));
        } else {
          dispatch(actions.setProductRelatedShowReScanButton(false));
        }

        if (isUsingBackButton) {
          dispatch(actions.setProductRelatedUseBackButton(true));
          dispatch(
            actions.setProductRelatedBackButtonTitle(backButtonTitle ?? 'Back')
          );
          history.push(path.productRelated);
        } else {
          dispatch(actions.setProductRelatedUseBackButton(false));
          dispatch(actions.setProductRelatedBackButtonTitle(''));
          history.replace(path.productRelated);
        }

        dispatch(actions.setSpotlightStatus('on'));

        parentPostMessage('EXPAND_SIDEBAR', '*');
      }
    }

    window.addEventListener('message', listenStickerClick);

    return () => window.removeEventListener('message', listenStickerClick);
  }, [dispatch, history]);

  useEffect(() => {
    const sidebarMinimizedEventListener = (event) => {
      if (event.data === 'SIDEBAR_MINIMIZED') {
        dispatch(actions.setPreferenceCategories([]));
      }
    };

    window.addEventListener('message', sidebarMinimizedEventListener);

    return function cleanup() {
      window.removeEventListener('message', sidebarMinimizedEventListener);
    };
  }, [dispatch]);

  const retailerPreviousUrl = usePrevious(retailerCurrentUrl) ?? '';

  useEffect(() => {
    const isOpenedInIframe = window.self !== window.top;
    const clickeranceCurrentUrl = window.location.href;

    // Don't send history when user already logout from the app
    if (
      // process.env.NODE_ENV === 'production' &&
      isOpenedInIframe &&
      loggedIn &&
      retailerCurrentUrl !== '' &&
      clickeranceCurrentUrl !== ''
    ) {
      logEvent(analyticsEvents.retailerPageView, {
        retailerPreviousUrl,
        retailerCurrentUrl,
        clickeranceCurrentUrl,
      });
    }
  }, [loggedIn, retailerCurrentUrl, retailerPreviousUrl]);

  useEffect(() => {
    const _randSponsoredBrand = () => {
      return sponsoredBrandBanners[
        Math.floor(Math.random() * sponsoredBrandBanners.length)
      ];
    };

    const sidebarButtonClickedEventListener = (event) => {
      if (event.data === 'SIDEBAR_BUTTON_CLICKED') {
        history.push(path.dashboard);

        /**
         * random sponsored brand data for the extension
         * loaded every time the user click the sidebar button,
         * and re-randomized it when the current data on redux
         * is equal with the randomized data.
         */
        const randSponsoredBrand = _randSponsoredBrand();
        if (randomSponsorBanner === randSponsoredBrand) _randSponsoredBrand();
        dispatch(actions.setRandomSponsorBanner(randSponsoredBrand));
      }
    };

    window.addEventListener('message', sidebarButtonClickedEventListener);

    return function cleanup() {
      window.removeEventListener('message', sidebarButtonClickedEventListener);
    };
  }, [dispatch, history, me, retailerCurrentUrl, randomSponsorBanner]);

  const [categories, setCategories] = useState([]);

  /**
   * Finding categories from the selected/highlighted product
   */
  useEffect(() => {
    async function updateCats() {
      if (
        isNotEmpty(retailerCurrentUrl) &&
        isNotEmpty(productIdFromExtension)
      ) {
        const payload = {
          retailer: { name: getRetailerNameFromUrl(retailerCurrentUrl) },
          product: { productId: productIdFromExtension },
        };

        const productResult = await postProduct(payload);

        if (productResult.data?.data && productResult.data.data?.categories) {
          setCategories(productResult.data.data.categories);
        } else {
          // set default category
          const defaultCategory = ['Food Cupboard', 'Bakery', 'Frozen Food'];
          const randomIndexCategory = Math.floor(Math.random() * 3);
          setCategories([defaultCategory[randomIndexCategory]]);
        }
      }
    }
    updateCats();
    // eslint-disable-next-line
  }, [retailerCurrentUrl, productIdFromExtension]);

  const [categoriesIds, setCategoriesIds] = useState([]);

  useEffect(() => {
    setCategoriesIds(getCategoriesIdsFromUrl(retailerCurrentUrl));
  }, [retailerCurrentUrl]);

  const pageCategories =
    transformCategoriesIdsIntoCategoriesNames(categoriesIds);

  const previousPageCategories = usePrevious(pageCategories);
  const previousCategories = usePrevious(categories);

  const [mostOccuringCategories, setMostOccuringCategories] = useState([]);

  /**
   * Effect for detecting most occuring categories in general page
   */
  useEffect(() => {
    if (isEmpty(pageCategories)) {
      setMostOccuringCategories(getMostOccuringCategories(productGroups));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(pageCategories), productGroups]);

  /**
   * Effect for set categories params for the scoring function
   */
  useEffect(() => {
    /**
     * Since pageCategories is array, we need to add comparison to
     * detect the change or else it'll trigger infinite setState
     */
    if (
      isNotEmpty(pageCategories) &&
      !isArrayEqual(previousPageCategories, pageCategories)
    ) {
      dispatch(actions.setCategories(pageCategories));
    }
    /**
     * Since pageCategories is array, we need to add comparison to
     * detect the change or else it'll trigger infinite setState
     */
    if (
      isEmpty(pageCategories) &&
      !isArrayEqual(previousCategories, categories)
    ) {
      dispatch(actions.setCategories(categories));
    }
  }, [
    pageCategories,
    categories,
    previousPageCategories,
    previousCategories,
    dispatch,
  ]);

  const queryClient = useQueryClient();

  const prevMostOccuringCategories = usePrevious(mostOccuringCategories);

  /**
   * Effect for prefetching recommendation by categories
   */
  useEffect(() => {
    /**
     * Since pageCategories is array, we need to add comparison to
     * detect the change or else it'll trigger infinite setState
     */
    if (
      isNotEmpty(pageCategories) &&
      !isArrayEqual(previousPageCategories, pageCategories)
    ) {
      pageCategories.map((cat) =>
        queryClient.prefetchQuery(
          ['recommendations', { categories: cat }],
          () =>
            fetchRecommendations({
              categories: [cat],
              retailerCurrentUrl,
              profiles: members,
            })
        )
      );
    }
    if (
      isNotEmpty(mostOccuringCategories) &&
      !isArrayEqual(prevMostOccuringCategories, mostOccuringCategories)
    ) {
      mostOccuringCategories.map((cat) =>
        queryClient.prefetchQuery(
          ['recommendations', { categories: cat }],
          () =>
            fetchRecommendations({
              categories: [cat],
              retailerCurrentUrl,
              profiles: members,
            })
        )
      );
    }
  }, [
    pageCategories,
    mostOccuringCategories,
    retailerCurrentUrl,
    members,
    previousPageCategories,
    prevMostOccuringCategories,
    queryClient,
  ]);

  const { isLoading, datas } = useRecommendations(
    {
      categories,
      retailerCurrentUrl,
      profiles: members,
    },
    fetchRecommendations
  );

  useEffect(() => {
    dispatch(actions.setLoading(isLoading));
  }, [dispatch, isLoading]);

  const previousRecommendations = usePrevious(datas);

  /**
   * Effect for set categories recommendations
   */
  useEffect(() => {
    /**
     * Since the datas is array of objects, we need to add comparison to
     * detect the change or else it'll trigger infinite setState
     */
    if (!isArrayEqual(previousRecommendations, datas)) {
      dispatch(actions.setCategoriesRecommendations(datas));
    }
  }, [datas, dispatch, previousRecommendations]);

  // hooks that gonna save previous members values
  const prevMembers = usePrevious(members);

  /**
   * Delete cache whenever profiles change
   */
  useEffect(() => {
    // we can start comparing value when the value is not null
    if (isNotNil(prevMembers)) {
      // We'll sort the preferences so both prev and current value deeply equal
      const prevPreferences = deduplicatePreferences(
        prevMembers.flatMap(({ preferences }) => preferences)
      ).sort((a, b) => a.name.length - b.name.length);
      const currPreferences = deduplicatePreferences(
        members.flatMap(({ preferences }) => preferences)
      ).sort((a, b) => a.name.length - b.name.length);

      const prevActiveProfiles = parseProfiles(prevMembers);
      const currActiveProfiles = parseProfiles(members);

      /**
       * We'll delete the cache when there's new preferences or when
       * some profiles change into inactive
       *
       * Note: activeProfiles will influence scoring system. that's why we
       * also watch it.
       */
      if (
        !isArrayEqual(prevPreferences, currPreferences) ||
        !isArrayEqual(prevActiveProfiles, currActiveProfiles)
      ) {
        queryClient.clear();
      }
    }
  }, [prevMembers, members, queryClient]);

  useEffect(() => {
    if (isSidebarHidden === false && userHasInteracted !== true) {
      setUserHasInteracted(true);
    }

    const configsChangeEventListener = (event) => {
      if (event.data?.channel === 'STORED_CONFIGS_DATA') {
        setFirstSession(event.data?.firstSession);
        setIsNudgeDisplayed(event.data?.isNudgeDisplayed);
        setFirstPreferencesReminderHasDisplayed(
          event.data?.firstPreferencesReminderHasDisplayed
        );
        setLastTimeNotificationHasDisplayed(
          event.data?.lastTimeNotificationHasDisplayed
        );
        setLastNotificationDisplayedType(
          event.data?.lastNotificationDisplayedType
        );
      }

      if (event.data?.channel === 'SIDEBAR_STATE_CHANGE') {
        if (event.data?.isSidebarHidden === true) {
          setViewingProductId('');
        }

        dispatch(actions.setIsSidebarHidden(event.data?.isSidebarHidden));
      }

      if (event.data?.channel === 'CREATORS_FEATURE_STATE_CHANGE') {
        dispatch(
          actions.setIsCreatorsFeatureEnabled(
            event.data?.isCreatorsFeatureEnabled
          )
        );
      }

      if (event.data?.channel === 'ON_FAB_HOVER') {
        setUserHasInteracted(event.data.userHasInteracted);
      }
    };

    window.addEventListener('message', configsChangeEventListener);

    return function cleanup() {
      window.removeEventListener('message', configsChangeEventListener);
    };
  }, [dispatch, isSidebarHidden, userHasInteracted]);

  const triggerNotificationDisplay = useCallback(
    (location, state) => {
      parentPostMessage('EXPAND_SIDEBAR_AND_REMOVE_HOVER', '*');

      history.push(location, state);

      parentPostMessage(
        {
          channel: 'NOTIFICATION_HAS_DISPLAYED',
          payload: { lastNotificationDisplayedType: state.type },
        },
        '*'
      );
    },
    [history]
  );

  useEffect(() => {
    // Don't continue if the data is not ready.
    if (
      isNil(firstPreferencesReminderHasDisplayed) ||
      isNil(lastTimeNotificationHasDisplayed) ||
      isNil(lastNotificationDisplayedType) ||
      isNil(isNudgeDisplayed) ||
      isNil(firstSession) ||
      isNil(members) ||
      isNil(userHasInteracted) ||
      userHasInteracted === true ||
      isSidebarHidden === false ||
      isWebAppReady === false ||
      loggedIn === false ||
      checked === false
    ) {
      return;
    }

    let notificationDisplayedType = '';

    // Check wether the notification should be displayed
    function shouldNotificationBeDisplayed() {
      // If the notification is already displayed this day
      const alreadyNotifiedThisDay =
        new Date(lastTimeNotificationHasDisplayed).toDateString() ===
        new Date().toDateString();
      // If the notification is already displayed this minute
      // const alreadyNotifiedThisMinute =
      //   new Date(lastTimeNotificationHasDisplayed).getUTCMinutes() ===
      //   new Date().getUTCMinutes();

      /**
       * These condition fulfilled:
       * • Same notification type and not yet being notified for today
       * • Different notification type and not yet being notified for today
       */
      if (
        !alreadyNotifiedThisDay
        // || !alreadyNotifiedThisMinute
      ) {
        return true;
      }

      // Default to false
      return false;
    }

    if (
      !firstSession &&
      isEmpty(members) &&
      !firstPreferencesReminderHasDisplayed
    ) {
      if (!shouldNotificationBeDisplayed()) return;

      notificationDisplayedType = 'first-preferences-reminder';

      triggerNotificationDisplay(path.notification, {
        path: path.addPreferences,
        type: notificationDisplayedType,
        button: t('set-preferences'),
        title: t('first-preferences-reminder-title'),
      });

      parentPostMessage('FIRST_PREFERENCES_REMINDER_HAS_DISPLAYED', '*');
    }

    if (
      !firstSession &&
      isEmpty(members) &&
      firstPreferencesReminderHasDisplayed
    ) {
      if (!shouldNotificationBeDisplayed()) return;

      notificationDisplayedType = 'second-preferences-reminder';

      triggerNotificationDisplay(path.notification, {
        path: path.addPreferences,
        type: notificationDisplayedType,
        button: t('set-preferences'),
        title: t('second-preferences-reminder-title'),
      });
    }

    if (!firstSession && loginType === 'anonymous' && isNotEmpty(members)) {
      if (!shouldNotificationBeDisplayed()) return;

      notificationDisplayedType = 'sign-up-reminder';

      triggerNotificationDisplay(path.notification, {
        path: path.welcome,
        type: notificationDisplayedType,
        button: t('sign-up'),
        title: t('sign-up-reminder-title'),
      });
    }

    const nudgeDismissedEventListener = (event) => {
      if (event.data?.channel === 'ON_NUDGE_DISMISSED') {
        if (
          shouldNotificationBeDisplayed() &&
          checked &&
          loggedIn &&
          loginType === 'anonymous' &&
          isEmpty(members)
        ) {
          notificationDisplayedType = 'first-time-popup';

          triggerNotificationDisplay(path.welcome, {
            type: notificationDisplayedType,
          });
        }
      }
    };

    window.addEventListener('message', nudgeDismissedEventListener);

    return function cleanup() {
      window.removeEventListener('message', nudgeDismissedEventListener);
    };
  }, [
    isSidebarHidden,
    isNudgeDisplayed,
    firstSession,
    firstPreferencesReminderHasDisplayed,
    lastTimeNotificationHasDisplayed,
    loggedIn,
    loginType,
    members,
    checked,
    lastNotificationDisplayedType,
    triggerNotificationDisplay,
    userHasInteracted,
    isWebAppReady,
  ]);

  useEffect(() => {
    const isOpenedInIframe = window.self !== window.top;

    if (
      // process.env.NODE_ENV === 'production' &&
      !isOpenedInIframe ||
      (isOpenedInIframe && retailerCurrentUrl !== '')
    ) {
      logEvent(analyticsEvents.pageView, {
        // Disable title as it late.
        // pageTitle: document.title,
        pageLocation: window.location.origin,
        pagePath: window.location.pathname,
        clickeranceCurrentUrl: window.location.href,
        isOpenedInIframe,
        retailerCurrentUrl,
      });
    }
  }, [retailerCurrentUrl]);

  useEffect(() => {
    const clickeranceCurrentUrl = window.location.href;

    const analyticsEventHandler = (event) => {
      if (event.data?.channel === 'ANALYTICS_LOG_EVENT') {
        logEvent(event.data?.payload.event, {
          ...event.data?.payload.params,
          // Retrieve the retailer url from extension if exists,
          // otherwise use the url from the global state.
          retailerCurrentUrl:
            event.data?.payload.retailerCurrentUrl ?? retailerCurrentUrl,
          clickeranceCurrentUrl,
          // Add members property on sticker click event.
          ...(event.data?.payload.event.name ===
            analyticsEvents.stickerClick.name && {
            currentPreferenceProfilesAmount: members.length,
          }),
        });
      }
    };

    if (clickeranceCurrentUrl !== '' && retailerCurrentUrl !== '') {
      window.addEventListener('message', analyticsEventHandler);
    }

    return function cleanup() {
      window.removeEventListener('message', analyticsEventHandler);
    };
  }, [members, retailerCurrentUrl]);

  /**
   * Set every user to login as anonymous user when they're not logged in
   *
   * Note: this is required for feedback collection
   */
  useEffect(() => {
    const isNotInLandingPage =
      !me?.id &&
      !loggedIn &&
      checked &&
      !window.location.pathname.includes('landing');
    if (
      isNotInLandingPage &&
      !process.env.REACT_APP_RUN_ON_FLUTTER &&
      !process.env.REACT_APP_RUN_ON_PUBLIC
    ) {
      dispatch(actions.loginAnonymously(true));
    }
  }, [me?.id, loggedIn, checked, dispatch]);

  /**
   * To receive products every time products on cart page change
   */
  useEffect(() => {
    const cartStateChangeEventListener = (event) => {
      if (event.data?.channel === 'on_cart_state_change') {
        dispatch(actions.setCartStates(event.data.cartStates));
      }
    };

    window.addEventListener('message', cartStateChangeEventListener);

    return function cleanup() {
      window.removeEventListener('message', cartStateChangeEventListener);
    };
  }, [dispatch]);

  /**
   * Listen to checkout event.
   */
  useEffect(() => {
    const beginCheckoutEventHandler = (event) => {
      if (event.data?.channel === 'on_checkout') {
        // Filter products with 0 quantity.
        // and remove unwanted properties.

        const cartItems = event.data?.payload.cartStates
          // TODO: Fix duplicate on connector side
          .filter(
            (p, idx, arr) =>
              arr.findIndex(
                (_p) => _p.product.productId === p.product.productId
              ) === idx
          )
          .filter((p) => p.quantity > 0)
          .map(({ product, quantity }) => ({
            quantity,
            // Refer to constants/index.js of this event name
            // to learn why we use this naming conventions.
            item_name: product.name,
            item_id: product.productId,
            url: product.url,
          }));

        logEvent(analyticsEvents.beginCheckout, {
          items: cartItems,
          retailerCurrentUrl,
          clickeranceCurrentUrl: window.location.href,
        });
      }
    };

    if (retailerCurrentUrl !== '') {
      window.addEventListener('message', beginCheckoutEventHandler);
    }

    return function cleanup() {
      window.removeEventListener('message', beginCheckoutEventHandler);
    };
  }, [retailerCurrentUrl]);

  // useEffect(() => {
  //   // Fetch recommendations created by current user.
  //   if (checked && loggedIn) {
  //     dispatch(creatorActions.fetchRecommendations());
  //   }
  // }, [dispatch, checked, loggedIn]);

  useCreatorRecommendations({
    isDisabled:
      !checked || !loggedIn || !isFeatureAvailable(me, features.creatorsInn),
  });

  /**
   * effect for set `isOnboardingCompleted` state
   */
  const previousLoginType = usePrevious(loginType);

  const { id, isOnboardingCompleted } = me;

  useEffect(() => {
    /**
     * we use prevState comparison to prevent update state
     * more than once.
     */
    if (
      previousLoginType === '' &&
      loginType === 'registered' &&
      !isOnboardingCompleted // to prevent write document more than once
    ) {
      dispatch(actions.updateIsOnboardingCompletedFlag({ userId: id }));
    }
  }, [me, loginType, previousLoginType, isOnboardingCompleted, dispatch, id]);

  const previousProductId = usePrevious(viewingProductId);

  /**
   * Change sticker for a product that's being viewed.
   *
   * PROCESS_PRODUCT
   */
  useEffect(() => {
    const productId =
      previousProductId !== '' && viewingProductId === ''
        ? previousProductId
        : viewingProductId;

    if (
      !isBlank(productId) &&
      !isBlank(members) &&
      isNotEmpty(productGroups) &&
      recommendationsStatus === 'success' &&
      me
    ) {
      try {
        const currentRecData = creatorRecommendations.find(
          (product) => product.productId === productId
        );
        const isProductAlreadyRecommended =
          !isBlank(currentRecData?.note) || !isBlank(currentRecData?.tags);

        const currentProduct = productGroups.find(
          (productGroup) => productGroup.analyzedProduct.productId === productId
        ).analyzedProduct;

        const message = {
          channel: 'PROCESS_PRODUCT',
          analyzedProduct: currentProduct,
          members: parseProfiles(members),
          creatorMode: {
            isProductAlreadyRecommended,
            isSidebarHidden,
            isCreator: isCreatorsFeatureEnabled,
            viewingProductId: productId,
          },
        };

        parentPostMessage(message, '*');
      } catch (error) {
        console.log(error);
      }
    }
  }, [
    creatorRecommendations,
    isSidebarHidden,
    me,
    members,
    viewingProductId,
    productGroups,
    previousProductId,
    isCreatorsFeatureEnabled,
    recommendationsStatus,
  ]);

  // Set initial creators feature status
  useEffect(() => {
    const isCreator = isFeatureAvailable(me, features.creatorsInn);
    dispatch(actions.setIsCreatorsFeatureEnabled(isCreator));
  }, [dispatch, isWebAppReady, me]);

  /**
   * Setup Event For save retailer account login state
   */
  useEffect(() => {
    window[windowGlobalVar.GET_RETAILERS_LOGIN_STATE] = () => {
      return connectedRetailerAccounts;
    };

    window.addEventListener('message', (event) => {
      const retailers = supportedRetailers
        .filter((s) => s.isSupported)
        .map((r) => r.name);
      if (!event.data.channel) {
        return;
      }

      if (event.data.channel !== 'RESPONSE_RETAILER_LOGIN') {
        return;
      }

      const retailerValid = retailers.some(
        (retailer) => event.data.retailerName === retailer.toUpperCase()
      );

      if (!retailerValid) {
        return;
      }

      try {
        const { retailerName, status } = event.data;
        const _connectedRetailerAccounts = { ...connectedRetailerAccounts };
        _connectedRetailerAccounts[retailerName.toLowerCase()] = status;
        dispatch(
          actions.setRetailerAccountsLoginState(_connectedRetailerAccounts)
        );
      } catch (error) {
        console.log(error);
      }
    });
  }, [dispatch, connectedRetailerAccounts]);

  /**
   * register global change path function to be called on webview
   */
  useEffect(() => {
    window[windowGlobalVar.CHANGE_PATH] = (path) => {
      history.replace(path);
    };
    window[windowGlobalVar.PUSH_PATH] = (path) => {
      history.push(path);
    };
  }, [history]);

  /**
   * to catch the session count from mobile app
   */
  useEffect(() => {
    const queryParams = new URLSearchParams(locationSearch);
    const sessionCount = parseInt(queryParams.get('sessionCount'));

    // check if numeric
    if (/^-?\d+$/.test(sessionCount)) {
      dispatch(actions.setSessionCount(sessionCount));
    }
  }, [dispatch, locationSearch]);

  /**
   * Trigger Confirm for session validation
   * when user fetch order datas
   */
  const verifiedSessionShoppingHistory = useCallback(
    /**
     * @param {string} retailer
     */
    (retailer) => {
      dispatch(actions.setIsFetchOrderHistory(false));
      dispatch(actions.setIsShowOverlayLoader(true));
      setTimeout(() => {
        if (
          window.confirm(t('order-history-analysis.warning-session-expired'))
        ) {
          parentPostMessage({
            channel: 'RETAILER_VIEW_ORDER_DETAIL',
            url: getRetailerOrderURL(retailer),
            retailer: retailer,
          });
        }
        dispatch(actions.setIsShowOverlayLoader(false));
      }, 500);
      return;
    },
    [dispatch]
  );

  /**
   * receive data shopping history / order
   */
  const callbackOrderHistory = useCallback(
    /**
     * @param {string} channel
     * @param {import('utils/shopping-history/shopping-history-module').SHOPPING_HISTORY[]} data
     * @param {string} retailer
     * @returns
     */
    (channel, data, retailer) => {
      if (channel !== 'SHOPPING_HISTORY' || !isArray(data)) {
        return;
      }
      dispatch(actions.setIsFetchOrderHistory(false));
      dispatch(actions.setOrderHistoryOrderList(data));
      history.push(`${path.analyzeOrderHistoryListOrders}`);
    },
    [dispatch, history]
  );

  /**
   * callback data to tell shopping history tasks registrations is done
   */
  const callbackRegisterShoppingHistoryDone = useCallback(
    /**
     * @param {string} channel
     * @param {import('utils/shopping-history/shopping-history-module').SHOPPING_HISTORY_ITEM} data
     * @returns
     */
    async (channel) => {
      if (channel !== 'REGISTER_SHOPPING_HISTORY_DONE') {
        return;
      }
      dispatch(actions.setIsFetchOrderHistoryItemsSuccess(true));
      dispatch(actions.setOrderHistoryAnalysisHistory([]));
    },
    [dispatch]
  );

  /**
   * receive data shopping history / order
   */
  const callbackOrderHistoryItem = useCallback(
    /**
     * @param {string} channel
     * @param {string} retailer
     * @param {string} orderID
     * @param {import('utils/shopping-history/shopping-history-module').SHOPPING_HISTORY_ITEM[]} data
     * @param {import('utils/shopping-history/shopping-history-module').SHOPPING_HISTORY[]} orders
     * @returns
     */
    async (channel, data, orders, orderID, retailer) => {
      if (channel !== 'SHOPPING_HISTORY_ITEMS' || !isArray(data) || !orderID) {
        return;
      }
      try {
        await saveSelectedOrderItems({
          orderHistoryItems: data,
          orders: orders,
          orderId: orderID,
          retailer: retailer,
        });
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  useEffect(() => {
    /**
     * register event to read shopping history data
     */
    window.addEventListener('message', (ev) => {
      const { channel, data, retailer } = ev.data;
      callbackOrderHistory(channel, data, retailer);
    });

    /**
     * register event to read registration tasks process is done
     */
    window.addEventListener('message', (ev) => {
      const { channel } = ev.data;
      callbackRegisterShoppingHistoryDone(channel);
    });

    /**
     * register event to read shopping items
     */
    window.addEventListener('message', (ev) => {
      const { channel, data, orders, orderID, retailer } = ev.data;
      callbackOrderHistoryItem(channel, data, orders, orderID, retailer);
    });

    /**
     * call function to show session verifications
     */
    window[windowGlobalVar.ORDER_SESSION_VERIFICATION] = (retailer) =>
      verifiedSessionShoppingHistory(retailer);
  }, [
    callbackOrderHistory,
    callbackRegisterShoppingHistoryDone,
    callbackOrderHistoryItem,
    verifiedSessionShoppingHistory,
  ]);

  // handle update premium data
  useEffect(() => {
    /**
     * Set premium product
     * @param {import('utils/subscription/subscription-module').PremiumPlan} plan
     */
    window[windowGlobalVar.UPDATE_PREMIUM_PLAN] = (plan) => {
      dispatch(actions.setPremiumPlans(plan));
    };

    /**
     * Set premium status
     * @param {boolean}  status
     */
    window[windowGlobalVar.UPDATE_PREMIUM_STATUS] = (status) => {
      dispatch(actions.setIsPremiumSubscriber(status));
    };

    /**
     * open / close overlay
     * @param {boolean} state
     */
    window[windowGlobalVar.SHOW_SUBSCRIPTION_OVERLAY] = (state) => {
      dispatch(actions.setIsShowOverlaySubscription(state));
    };
  }, [dispatch]);

  /// handle to check scan counter
  useEffect(() => {
    window[windowGlobalVar.CHECK_SCAN_COUNTER] = async () => {
      // add loader screen
      dispatch(actions.setIsShowOverlayLoader(true));
      // update me object with scan product counter
      await updateScanProductCounter({
        uid: me.id,
        totalScanned: getScanProductCounter(me),
      });
      const updatedMeObject = await actions.getMeObject(me.id);
      if (!updatedMeObject.id) {
        dispatch(actions.setIsShowOverlayLoader(false));
        return;
      }
      dispatch(
        actions.setMe({
          me: {
            id: updatedMeObject.id,
            emailVerified: me.emailVerified,
            ...updatedMeObject.data(),
          },
        })
      );
      // remove loader screen
      dispatch(actions.setIsShowOverlayLoader(false));

      const showTransitionOnCount = [1, 10, 20];
      if (
        showTransitionOnCount.includes(
          getScanProductCounter(updatedMeObject.data())
        )
      ) {
        return history.replace(`${path.shoppingAssistantBeforeShop}&scan=true`);
      }

      parentPostMessage('START_SCAN_PRODUCT');
    };
  }, [dispatch, history, me]);

  // global func to set FCM
  useEffect(() => {
    /**
     * Register FCM
     * @param {string} deviceID
     * @param {string} FCMToken
     */
    window[windowGlobalVar.REGISTER_FCM] = (deviceID, FCMToken) => {
      if (!loggedIn || loginType !== 'registered') {
        return;
      }
      actions.setFCMTokens({
        uid: auth.currentUser.uid,
        deviceID: deviceID,
        FCMToken: FCMToken,
      });
    };
  }, [loggedIn, loginType]);

  // handle scan / past url request
  useEffect(() => {
    /**
     * check premium feature
     * @param {"scan" | "paste-url"} type
     * @param {string | null} productUrl
     */
    const handleCheckPremiumFeatures = (type, productUrl = null) => {
      if (
        isFeatureOn({
          featureFlags: featureFlags,
          featureKey: SUBSCRIPTION_FEATURE_FLAG,
          me: me,
        }) &&
        !isPremiumSubscriber
      ) {
        dispatch(actions.setIsShowOverlaySubscription(true));
        return;
      }

      if (type === 'scan') {
        handleStartScan();
      } else {
        handleCheckProductUrl(productUrl);
      }
    };

    const handleStartScan = async () => {
      // add loader screen
      dispatch(actions.setIsShowOverlayLoader(true));
      // update me object with scan product counter
      await updateScanProductCounter({
        uid: auth.currentUser.uid,
        totalScanned: getScanProductCounter(me),
      });
      const updatedMeObject = await actions.getMeObject(auth.currentUser.uid);
      if (!updatedMeObject.id) {
        dispatch(actions.setIsShowOverlayLoader(false));
        return;
      }
      dispatch(
        actions.setMe({
          me: {
            id: auth.currentUser.uid,
            ...updatedMeObject.data(),
          },
        })
      );
      // remove loader screen
      dispatch(actions.setIsShowOverlayLoader(false));

      const showTransitionOnCount = [1, 10, 20];
      if (
        showTransitionOnCount.includes(
          getScanProductCounter(updatedMeObject.data())
        )
      ) {
        return history.replace(`${path.shoppingAssistantBeforeShop}&scan=true`);
      }

      parentPostMessage('START_SCAN_PRODUCT');
    };

    /**
     * handle check pasted url
     * @param {string} url
     */
    const handleCheckProductUrl = async (url) => {
      if (isEmpty(url)) {
        return toast({
          title: t('notification.bad-request.title'),
          description: t('notification.bad-request.body', {
            field: 'Product URL',
          }),
          status: 'warning',
          duration: 3000,
          position: 'top',
          isClosable: true,
        });
      }

      dispatch(actions.setIsShowOverlayLoader(true));

      const scanID = generateNextScanID(
        await getExistingScanIDs({ emailOrUID: me.email ?? me.uid })
      );

      await openProductAnalysisWithBarcodeOrUrl(
        scanID,
        null,
        url,
        true,
        `Back to ${t('check-a-product.title')}`,
        true,
        false
      );
      dispatch(actions.resetStateScanHistory());

      dispatch(actions.setIsShowOverlayLoader(false));
    };

    /**
     * global function request scan
     * @param {"scan" | "paste-url"} type
     * @param {string | null} productUrl
     */
    window[windowGlobalVar.SCAN_REQUEST] = (type, productUrl = null) => {
      handleCheckPremiumFeatures(type, productUrl);
    };
  }, [dispatch, featureFlags, history, isPremiumSubscriber, me, toast]);

  /**
   * Global Function to Update retailer session
   */
  useEffect(() => {
    /**
     * @param {string} retailer
     * @param {boolean} status
     */
    window[windowGlobalVar.UPDATE_RETAILER_SESSION] = (retailer, status) => {
      const _connectedRetailerAccounts = { ...connectedRetailerAccounts };
      _connectedRetailerAccounts[retailer.toLowerCase()] = status;
      dispatch(
        actions.setRetailerAccountsLoginState(_connectedRetailerAccounts)
      );
    };
  }, [connectedRetailerAccounts, dispatch]);

  /**
   * Global function to start get order data
   */
  useEffect(() => {
    window[windowGlobalVar.START_FETCHING_ORDER_HISTORY] = (retailer) => {
      if (retailer === 'asda') {
        toast({
          title: t('order-history-analysis.retailer-not-supported'),
          description: t(
            'order-history-analysis.retailer-not-supported-waring'
          ),
          status: 'success',
          duration: 3000,
          position: 'top',
          isClosable: true,
        });
        return;
      }
      parentPostMessage({
        channel: 'GET_SHOPPING_HISTORY',
        retailer: retailer,
      });
      dispatch(actions.setIsFetchOrderHistory(true));
    };
  }, [dispatch, toast]);

  /**
   * Global Handle to start REQUEST_RETAILER_LOGIN
   */
  useEffect(() => {
    /**
     * @param {string} retailer
     * @returns
     */
    window[windowGlobalVar.START_REQUEST_RETAILER_LOGIN] = (retailer) => {
      return parentPostMessage({
        channel: 'REQUEST_RETAILER_LOGIN',
        retailer: retailer,
      });
    };

    /**
     * @param {string} retailer
     * @returns
     */
    window[windowGlobalVar.VALIDATE_SHOPPING_ASSISTANT_SESSION] = (
      retailer
    ) => {
      dispatch(actions.setIsShowOverlayLoader(true));
      setTimeout(() => {
        if (
          window.confirm(t('order-history-analysis.warning-session-expired'))
        ) {
          window[windowGlobalVar.START_REQUEST_RETAILER_LOGIN](retailer);
        }
        dispatch(actions.setIsShowOverlayLoader(false));
      }, 500);
      return;
    };
  }, [dispatch]);

  useEffect(() => {
    /**
     * update active plans
     * @param {import('utils/subscription/subscription-module').SubscriptionDetail} plans[]
     */
    window[windowGlobalVar.UPDATE_ACTIVE_PLAN] = (plans) => {
      dispatch(actions.setActivePlan(plans));
    };
  }, [dispatch]);

  useEffect(() => {
    /**
     * update status to check if user eligible free trials
     * @param {boolean} status
     */
    window[windowGlobalVar.SET_AVAILABLE_FREE_TRIALS] = (status) => {
      dispatch(actions.setEligibleForFreeTrials(status));
    };
  }, [dispatch]);

  useEffect(() => {
    /**
     * record purchase event
     * @param {{
     *  productId: string,
     *  status: string
     * }} param
     */
    window[windowGlobalVar.RECORD_PURCHASE_EVENT] = ({ productId, status }) => {
      recordPurchaseEvent({
        productId: productId,
        status: status,
      });
    };
  }, [dispatch]);

  /**
   * Global handle to log event.
   */
  useEffect(() => {
    /**
     * @param {{
     *  name: string,
     *  params: Object<string, any>,
     * }}
     */
    window[windowGlobalVar.RECORD_ANALYTIC_EVENT] = (name, params) => {
      const event = { name: name.toLowerCase(), type: 'interactive' };
      logEvent(event, params);
    };
  }, [dispatch]);

  return props.children;
}
