/**
 * This file will handled indexedDB operation
 * @var FL_INDEXED_DB_NAME is name of database we use fr foodlama
 * @var FL_INDEXED_DB_VERSION is version of database we use fr foodlama
 */
export const FL_INDEXED_DB_NAME = "fl_indexed_db";
export const FL_INDEXED_DB_VERSION = 1;

/**
 * Function to open FL indexed DB
 * @param {string} databaseName 
 * @param {number} version 
 * @returns {Promise<IDBDatabase>}
 */
export async function openIndexedDB(databaseName, version) {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(databaseName, version);

        request.onsuccess = function (event) {
            const db = event.target.result;
            resolve(db);
        };

        request.onerror = function (event) {
            reject(event.target.error);
        };

        request.onupgradeneeded = function (event) {
            const db = event.target.result;

            // Create an object store to store key-value pairs
            if (!db.objectStoreNames.contains('keyValueStore')) {
                db.createObjectStore('keyValueStore');
            }
        };
    });
}

/**
 * Saving Data on FL INDEXED_DB
 * @param {IDBDatabase} db 
 * @param {string} key 
 * @param {string} data 
 * @returns {Promise<any>}
 */
export async function saveIDBData(db, key, data) {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction('keyValueStore', 'readwrite');
        const store = transaction.objectStore('keyValueStore');
        const request = store.put(data, key);

        request.onsuccess = function (event) {
            resolve();
        };

        request.onerror = function (event) {
            reject(event.target.error);
        };
    });
}

/**
 * Getting Data on FL INDEXED_DB
 * @param {IDBDatabase} db 
 * @param {string} key 
 * @returns {Promise<any>}
 */
export async function getIDBData(db, key) {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction('keyValueStore', 'readonly');
        const store = transaction.objectStore('keyValueStore');
        const request = store.get(key);

        request.onsuccess = function (event) {
            const data = event.target.result;
            resolve(data);
        };

        request.onerror = function (event) {
            reject(event.target.error);
        };
    });
}

/**
 * Removes data from the IndexedDB database by key.
 * @param {IDBDatabase} db - The IndexedDB database connection.
 * @param {string} key - The key of the data to remove.
 * @returns {Promise<void>} A Promise that resolves when the data is successfully removed.
 */
export async function removeIDBData(db, key) {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction('keyValueStore', 'readwrite');
        const store = transaction.objectStore('keyValueStore');
        const request = store.delete(key);

        request.onsuccess = () => resolve();
        request.onerror = () => reject(request.error);
    });
}

/**
 * Function for removing referral data on IndexedDB
 * @returns {Promise<void>}
 */
export async function removeReferralOnIDB() {
    try {
        const db = await openIndexedDB(FL_INDEXED_DB_NAME);
        await removeIDBData(db, "ref_code");
    } catch (error) {
        //ignore any error in this process
    }
}   