import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { Fragment } from 'react';
import StartForFreeImg from '../../assets/images/subscription/overlay-start-for-free.png';
import LogoFL from '../../assets/images/foodlama-logo-long.svg';
import { premiumFeatureRules } from 'constants';
import { useSelector } from 'react-redux';
import { isIOS } from 'react-device-detect';
import { externalUrls } from 'utils/const';
import { parentPostMessage } from 'utils/postmessage-module/postMessage';
import { convertNumberToCurrency } from 'utils/price-formatter';

/**
 * @typedef ReduxVar
 * @property {import('utils/subscription/subscription-module').PremiumPlan} premiumPlans
 * @property {boolean} eligibleForFreeTrials
 */

const SubscriptionStarter = ({ onClick }) => {
  /** @type {ReduxVar} */
  const { premiumPlans, eligibleForFreeTrials } = useSelector(
    (state) => state.app
  );

  return (
    <Fragment>
      <Box
        w="full"
        position="fixed"
        top={0}
        right={0}
        bottom={0}
        left={0}
        bgImage={StartForFreeImg}
        bgPosition="center"
        bgSize="cover"
        bgRepeat="no-repeat"
        zIndex={-1}
        _after={{
          content: '""',
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          height: '100%',
          backgroundImage:
            'linear-gradient(180deg, rgba(0, 0, 0, 0.6) 10.47%, rgba(0, 0, 0, 0.58) 52.03%, rgba(0, 0, 0, 0.78) 84.36%)',
          zIndex: 1,
        }}
      />

      <Flex
        flexDir="column"
        w="full"
        h="full"
        overflowY={'auto'}
        alignItems="center"
        justifyContent="start"
        gap="6"
        py="28"
        px="8"
        maxW={'430px'}
      >
        <Box w="145px" mb="60">
          <Image w="full" h="full" src={LogoFL} alt="fl-logo-dark" />
        </Box>

        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          w="full"
          h="full"
          gap="4"
        >
          <Heading
            fontSize={{ base: '30px', md: '32px' }}
            color="#FFF"
            textAlign="center"
            lineHeight={'1.2'}
            mb={'5px'}
          >
            {t('subscription-screen.overlay.title', {
              day: premiumFeatureRules.TRIAL_DAYS_LIMIT,
            })}
          </Heading>
          <Text
            fontSize={{ base: '18px', md: '20px' }}
            color="#FFF"
            textAlign="center"
            fontWeight="600"
            dangerouslySetInnerHTML={{
              __html: eligibleForFreeTrials
                ? t('subscription-screen.overlay.body', {
                    day: premiumFeatureRules.TRIAL_DAYS_LIMIT,
                    monthlyPrice: `${
                      premiumPlans.yearly
                        ? convertNumberToCurrency(
                            premiumPlans.yearly.realNumber / 12,
                            premiumPlans.yearly.currencyCode
                          )
                        : premiumPlans.monthly.priceTag
                    }/month`,
                  })
                : t('subscription-screen.overlay.body-no-free-trials', {
                    monthlyPrice: `${
                      premiumPlans.yearly
                        ? convertNumberToCurrency(
                            premiumPlans.yearly.realNumber / 12,
                            premiumPlans.yearly.currencyCode
                          )
                        : premiumPlans.monthly.priceTag
                    }/month`,
                  }),
            }}
          />

          <Button
            onClick={onClick}
            w="full"
            fontSize={{ base: '16px', md: '18px' }}
            variant="mobileRounded"
            mt="12"
          >
            {eligibleForFreeTrials
              ? t('subscription-screen.overlay.start-button')
              : t('subscription-screen.overlay.start-button-no-free-trials')}
          </Button>

          {isIOS && (
            <Button
              mt={'8px'}
              variant="link"
              fontSize={{ base: '12px', md: '14px' }}
              color="#FFF"
              textDecoration="underline"
              textUnderlineOffset="3px"
              textTransform="none"
              onClick={() => parentPostMessage('REQUEST_RESTORE_PURCHASE')}
            >
              {t('subscription-screen.overlay.restore-button')}
            </Button>
          )}

          <Box w="full" maxW="calc(100% - 80px)" mt="10">
            <Text
              fontSize={{ base: '14px', md: '16px' }}
              color="#FFF"
              textAlign="center"
              mb="10"
            >
              {eligibleForFreeTrials
                ? t(
                    premiumPlans.yearly
                      ? 'subscription-screen.overlay.billed-yearly'
                      : 'subscription-screen.overlay.billed-monthly',
                    {
                      day: premiumFeatureRules.TRIAL_DAYS_LIMIT,
                      price: `${
                        premiumPlans.yearly
                          ? premiumPlans.yearly.priceTag
                          : premiumPlans.monthly.priceTag
                      }`,
                    }
                  )
                : t(
                    premiumPlans.yearly
                      ? 'subscription-screen.overlay.billed-yearly-no-free-trials'
                      : 'subscription-screen.overlay.billed-monthly-no-free-trials',
                    {
                      price: `${
                        premiumPlans.yearly
                          ? premiumPlans.yearly.priceTag
                          : premiumPlans.monthly.priceTag
                      }`,
                      day: premiumFeatureRules.TRIAL_DAYS_LIMIT,
                    }
                  )}
            </Text>
            {isIOS && (
              <Flex
                w="full"
                justifyContent="center"
                gap="6"
                pos="absolute"
                bottom="32px"
                left="0"
              >
                <Button
                  variant="link"
                  fontSize={{ base: '12px', md: '14px' }}
                  color="#FFF"
                  textDecoration="underline"
                  textUnderlineOffset="3px"
                  textTransform="none"
                  onClick={() =>
                    (window.location.href = externalUrls.termsOfUse)
                  }
                >
                  {t('subscription-screen.overlay.terms-of-use')}
                </Button>
                <Divider orientation="vertical" borderColor="#fff" h="25px" />
                <Button
                  variant="link"
                  fontSize={{ base: '12px', md: '14px' }}
                  color="#FFF"
                  textDecoration="underline"
                  textUnderlineOffset="3px"
                  textTransform="none"
                  onClick={() =>
                    (window.location.href = externalUrls.privacyPolicy)
                  }
                >
                  {t('subscription-screen.overlay.privacy-policy')}
                </Button>
              </Flex>
            )}
          </Box>
        </Flex>
      </Flex>
    </Fragment>
  );
};

export default SubscriptionStarter;
