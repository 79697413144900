import { isNil } from 'lodash';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { collections } from '../utils/const';
import { actions as creatorActions } from '../slices/creators.slice';

// TODO:
// - Implement pagination
// - Return stream of products
// - Use Context instead of redux
export function useCreatorRecommendations(
  { isDisabled } = { isDisabled: false }
) {
  const db = useFirestore();

  const dispatch = useDispatch();

  const id = useSelector((state) => state.app.me?.email);

  useEffect(() => {
    let unsubscribe;

    if ((isNil(id) && typeof id !== 'string') || isDisabled) {
      return;
    }

    dispatch(creatorActions.setRecommendationsStatus('loading'));

    const collectionRef = collection(db, collections.creatorRecommendations);
    const queryRef = query(collectionRef, where('createdBy', '==', id));

    unsubscribe = onSnapshot(queryRef, (querySnapshot) => {
      const recommendations = querySnapshot.docs.map((doc) => ({
        NO_ID_FIELD: doc.id,
        ...doc.data(),
      }));

      dispatch(
        creatorActions.setRecommendations(
          recommendations.map(serializeTimestampFields)
        )
      );
    });

    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [id, db, dispatch, isDisabled]);
}

function defaultTimestampSerializer(timestamp) {
  return new Date(timestamp.seconds * 1000).toISOString();
}

function isTimestamp(value) {
  return typeof value === 'object' && !isNil(value.seconds);
}

function serializeTimestampFields(data, { serializer } = {}) {
  const hasSerializer = typeof serializer === 'function';

  return Object.entries(data).reduce((acc, [key, value]) => {
    if (isTimestamp(value)) {
      return {
        ...acc,
        [key]: hasSerializer
          ? serializer(value)
          : defaultTimestampSerializer(value),
      };
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {});
}

export default useCreatorRecommendations;
