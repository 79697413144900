import React from 'react';
import { Box, Flex, Spacer, Image, Divider, Center } from '@chakra-ui/react';

import Logo from '../../assets/images/foodlama-logo-long.svg';
import CUKLogo from '../../assets/images/cuk-logo.svg';
import { CloseButton, NavigationMenu } from './components';

import './navbar.scss';
import { useMinimizeSidebar } from '../../hooks';
import { useSelector } from 'react-redux';
import { checkCUKReferralOnMeObject } from 'utils/cuk-module/cuk-module';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { path } from 'utils/const';

// TODO: Replace `trailing` props with `actions`
const Navbar = ({
  background,
  leading,
  title,
  trailing,
  onClose,
  navProps,
}) => {
  const { me, loggedIn, loginType, refCookie } = useSelector(
    (state) => state.app
  );

  const isOpenedInIframe = window.self !== window.top;

  const minimizeSidebar = useMinimizeSidebar();

  const renderDefaultTrailing = () => {
    if (!isOpenedInIframe) {
      return <Box />;
    }

    return (
      <Box pr={4}>
        <CloseButton onCloseClick={onClose || minimizeSidebar} />
      </Box>
    );
  };

  const location = useLocation();
  const isNavigationMenuVisible = () => {
    if (
      !process.env.REACT_APP_RUN_ON_FLUTTER ||
      !loggedIn ||
      loginType === 'anonymous'
    ) {
      return false;
    }

    if (location.pathname === path.productRelated) {
      return false;
    }

    return true;
  };

  return isNavigationMenuVisible() ? (
    <NavigationMenu userName={me.name} {...navProps} />
  ) : (
    <Box
      width="full"
      bgColor="white"
      borderBottom="1px"
      borderColor="white.300"
      position="sticky"
      top="0px"
      py="4"
      zIndex="4"
      {...navProps}
    >
      <Flex alignItems="center">
        <Flex justifyContent="center" alignItems="center">
          {leading}
          {checkCUKReferralOnMeObject(me) ||
          (refCookie && refCookie.isSupported) ? (
            <CUKxFLNavbar />
          ) : (
            title || (
              <Box pl={8}>
                <Image
                  width={['40', '56', '64']}
                  src={Logo}
                  alt="foodlama-logo"
                  loading="lazy"
                />
              </Box>
            )
          )}
        </Flex>

        <Spacer />

        {trailing || renderDefaultTrailing()}
      </Flex>
    </Box>
  );
};

export const CUKxFLNavbar = () => {
  return (
    <Flex justifyContent="start" alignItems="center" gap={3}>
      <Image
        width={['32', '36', '56']}
        src={CUKLogo}
        alt="cuk-logo"
        loading="lazy"
        ml="8"
      />
      <Center height={{ base: '32px', md: '50px', lg: '65px' }}>
        <Divider
          orientation="vertical"
          borderColor="black"
          variant="solid"
          size="2px"
        />
      </Center>
      <Image
        width={['32', '36', '56']}
        src={Logo}
        alt="foodlama-logo"
        loading="lazy"
      />
    </Flex>
  );
};

export default Navbar;
