const deduplicatePreferences = (preferences) => {
  return preferences.reduce((acc, curr) => {
    const existingValue = acc.find(({ name }) => name === curr.name);

    if (!existingValue) {
      acc.push(curr);
    }
    return acc;
  }, []);
};

export default deduplicatePreferences;
