import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';
import { isEmpty, isNil } from 'lodash';
import { collections } from '../utils/const';
import { getRecommendationsToDelete } from '../utils/creators-inn';
import { transformRecommendation } from '../utils/creators-inn/transform-recommendation';
// import { getRecommendationId } from '../utils/creators-inn';
import { firestore } from '../utils/firebase';
import { getProductUid } from '../utils/product';

/**
 * @type {
 *  recommendations: any[],
 *  recommendationsStatus: 'loading' | 'success' | 'error'
 * }
 */
const initialState = {
  // Current creator's recommendations.
  recommendations: [],
  recommendationsStatus: 'success',
  fetchRecommendationsStatus: 'success',
  saveStatus: 'success',
  deleteStatus: 'success',
};

const creatorsSlice = createSlice({
  name: 'creators',
  initialState,
  reducers: {
    setRecommendationsStatus: (state, action) => ({
      ...state,
      recommendationsStatus: action.payload,
    }),
    setRecommendations: (state, action) => ({
      ...state,
      recommendations: action.payload,
      recommendationsStatus: 'success',
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecommendations.fulfilled, (state, { payload }) => {
        state.recommendations = payload.recommendations.map(
          transformRecommendation
        );
        state.fetchRecommendationsStatus = 'success';
      })
      .addCase(fetchRecommendations.pending, (state) => {
        state.fetchRecommendationsStatus = 'loading';
      })
      .addCase(fetchRecommendations.rejected, (state) => {
        state.fetchRecommendationsStatus = 'error';
      })
      .addCase(saveRecommendation.fulfilled)
      .addCase(
        deleteRecommendation.fulfilled,
        (
          state
          // { payload: deletedRecommendations }
        ) => {
          // const deletedIds = deletedRecommendations.map(getProductUid);
          // state.recommendations = state.recommendations
          //   .filter((rec) => !deletedIds.includes(getProductUid(rec)))
          //   .map(transformRecommendation);
          state.deleteStatus = 'success';
        }
      )
      .addCase(deleteRecommendation.pending, (state) => {
        state.deleteStatus = 'loading';
      })
      .addCase(deleteRecommendation.rejected, (state) => {
        state.deleteStatus = 'error';
      });
  },
});

const creatorRecRef = firestore.collection(collections.creatorRecommendations);

/**
 * @deprecated
 */
const fetchRecommendations = createAsyncThunk(
  'creators/fetchRecommendations',
  async (_, { getState }) => {
    const {
      app: {
        me: { email },
        loginType,
      },
    } = getState();

    const userEmail = email ?? '';
    const isAnonymousUser = loginType === 'anonymous';

    if (userEmail === '' || isAnonymousUser) {
      return { recommendations: [] };
    }

    const recommendations = (
      await creatorRecRef.where('createdBy', '==', userEmail).get()
    ).docs.map((doc) => {
      const data = doc.data();
      return serializeTimestampFields({
        ...data,
        NO_ID_FIELD: doc.id,
      });
    });

    return { recommendations };
  }
);

const deleteRecommendation = createAsyncThunk(
  'creators/deleteRecommendations',
  async (recommendation, { getState }) => {
    const { recommendations } = getState().creators;
    const writeBatch = firestore.batch();

    // Remove main product and products on its `alternativeTo` list
    // product on `alternativesTo` are removed iff
    // the product hasn't been recommended AND no other product link this product in their `alternativeTo`
    const targets = getRecommendationsToDelete({
      target: recommendation,
      recommendations,
    });

    targets.forEach((doc) =>
      writeBatch.delete(creatorRecRef.doc(doc.NO_ID_FIELD))
    );

    await writeBatch.commit();

    // return targets;
  }
);

const saveRecommendation = createAsyncThunk(
  'creators/saveRecommendation',
  async (recommendation, { getState }) => {
    const { app, creators } = getState();

    const writeBatch = firestore.batch();

    const userEmail = app.me.email;

    // Create new document if no document id (NO_ID_FIELD) in the payload
    const updatedRecommendation = {
      ...recommendation,
      createdAt: Timestamp.now(),
      createdBy: userEmail,
      NO_ID_FIELD: recommendation?.NO_ID_FIELD || creatorRecRef.doc().id, // Create a uid if it's not exist.
      // Two fields above should not be updated.
      updatedAt: Timestamp.now(),
    };

    (() => {
      const { NO_ID_FIELD: targetId, ...recommendationData } =
        updatedRecommendation;
      writeBatch.set(creatorRecRef.doc(targetId), {
        ...recommendationData,
      });
    })();

    const existingRecIds = creators.recommendations.map(getProductUid);
    // // New alternatives that should be created as a new recommendation document.
    const newAlternatives =
      recommendation.alternativesTo?.filter(
        (alt) => !existingRecIds.includes(getProductUid(alt))
      ) ?? [];

    // const prevRecommendation = creators.recommendations.find(
    //   (rec) => getProductUid(rec) === getProductUid(recommendation)
    // );

    // Get all alternatives that will be removed in new update.
    // const willBeRemovedAlts = !isNil(prevRecommendation)
    //   ? prevRecommendation.alternativesTo?.filter(
    //       (alt) =>
    //         !recommendation.alternativesTo.some(
    //           (_alt) => getProductUid(alt) === getProductUid(_alt)
    //         )
    //     )
    //   : [];

    // const deletedAlternatives = getRecommendationsToDelete({
    //   target: {
    //     ...recommendation,
    //     alternativesTo: willBeRemovedAlts,
    //   },
    //   recommendations: creators.recommendations,
    // });
    // console.log('deletedAlternatives', deletedAlternatives);

    // deletedAlternatives.forEach((alt) => {
    //   writeBatch.delete(creatorRecRef.doc(alt.NO_ID_FIELD));
    // });

    const createdAlternatives = !isEmpty(newAlternatives)
      ? newAlternatives.map((alt) => {
          const uid = creatorRecRef.doc().id;
          return {
            ...alt,
            NO_ID_FIELD: uid,
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
            createdBy: userEmail,
          };
        })
      : [];

    createdAlternatives.forEach(({ NO_ID_FIELD, ...alt }) => {
      const docRef = creatorRecRef.doc(NO_ID_FIELD);
      writeBatch.set(docRef, { ...alt });
    });

    await writeBatch.commit();
  }
);

export const actions = {
  ...creatorsSlice.actions,
  fetchRecommendations,
  saveRecommendation,
  deleteRecommendation,
};

export default creatorsSlice.reducer;

function defaultTimestampSerializer(timestamp) {
  return new Date(timestamp.seconds * 1000).toISOString();
}

function isTimestamp(value) {
  return typeof value === 'object' && !isNil(value.seconds);
}

function serializeTimestampFields(data, { serializer } = {}) {
  const hasSerializer = typeof serializer === 'function';

  return Object.entries(data).reduce((acc, [key, value]) => {
    if (isTimestamp(value)) {
      return {
        ...acc,
        [key]: hasSerializer
          ? serializer(value)
          : defaultTimestampSerializer(value),
      };
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {});
}
