import { getProductUid } from '../product';
import { isEmpty } from 'lodash';

/**
 * Get all recommendation that should be removed given
 * `target` as primary target that will be deleted.
 * A recommendation, aside from primary target, will be deleted iff it fulfill following conditions:
 * 1. After the parimary target is deleted, it won't have any `alternatives` left
 * 2. AND it hasn't been recommended i.e. `tags`, `tagsNotRecommended`, and `alternativesTo` fields are empty.
 *
 * @param {{target: object, recommendations: object[]}} param0
 * @returns
 */
export function getRecommendationsToDelete({ target, recommendations }) {
  if (isEmpty(target.alternativesTo)) {
    return [target];
  }

  const relatedTargets = recommendations.filter(
    (rec) =>
      // Product has only this recommendation as an alternative
      rec.alternatives.every((alt) => {
        return getProductUid(alt) === getProductUid(target);
      }) &&
      // Product is not yet recommended (they're created only for alternative recommendation purpose)
      !rec.hasRecommended
  );

  return [...relatedTargets, target];
}
