import React from 'react';
import { Box } from '@chakra-ui/react';
import { isFunction } from 'lodash';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Close } from '../../../assets/icon/close.svg';

export const CloseButton = ({ onCloseClick }) => {
  const history = useHistory();

  const handleCloseClick = () => {
    if (isFunction(onCloseClick)) {
      onCloseClick();
    } else {
      history.goBack();
    }
  };

  return (
    <Box
      fontSize={['3xl', '3xl', '4xl']}
      color="white"
      display="flex"
      justifyContent="flex-end"
      _hover={{ cursor: 'pointer' }}
      className="container-close-button"
      onClick={handleCloseClick}
    >
      <Close />
    </Box>
  );
};
