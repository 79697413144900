import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Heading,
  Image,
  Input,
  List,
  ListItem,
  Skeleton,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { SubMenuNavigation } from 'components/navbar/components';
import { t } from 'i18next';
import React, { Fragment, useState } from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { externalUrls, path } from 'utils/const';
import LogoFL from '../../assets/images/foodlama-logo-long-dark.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getIndefiniteArticle } from 'utils/misc';
import FontIcon from 'components/font-icon';
import { getPersonality } from './personality-rules';
import { isEmpty } from 'lodash';
import { parentPostMessage } from 'utils/postmessage-module/postMessage';
import SuccessPattern from '../../assets/images/patterns/foodlama-success-pattern.svg';
import ModalConfirm from 'components/modal/ModalConfirm';
import { isFeatureOn } from 'utils/feature-flags/feature-flags-module';
import { actions } from 'slices/app.slice';

/**
 * Represents a user profile with specific traits related to culinary preferences.
 * @typedef {Object} PersonalityTraits
 * @property {string} Motivators - The motivator trait of the user.
 * @property {string} Openness - The openness trait of the user.
 * @property {string} Control - The control trait of the user.
 * @property {string} Social_Function - The social function trait of the user.
 */

const PersonalitySkeletonCard = () => {
  return (
    <Fragment>
      <Skeleton h="440px" rounded="18px" mb="6" />
      <Skeleton h="56px" rounded="100px" />
    </Fragment>
  );
};

/**
 * Card when failed get personality type
 * @param {{
 *  personalityTraits: PersonalityTraits
 * }} props
 * @returns
 */
const PersonalityFailedCard = ({ personalityTraits }) => {
  return (
    <Flex flexDir="column" bg="#04CEAA1F" rounded="18px" p="6" gap="4" mb="8">
      <Heading
        fontSize="16px"
        fontWeight="semibold"
        lineHeight="1.2"
        mb={'16px'}
      >
        {t('food-personality-result.not-found-text')}
      </Heading>
      <List mb={'16px'}>
        <ListItem
          fontSize={'16px'}
          display={'flex'}
          justifyContent={'space-between'}
          borderTop={'1px solid'}
          borderColor={'teal.500'}
          py={'5px'}
        >
          <Text as="span" color="teal.500" fontWeight={'semibold'}>
            Motivators:
          </Text>
          {personalityTraits.Motivators}
        </ListItem>
        <ListItem
          fontSize={'16px'}
          display={'flex'}
          justifyContent={'space-between'}
          borderTop={'1px solid'}
          borderColor={'teal.500'}
          py={'5px'}
        >
          <Text as="span" color="teal.500" fontWeight={'semibold'}>
            Openness:
          </Text>
          {personalityTraits.Openness}
        </ListItem>
        <ListItem
          fontSize={'16px'}
          display={'flex'}
          justifyContent={'space-between'}
          borderTop={'1px solid'}
          borderColor={'teal.500'}
          py={'5px'}
        >
          <Text as="span" color="teal.500" fontWeight={'semibold'}>
            Control:
          </Text>
          {personalityTraits.Control}
        </ListItem>
        <ListItem
          fontSize={'16px'}
          display={'flex'}
          justifyContent={'space-between'}
          borderY={'1px solid'}
          borderColor={'teal.500'}
          py={'5px'}
        >
          <Text as="span" color="teal.500" fontWeight={'semibold'}>
            Social Function:
          </Text>
          {personalityTraits.Social_Function}
        </ListItem>
      </List>
    </Flex>
  );
};

/**
 * Screen when download personality
 * @param {{
 *   bgCard: string,
 *   personalityTraits: PersonalityTraits
 *   personalityType: import('./personality-rules').CulinaryProfile
 * }} props
 * @returns
 */
const PersonalityResultCard = ({
  bgCard = '#04CEAA1F',
  personalityTraits,
  personalityType,
  shadowCard = 'unset',
}) => {
  if (isEmpty(personalityType)) {
    return <PersonalityFailedCard personalityTraits={personalityTraits} />;
  }

  return (
    <Flex
      flexDir="column"
      bg={bgCard}
      rounded="24px"
      p="24px"
      gap="16px"
      shadow={shadowCard}
    >
      <Heading fontSize="27px" fontWeight="400" lineHeight="1.5">
        {t('food-personality-result.you-are', {
          indefinite: getIndefiniteArticle(personalityType.personality),
        })}{' '}
        <Text as="span" color="#04CEAA">
          {personalityType.personality}
        </Text>
      </Heading>
      <Text fontSize="14px">{personalityType.description}</Text>

      <Flex
        alignItems="end"
        justifyContent="center"
        minH="200px"
        bg="#04CEAA"
        rounded="16px"
        my="16px"
      >
        <Box>
          <Image
            src={personalityType.image}
            w="full"
            h="full"
            objectFit="cover"
            alt={personalityType.personality}
            loading="lazy"
          />
        </Box>
      </Flex>

      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize="14px" color="#045050">
          {`#${t(`nav-menu.my-food-personality`).replace(/\s/g, '')}`}
        </Text>
        <Box w="64px">
          <Image
            w="full"
            h="full"
            objectFit="cover"
            src={LogoFL}
            alt="foodlama-logo"
            loading="lazy"
          />
        </Box>
      </Flex>
    </Flex>
  );
};

const ShareViaSection = ({ comingSoonToast }) => {
  const shareViaList = [
    { active: true, name: 'whatsapp', onClick: comingSoonToast },
    { active: true, name: 'twitter', onClick: comingSoonToast },
    { active: true, name: 'facebook', onClick: comingSoonToast },
    { active: true, name: 'instagram', onClick: comingSoonToast },
  ];

  // temporary hide
  return (
    <Fragment>
      <Heading display="none" fontSize="2xl">
        {t('food-personality-result.share-via')}
      </Heading>
      <hr display="none" />
      <Flex
        display="none"
        gap="4"
        alignItems="center"
        justifyContent="space-between"
      >
        {shareViaList
          .filter((v) => v.active)
          .map((v, i) => {
            return (
              <Button
                key={`share-via-${i}`}
                variant="outline"
                rounded="full"
                borderColor="#00000014"
                w="48px"
                h="48px"
                fontSize="4xl"
                colorScheme={v.name}
                onClick={v.onClick}
              >
                <FontIcon type="fab" name={v.name} />
              </Button>
            );
          })}
        <Button
          variant="outline"
          rounded="full"
          borderColor="#00000014"
          w="48px"
          h="48px"
          fontSize="4xl"
          onClick={comingSoonToast}
        >
          <FontIcon name="ellipsis-v" />
        </Button>
      </Flex>
    </Fragment>
  );
};

/**
 * Screen when download personality
 * @param {{
 *   dark: boolean,
 *   personalityTraits: PersonalityTraits
 *   personalityType: import('./personality-rules').CulinaryProfile
 * }} props
 * @returns
 */
export const PersonalityResultDownload = ({
  dark = true,
  personalityTraits,
  personalityType,
  isLoading = false,
}) => {
  return (
    <Flex
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      w="full"
      h="100vh"
      bg={dark ? 'teal.500' : 'white'}
    >
      <Box w="full" h="full" position="absolute" bgImage={SuccessPattern} />

      <Box w="full" px="32px" mt="16px" zIndex="1" maxW={"450px"} mx={"auto"}>
        {isLoading ? (
          <PersonalitySkeletonCard />
        ) : (
          <Fragment>
            <PersonalityResultCard
              bgCard={dark ? 'white' : '#04CEAA1F'}
              personalityTraits={personalityTraits}
              personalityType={personalityType}
              shadowCard="2xl"
            />
            {process.env.REACT_APP_RUN_ON_PUBLIC && (
              <Button
                mt="6"
                variant="mobileRounded"
                colorScheme="light"
                shadow="2xl"
                w="full"
                onClick={() =>
                  window.open(externalUrls.myFoodPersonality, '_blank')
                }
              >
                {t('food-personality-result.get-my-button')}
              </Button>
            )}
          </Fragment>
        )}
      </Box>
    </Flex>
  );
};

const FoodPersonalityResult = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();

  const { me, featureFlags } = useSelector((state) => state.app);

  const {
    isOpen: isOpenModalRetakeQuiz,
    onClose: onCloseModalRetakeQuiz,
    onOpen: onOpenModalRetakeQuiz,
  } = useDisclosure();

  const {
    isOpen: isOpenModalUpdateUsername,
    onClose: onCloseModalUpdateUsername,
    onOpen: onOpenModalUpdateUsername,
  } = useDisclosure();

  const [newUsername, setNewUsername] = useState('');
  const [statusUpdateUsername, setStatusUpdateUsername] = useState('');

  /** @type {PersonalityTraits} */
  const personalityTraits = me.personality?.traits ?? null;

  if (personalityTraits === null) {
    return <Redirect to={path.dashboard} />;
  }

  /** @type {import('./personality-rules').CulinaryProfile} */
  const personalityType = getPersonality({ ...personalityTraits });

  const comingSoonToast = () =>
    toast({
      title: t('notification.coming-soon.title'),
      description: t('notification.coming-soon.body'),
      status: 'success',
      duration: 3000,
      position: 'top',
      isClosable: true,
    });

  const handleUpdateUsername = async () => {
    setStatusUpdateUsername('pending');

    try {
      if (!newUsername) {
        throw new Error('Please enter your username properly!');
      }

      if (newUsername.includes(" ")){
        throw new Error('Username cannot contains white space');
      }

      if (newUsername.includes("&")){
        throw new Error('Username cannot contains &');
      }

      if (newUsername.includes("?")){
        throw new Error('Username cannot contains ?');
      }

      if (newUsername.includes(".")){
        throw new Error('Username cannot contains (.)');
      }

      if (newUsername.includes(".")){
        throw new Error('Username cannot contains (,)');
      }

      // update username
      await dispatch(
        actions.updateUsername({
          uid: me.id,
          username: newUsername,
        })
      );
      // update me object
      const updatedMeObject = await actions.getMeObject(me.id);
      dispatch(
        actions.setMe({
          me: {
            id: updatedMeObject.id,
            emailVerified: me.emailVerified,
            ...updatedMeObject.data(),
          },
        })
      );
      parentPostMessage({
        channel: 'SHARE_PERSONALITY',
        personality: personalityType.personality
          .toLowerCase()
          .replace(/\s/g, '-'),
        username: updatedMeObject.data().username,
        publicLink: `${window.location.origin}/~/${updatedMeObject.data().username}`,
      });

      onCloseModalUpdateUsername();
    } catch (err) {
      setStatusUpdateUsername('rejected');

      toast({
        title: err.message ? t('warning') : t('error'),
        description:
          err.message || t('food-personality-result.update-username.failed'),
        status: err.message ? 'warning' : 'error',
        duration: 3000,
        position: 'top',
        isClosable: false,
      });
    } finally {
      setStatusUpdateUsername('fulfilled');
    }
  };

  const handleSharePersonality = () => {
    if (me.username === undefined || !me.username) {
      onOpenModalUpdateUsername();
      return;
    }

    parentPostMessage({
      channel: 'SHARE_PERSONALITY',
      personality: personalityType.personality
        .toLowerCase()
        .replace(/\s/g, '-'),
      username: me.username,
      publicLink: `${window.location.origin}/~/${me.username}`,
    });
  };

  return location.pathname === path.foodPersonalityResultDownload ? (
    <PersonalityResultDownload
      dark={true}
      personalityTraits={personalityTraits}
      personalityType={personalityType}
    />
  ) : (
    <Fragment>
      <ModalConfirm
        isOpen={isOpenModalRetakeQuiz}
        onClose={onCloseModalRetakeQuiz}
        modalBody={t('food-personality-result.retake-quiz-body')}
        modalTitle={t('food-personality-result.retake-quiz-title')}
        onSubmit={() => parentPostMessage('START_QUIZ')}
      />

      <ModalConfirm
        isOpen={isOpenModalUpdateUsername}
        onSubmit={handleUpdateUsername}
        onClose={onCloseModalUpdateUsername}
        isLoading={statusUpdateUsername === 'pending'}
        isCentered={true}
        modalTitle={t('food-personality-result.update-username.title')}
        modalBody={t('food-personality-result.update-username.body')}
        modalTitleBodyGap={4}
        cancelBtnText={t('cancel')}
        confirmBtnText={t('submit')}
      >
        <FormControl mt="6" id="update-username">
          <Input
            fontSize={{ base: 'lg', md: 'xl' }}
            size="lg"
            placeholder={t(
              'food-personality-result.update-username.placeholder'
            )}
            resize="vertical"
            onChange={(e) => setNewUsername(e.target.value)}
          />
        </FormControl>
      </ModalConfirm>

      <Flex
        flexDir="column"
        justifyContent="start"
        alignItems="start"
        w="full"
        h="100vh"
        bg="#fafafa"
        pb="50px"
      >
        <Flex
          pt="30px"
          pb="12"
          gap="8"
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          width="full"
          position="relative"
        >
          <SubMenuNavigation
            onClick={() => history.goBack()}
            withPattern={true}
            patternZ="0"
          >
            <Text fontSize="20px" color="black" fontWeight="700" gap="8">
              {t('nav-menu.my-food-personality')}
            </Text>
          </SubMenuNavigation>

          <Box w="full" px="8" mt="4">
            <PersonalityResultCard
              bgCard="#04CEAA1F"
              personalityTraits={personalityTraits}
              personalityType={personalityType}
            />

            {!isEmpty(personalityType) ? (
              <Flex
                flexDir="column"
                alignItems="center"
                justifyContent="center"
                w="full"
                mt="8"
                bg="white"
                rounded="24px"
                p="6"
                gap="6"
              >
                <Flex
                  w="full"
                  alignItems="center"
                  justifyContent="center"
                  columnGap="4"
                >
                  <Box w="full">
                    <Button
                      variant="mobileRoundedOutlined"
                      w="full"
                      iconSpacing="4"
                      leftIcon={<FontIcon name="download" />}
                      onClick={() => {
                        parentPostMessage('DOWNLOAD_PERSONALITY');
                        parentPostMessage({
                          channel: 'DOWNLOAD_PERSONALITY',
                          personality: personalityType.personality
                            .toLowerCase()
                            .replace(/\s/g, '-'),
                        });
                      }}
                    >
                      {t('food-personality-result.download-text')}
                    </Button>
                  </Box>

                  <Box w="full">
                    <Button
                      variant="mobileRoundedOutlined"
                      w="full"
                      iconSpacing="4"
                      rightIcon={<FontIcon name="share-alt" />}
                      onClick={handleSharePersonality}
                    >
                      {t(`food-personality-result.share-text`)}
                    </Button>
                    <ShareViaSection comingSoonToast={comingSoonToast} />
                  </Box>
                </Flex>

                {isFeatureOn({
                  featureKey: 'MOBILE_VIEW_PERSONALITY_FULL_RESULT',
                  me: me,
                  featureFlags: featureFlags,
                }) && (
                  <Fragment>
                    <Divider borderColor="#00000020" />
                    <Button
                      variant="mobileRounded"
                      w="full"
                      onClick={() =>
                        parentPostMessage('OPEN_PERSONALITY_FULL_RESULT')
                      }
                    >
                      {t('food-personality-result.view-full-results')}
                    </Button>
                  </Fragment>
                )}
              </Flex>
            ) : (
              <Box mt={'8'}>
                <Button
                  variant={'mobileRounded'}
                  w={'full'}
                  onClick={onOpenModalRetakeQuiz}
                >
                  {t(`food-personality-result.retake-quiz`)}
                </Button>
              </Box>
            )}
          </Box>
        </Flex>
      </Flex>
    </Fragment>
  );
};

export default FoodPersonalityResult;
