export const avatarSeeds = [
  'Emily',
  'William',
  'Olivia',
  'James',
  'Sophia',
  'Benjamin',
  'Aaliyah',
  'Malik',
  'Imani',
  'Elijah',
  'Zara',
  'Isaiah',
  'Mei',
  'Jun',
  'Aya',
  'Hiroshi',
  'An',
  'Ming',
];

export const dicebarDefaultStyle = 'croodles-neutral';

export const dicebarListStyle = {
  adventurer: {
    name: 'Adventurer',
    svgURL: 'https://api.dicebear.com/7.x/adventurer/svg',
  },
  'adventurer-neutral': {
    name: 'Adventurer Neutral',
    svgURL: 'https://api.dicebear.com/7.x/adventurer-neutral/svg',
  },
  avataaars: {
    name: 'Avataaars',
    svgURL: 'https://api.dicebear.com/7.x/avataaars/svg',
  },
  'avataaars-neutral': {
    name: 'Avataaars Neutral',
    svgURL: 'https://api.dicebear.com/7.x/avataaars-neutral/svg',
  },
  'big-ears': {
    name: 'Big Ears',
    svgURL: 'https://api.dicebear.com/7.x/big-ears/svg',
  },
  'big-ears-neutral': {
    name: 'Big Ears Neutral',
    svgURL: 'https://api.dicebear.com/7.x/big-ears-neutral/svg',
  },
  'big-smile': {
    name: 'Big Smile',
    svgURL: 'https://api.dicebear.com/7.x/big-smile/svg',
  },
  bottts: {
    name: 'Bottts',
    svgURL: 'https://api.dicebear.com/7.x/bottts/svg',
  },
  'bottts-neutral': {
    name: 'Bottts Neutral',
    svgURL: 'https://api.dicebear.com/7.x/bottts-neutral/svg',
  },
  croodles: {
    name: 'Croodles',
    svgURL: 'https://api.dicebear.com/7.x/croodles/svg',
  },
  'croodles-neutral': {
    name: 'Croodles Neutral',
    svgURL: 'https://api.dicebear.com/7.x/croodles-neutral/svg',
  },
  'fun-emoji': {
    name: 'Fun Emoji',
    svgURL: 'https://api.dicebear.com/7.x/fun-emoji/svg',
  },
  icons: {
    name: 'Icons',
    svgURL: 'https://api.dicebear.com/7.x/icons/svg',
  },
  identicon: {
    name: 'Identicon',
    svgURL: 'https://api.dicebear.com/7.x/identicon/svg',
  },
  initials: {
    name: 'Initials',
    svgURL: 'https://api.dicebear.com/7.x/initials/svg',
  },
  lorelei: {
    name: 'Lorelei',
    svgURL: 'https://api.dicebear.com/7.x/lorelei/svg',
  },
  'lorelei-neutral': {
    name: 'Lorelei Neutral',
    svgURL: 'https://api.dicebear.com/7.x/lorelei-neutral/svg',
  },
  micah: {
    name: 'Micah',
    svgURL: 'https://api.dicebear.com/7.x/micah/svg',
  },
  miniavs: {
    name: 'Miniavs',
    svgURL: 'https://api.dicebear.com/7.x/miniavs/svg',
  },
  notionists: {
    name: 'Notionists',
    svgURL: 'https://api.dicebear.com/7.x/notionists/svg',
  },
  'notionists-neutral': {
    name: 'Notionists Neutral',
    svgURL: 'https://api.dicebear.com/7.x/notionists-neutral/svg',
  },
  'open-peeps': {
    name: 'Open Peeps',
    svgURL: 'https://api.dicebear.com/7.x/open-peeps/svg',
  },
  personas: {
    name: 'Personas',
    svgURL: 'https://api.dicebear.com/7.x/personas/svg',
  },
  'pixel-art': {
    name: 'Pixel Art',
    svgURL: 'https://api.dicebear.com/7.x/pixel-art/svg',
  },
  'pixel-art-neutral': {
    name: 'Pixel Art Neutral',
    svgURL: 'https://api.dicebear.com/7.x/pixel-art-neutral/svg',
  },
  rings: {
    name: 'Rings',
    svgURL: 'https://api.dicebear.com/7.x/rings/svg',
  },
  shapes: {
    name: 'Shapes',
    svgURL: 'https://api.dicebear.com/7.x/shapes/svg',
  },
  thumbs: {
    name: 'Thumbs',
    svgURL: 'https://api.dicebear.com/7.x/thumbs/svg',
  },
};
