import { Box } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { parentPostMessage } from 'utils/postmessage-module/postMessage';

function Resizer({ children }) {
  const refResizer = useRef(null);

  useEffect(() => {
    if (!refResizer.current) return;

    // observe the sidebar height based on its content and send it to the extension
    const resizeObserver = new ResizeObserver((data) => {
      if (data.length > 0) {
        parentPostMessage(
          {
            channel: 'RESIZE_SIDEBAR',
            payload: { sidebarHeight: data[0].contentRect.height },
          },
          '*'
        );
      }
    });

    resizeObserver.observe(refResizer.current);
    return () => resizeObserver.disconnect(); // clean
  }, [refResizer]);

  return (
    // listen sidebar height based on its content
    <Box display="flex" width="100%" ref={refResizer}>
      {children}
    </Box>
  );
}

export default Resizer;
