export const windowGlobalVar = {
  FOODLAMA_WEB_APP_BUILD_VERSION: 'FOODLAMA_WEB_APP_BUILD_VERSION',
  LOGIN_WITH_CUSTOM_TOKEN: 'LOGIN_WITH_CUSTOM_TOKEN',
  OPEN_PRODUCT_ANALYSIS_BY_BARCODE: 'OPEN_PRODUCT_ANALYSIS_BY_BARCODE',
  GET_USER_SESSION: 'GET_USER_SESSION',
  REDIRECT_USER_AFTER_GOALS: 'REDIRECT_USER_AFTER_GOALS',
  REDIRECT_USER_AFTER_QUIZ: 'REDIRECT_USER_AFTER_QUIZ',
  GET_RETAILERS_LOGIN_STATE: 'GET_RETAILERS_LOGIN_STATE',
  CHANGE_PATH: 'CHANGE_PATH',
  PUSH_PATH: 'PUSH_PATH',
  UPDATE_PREMIUM_PLAN: 'UPDATE_PREMIUM_PLAN',
  UPDATE_PREMIUM_STATUS: 'UPDATE_PREMIUM_STATUS',
  SHOW_SUBSCRIPTION_OVERLAY: 'SHOW_SUBSCRIPTION_OVERLAY',
  CHECK_SCAN_COUNTER: 'CHECK_SCAN_COUNTER',
  REGISTER_FCM: 'REGISTER_FCM',
  SCAN_REQUEST: 'SCAN_REQUEST',
  ORDER_SESSION_VERIFICATION: 'ORDER_SESSION_VERIFICATION',
  UPDATE_RETAILER_SESSION: 'UPDATE_RETAILER_SESSION',
  START_FETCHING_ORDER_HISTORY: 'START_FETCHING_ORDER_HISTORY',
  START_REQUEST_RETAILER_LOGIN: 'START_REQUEST_RETAILER_LOGIN',
  VALIDATE_SHOPPING_ASSISTANT_SESSION: 'VALIDATE_SHOPPING_ASSISTANT_SESSION',
  UPDATE_ACTIVE_PLAN: 'UPDATE_ACTIVE_PLAN',
  SET_AVAILABLE_FREE_TRIALS: 'SET_AVAILABLE_FREE_TRIALS',
  RECORD_PURCHASE_EVENT: 'RECORD_PURCHASE_EVENT',
  RECORD_ANALYTIC_EVENT: 'RECORD_ANALYTIC_EVENT',
};
